import {FiberNewOutlined} from "@mui/icons-material";

const NewIcon = () => {
  return (
    <FiberNewOutlined
      sx={{
        fontSize: "26px",
        position: "absolute",
        top: "-10px",
        right: "-10px",
      }}
    />
  );
};

export default NewIcon;
