import {useEffect} from "react";
import {useAdminAuth} from "shared/hooks/adminAuthContext/useAdminAuth";
import {WS_SERVERS} from "shared/constants/wsServers";
import {useAppDispatch} from "shared/hooks/useAppDispatch";
import {chatUtils} from "shared/utils/chatUtils/chatUtils";
import {chatsInit, setChatsIsInit} from "shared/ReduxSlice/chatSlice/chatSlice";
import {useAppSelector} from "shared/hooks/useAppSelector";

/**
 * Кастомный хук для инициализации чата и получения первых 20 чатов во всех трех состояниях:
 * GetChats, GetArchivedChats, GetBlackListChats
 *  */
export const useChatInit = () => {
  const {user} = useAdminAuth();
  const {isMainApp, tabsCount} = useAppSelector((state) => state.broadcastChannelSlice);
  const {isInit} = useAppSelector((store) => store.chatSlice);
  const wsSlice = useAppSelector((state) => state.WSSlice);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!user?.id) return;
    if (tabsCount === 0) return; // waiting initialization of broadcastChannelSlice

    if (
      wsSlice[WS_SERVERS.v2.name]?.isConnected &&
      !wsSlice[WS_SERVERS.v2.name]?.isLoading &&
      isMainApp
    ) {
      !isInit && console.log("chat initing");
      !isInit && dispatch(chatsInit());

      chatUtils.chatMessagesStartListener();
    }

    if (!isMainApp && !isInit) {
      dispatch(setChatsIsInit(true));

      chatUtils.chatMessagesStartListener();
    }
  }, [isMainApp, user, isInit, tabsCount, wsSlice, dispatch]);
};
