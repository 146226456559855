import {
  CreateButton,
  DatagridConfigurable,
  EditButton,
  FunctionField,
  List,
  NullableBooleanInput,
  NumberField,
  SelectColumnsButton,
  TextField,
  TopToolbar,
  useListContext,
  WrapperField,
} from "react-admin";
import Loader from "shared/components/Loader/Loader";
import {MyBooleanField} from "shared/components/MyBooleanField/MyBooleanField";
import {MyUrlField} from "shared/components/MyUrlField/MyUrlField";
import {PACKAGES_NAME} from "shared/constants/packagesName";
import {ROUTES} from "shared/constants/ROUTES";
import {getDataGridStyles} from "shared/styles/dataGridStyles";
import {getDurationFromDMY} from "shared/utils/getDurationFromDMY";
import {useGetConditions} from "./hooks/useGetConditions";

const filters = [
  <NullableBooleanInput label="Промо" source="promo" alwaysOn sx={{minWidth: "200px"}} />,
  <NullableBooleanInput label="Демо" source="demo" alwaysOn sx={{minWidth: "200px"}} />,
];

export const TariffsList = () => {
  return (
    <List
      pagination={false}
      actions={
        <TopToolbar>
          <CreateButton variant="main" sx={{px: 2}} label="Создать тариф" />
          <SelectColumnsButton />
        </TopToolbar>
      }
      filters={filters}
      filterDefaultValues={{archived: false}}
      title="Список тарифов"
    >
      <Fields />
    </List>
  );
};

function Fields() {
  const {isLoading = false} = useListContext();
  const {data: conditions} = useGetConditions();

  if (isLoading) return <Loader />;
  return (
    <DatagridConfigurable
      bulkActionButtons={false}
      sx={({palette}) => ({
        ...getDataGridStyles(palette),
      })}
      omit={[
        "priceNoNds",
        "ndsPercent",
        "ndsAmount",
        "s1Sku",
        "sorter",
        "internalComment",
        "specialPriceIsEnabled",
        "specialPriceWithNds",
        "specialPriceNoNds",
        "specialPriceNdsPercent",
        "specialPriceNdsAmount",
        "specialPriceReason",
      ]}
    >
      <TextField source="id" label={"Id"} sortable={false} />
      <TextField source="codename" label={"Код"} sortable={false} />
      <TextField source="name" label={"Название"} sortable={false} />

      <TextField source="priceWithNds" label={"Цена с НДС"} sortable={false} />
      <TextField source="priceNoNds" label={"Цена без НДС"} sortable={false} />
      <TextField source="ndsPercent" label={"Процент НДС"} sortable={false} />
      <TextField source="ndsAmount" label={"Сумма НДС"} sortable={false} />
      <TextField source="s1Sku" label={"1С Артикул"} sortable={false} />
      <TextField source="s1Name" label={"1С Название"} sortable={false} />

      <WrapperField label="Тарифная группа">
        <MyUrlField
          source="tariffGroup.title"
          label={
            <>
              Тарифная <br />
              группа
            </>
          }
          to={(record) => `/${ROUTES.GROUP_TARIFFS.path}/${record.tgroupId}`}
          sortable={false}
        />
      </WrapperField>

      <TextField source="title" label={"Заголовок"} sortable={false} />
      <TextField source="priceReason" label={"Причина цены"} sortable={false} />

      <WrapperField label="Кол-во дней">
        <FunctionField
          source="month"
          label={
            <>
              Кол-во <br />
              дней
            </>
          }
          render={getDurationFromDMY}
          sortable={false}
        />
      </WrapperField>

      <WrapperField label="Порядковый номер">
        <NumberField
          source="sorter"
          label={
            <>
              Порядковый <br /> номер
            </>
          }
          sortable={false}
        />
      </WrapperField>

      <MyBooleanField source="isPromo" label={"Промо"} sortable={false} />

      <WrapperField label="Можно купить">
        <MyBooleanField
          source="canBuy"
          sortable={false}
          label={
            <>
              Можно <br /> купить
            </>
          }
        />
      </WrapperField>

      <MyBooleanField source="isDemo" sortable={false} label={"Демо"} />
      <MyBooleanField source="isArchived" sortable={false} label={"В архиве"} />

      <WrapperField label="Включаемые пакеты">
        <FunctionField
          source="subscriptionTypes"
          label={
            <>
              Кол-во <br />
              дней
            </>
          }
          render={(record) =>
            record?.subscriptionTypes
              ? record?.subscriptionTypes
                  .map((item) => PACKAGES_NAME[item.code])
                  .join(", ")
              : null
          }
          sortable={false}
        />
      </WrapperField>

      <WrapperField label="Условия">
        <FunctionField
          source="conditions"
          label={
            <>
              Кол-во <br />
              дней
            </>
          }
          render={(record) =>
            conditions && record?.conditions?.length
              ? record?.conditions.map((item) => conditions[item]).join(", ")
              : null
          }
          sortable={false}
        />
      </WrapperField>

      <WrapperField label="Внутренний комментарий">
        <TextField
          source="internalComment"
          label={
            <>
              Внутренний <br /> комментарий
            </>
          }
          sortable={false}
        />
      </WrapperField>

      <WrapperField label="Включена ли акция">
        <MyBooleanField
          sortable={false}
          source="specialPriceIsEnabled"
          label={
            <>
              Включена ли <br /> акция
            </>
          }
        />
      </WrapperField>

      <WrapperField label="Цена акции с НДС">
        <TextField
          source="specialPriceWithNds"
          label={
            <>
              Цена акции <br /> с НДС
            </>
          }
          sortable={false}
        />
      </WrapperField>

      <WrapperField label="Цена акции без НДС">
        <TextField
          source="specialPriceNoNds"
          label={
            <>
              Цена акции <br /> без НДС
            </>
          }
          sortable={false}
        />
      </WrapperField>

      <WrapperField label="Процент НДС акции">
        <TextField
          source="specialPriceNdsPercent"
          label={
            <>
              Процент НДС <br /> акции
            </>
          }
          sortable={false}
        />
      </WrapperField>

      <WrapperField label="Сумма НДС акции">
        <TextField
          source="specialPriceNdsAmount"
          label={
            <>
              Сумма НДС <br /> акции
            </>
          }
          sortable={false}
        />
      </WrapperField>

      <WrapperField label="Причина акции">
        <TextField
          source="specialPriceReason"
          label={
            <>
              Причина <br /> акции
            </>
          }
          sortable={false}
        />
      </WrapperField>

      <WrapperField textAlign="center" sortable={false} label="Редактировать">
        <EditButton
          label=""
          size="small"
          sx={({palette}) => ({color: palette.primary.main})}
        />
      </WrapperField>
    </DatagridConfigurable>
  );
}
