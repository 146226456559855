import React, {memo} from "react";
import {
  CreateButton,
  Datagrid,
  DatagridConfigurable,
  EditButton,
  FunctionField,
  ImageField,
  List,
  ListActions,
  NumberField,
  SelectColumnsButton,
  TextField,
  TopToolbar,
  useListContext,
  useRecordContext,
  WrapperField,
} from "react-admin";
import Loader from "shared/components/Loader/Loader";
import {MyBooleanField} from "shared/components/MyBooleanField/MyBooleanField";
import {getDataGridStyles} from "shared/styles/dataGridStyles";

const ShowImageOrVideo = memo(() => {
  const record = useRecordContext();
  return record?.type === "card" ? (
    <ImageField source="preview_url" textAlign="left" sx={{width: "fit-content"}} />
  ) : (
    <video
      poster={record.video_url?.replace("mp4", "jpg")}
      preload="none"
      controls
      style={{
        borderRadius: "8px",
        height: "100%",
        maxHeight: "240px",
        width: "100%",
        maxWidth: "280px",
        objectFit: "cover",
        objectPosition: "0 0",
      }}
    >
      <source src={record.video_url} type="video/mp4" style={{height: "100%"}} />
    </video>
  );
});

const CustomListActions = () => {
  return (
    <TopToolbar>
      <CreateButton />
      <SelectColumnsButton />
    </TopToolbar>
  );
};

export const BlogList = () => {
  return (
    <List title="Все блоги" actions={<CustomListActions />} filters={[]}>
      <Fields />
    </List>
  );
};

function Fields() {
  const {isLoading = false} = useListContext();

  if (isLoading) return <Loader />;
  return (
    <DatagridConfigurable
      rowClick={false}
      sx={({palette}) => ({
        ...getDataGridStyles(palette),
      })}
      bulkActionButtons={false}
      omit={["id", "position", "card_title", "sub_title", "html"]}
    >
      <TextField source="id" textAlign="center" />
      <MyBooleanField source={"status"} label={"Опублик."} textAlign="center" />
      <FunctionField
        render={(record) => (record?.type === "card" ? "Статья" : "Видео")}
        source="type"
        label={"Тип"}
        textAlign="center"
      />
      <NumberField source="position" label={"Позиция"} textAlign="center" />
      <TextField source="title" label={"Заголовок"} textAlign="center" sortable={false} />
      <WrapperField label="Заголовок карточки" sortable={false}>
        <FunctionField
          render={(record) => (
            <div
              dangerouslySetInnerHTML={{__html: record?.card_title || ""}}
              style={{
                WebkitLineClamp: 4,
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
              }}
            />
          )}
          source="card_title"
          label={
            <>
              Заголовок <br /> карточки
            </>
          }
          textAlign="center"
          sortable={false}
        />
      </WrapperField>

      <TextField
        source="sub_title"
        label={"Подзаголовок"}
        textAlign="center"
        sortable={false}
      />

      <FunctionField
        render={(record) => (
          <div
            dangerouslySetInnerHTML={{__html: record?.html || ""}}
            style={{
              WebkitLineClamp: 4,
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
            }}
          />
        )}
        source="html"
        label={"HTML"}
        textAlign="left"
        sortable={false}
      />

      <WrapperField label="Картинка/Видео" sortable={false}>
        <ShowImageOrVideo />
      </WrapperField>

      <WrapperField textAlign="center" sortable={false} label="Редактировать">
        <EditButton
          label=""
          size="small"
          sx={({palette}) => ({color: palette.primary.main})}
        />
      </WrapperField>
    </DatagridConfigurable>
  );
}
