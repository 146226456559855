import {useEffect, useState} from "react";
import {Typography, useTheme} from "@mui/material";
import {TChat} from "shared/ReduxSlice/chatSlice/types";
import {CHAT_TYPES} from "shared/constants/chat";
import {chatUtils} from "shared/utils/chatUtils/chatUtils";
import {useAppSelector} from "shared/hooks/useAppSelector";

const ChatStatus = () => {
  const {palette} = useTheme();
  const {currentChat} = useAppSelector((state) => state.chatSlice);
  const {type, typingUsers, users} = currentChat as TChat;
  const [isUserTyping, setUserTyping] = useState(false);

  useEffect(() => {
    if (!typingUsers || typingUsers?.startTime + 3000 < Date.now()) return;
    setUserTyping(!!typingUsers?.startTime);
    setTimeout(() => {
      setUserTyping(false);
    }, 3000);
  }, [typingUsers?.startTime]);

  if (isUserTyping) {
    return <Typography>Печатает...</Typography>;
  }

  if (type === CHAT_TYPES.GROUP) {
    return <Typography>Групповой чат</Typography>;
  }

  if (type === CHAT_TYPES.MEETING) {
    return <Typography>Переговорная комната</Typography>;
  }

  if (chatUtils.getChatUserById({userID: users[0]})?.online.isOnline) {
    return <Typography sx={{color: palette.primary.main}}>Online</Typography>;
  }

  return <Typography sx={{color: palette.red.main}}>Offline</Typography>;
};

export default ChatStatus;
