import {IconButton, IconButtonProps, useTheme} from "@mui/material";

type StyledChatButtonProps = {
  isActive?: boolean;
} & IconButtonProps;

export const StyledChatButton = ({isActive = false, ...props}: StyledChatButtonProps) => {
  const {children, sx, ...rest} = props;
  const {palette} = useTheme();
  return (
    <IconButton
      sx={{
        width: 48,
        height: 48,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: isActive
          ? palette.primary.main
          : palette.mode === "light"
          ? palette.primary700.main
          : palette.black.main,
        borderRadius: "50%",
        cursor: "pointer",
        position: "relative",
        transition: "all 0.3s ease",
        minWidth: 48,
        minHeight: 48,
        ":hover": {opacity: 0.8, bgcolor: palette.primary.main},
        ":disabled": {bgcolor: palette.action.disabledBackground},
        ...sx,
      }}
      {...rest}
    >
      {children}
    </IconButton>
  );
};
