import {mergeAttributes, Node} from "@tiptap/core";
import {ReactNodeViewRenderer} from "@tiptap/react";
import {BlockWithBackground} from "./BlockWithBackground";

export type DefaultBlockWithBackgroundAttrs = {
  url: string;
};

export default Node.create({
  name: "BlockWithBackground",

  group: "block",

  content: "inline*",

  addAttributes() {
    return {
      url: {
        default: "/",
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "BlockWithBackground",
      },
    ];
  },

  renderHTML({HTMLAttributes}) {
    return ["BlockWithBackground", mergeAttributes(HTMLAttributes), 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(BlockWithBackground);
  },
});
