import chatSlice from "shared/ReduxSlice/chatSlice/chatSlice";
import WSSlice from "shared/ReduxSlice/webSocketSlice/WSSlice";
import broadcastChannelSlice from "shared/ReduxSlice/bcSlice/bcSlice";

import {api} from "typesFromApi/api";

export const reducers = {
  chatSlice,
  broadcastChannelSlice,
  WSSlice,
};

export const reducersForStore = {
  ...reducers,
  api: api.reducer,
};
