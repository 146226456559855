import {Box, Link} from "@mui/material";
import React from "react";
import {
  FunctionField,
  FunctionFieldProps,
  Identifier,
  RaRecord,
  useRecordContext,
} from "react-admin";
import {textAlignLeft} from "shared/styles/textFieldAlignLeft";

const getNestedPropertyValue = (obj: any, keys: string[]) => {
  return keys.reduce(
    (acc, key) => (acc && acc[key] !== "undefined" ? acc[key] : undefined),
    obj,
  );
};

export const MyUrlField = ({
  source,
  to,
  target = "_self",
  label,
  sortable = true,
  isAlignLeft = false,
  isValueBoolean = false,
}: {
  source: string;
  target?: React.HTMLAttributeAnchorTarget;
  to?: (record: RaRecord<Identifier>) => string;
  label: FunctionFieldProps["label"];
  sortable?: boolean;
  isAlignLeft?: boolean;
  isValueBoolean?: boolean;
}) => {
  const record = useRecordContext();

  const validKey = React.useMemo(() => {
    if (!source.includes(".")) return [source];

    return source.split(".");
  }, [source]);

  const value = getNestedPropertyValue(record, validKey);

  const isAlignLeftStyle = isAlignLeft
    ? textAlignLeft
    : {display: "flex", justifyContent: "center"};

  return record ? (
    <FunctionField
      label={label}
      sortable={sortable}
      sx={{width: "100%"}}
      render={() => (
        <Box
          component={Link}
          href={to ? to(record) : value}
          sx={({palette}) => ({
            textDecoration: "none",
            color: palette.primary.main,
            width: "100%",
            ...isAlignLeftStyle,
          })}
          target={target}
        >
          {isValueBoolean ? (value ? "Да" : "Нет") : value}
        </Box>
      )}
    />
  ) : null;
};
