import {FormControl, FormLabel, RadioGroup, FormControlLabel, Radio} from "@mui/material";

type RadioGroupTypeProps = {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
};

export const RadioGroupTypeOfContent = ({value, onChange}: RadioGroupTypeProps) => (
  <FormControl>
    <FormLabel id="row-radio">Тип загружаемого контента</FormLabel>
    <RadioGroup
      row
      aria-labelledby="row-radio"
      name="row-radio"
      value={value}
      onChange={onChange}
    >
      <FormControlLabel value="card" control={<Radio />} label="Статья" />
      <FormControlLabel value="video" control={<Radio />} label="Видео" />
    </RadioGroup>
  </FormControl>
);
