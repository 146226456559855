import {Components} from "@mui/material";

export const muiTableCell: Components["MuiTableCell"] = {
  styleOverrides: {
    root: ({theme}) => ({
      padding: "0 5px",
      lineHeight: "110% !important",
      span: {
        lineHeight: "110% !important",
      },
      a: {
        lineHeight: "110% !important",
      },
    }),
  },
};
