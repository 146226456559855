import {Stack, Button, Tooltip, List, TextField} from "@mui/material";
import {CustomSelect} from "../CustomSelect/CustomSelect";
import {Editor} from "@tiptap/react";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {useDisclosure} from "shared/hooks/useDisclosure";
import {UI_Modal} from "shared/components/UI_Modal/UI_Modal";
import {useCallback, useState} from "react";
import ErrorIcon from "@mui/icons-material/Error";
import {LinksInToolbar} from "pages/FAQ/components/LinksInToolbar";

export const buttonSx = {
  maxWidth: "160px",
  width: "100%",
  lineHeight: "110%",
  minHeight: "30px",
  height: "30px",
};

export const MenuBar = ({editor}: {editor: Editor | null}) => {
  const {isOpen, onClose, onOpen} = useDisclosure();
  const [linkIsFor, setLinkIsFor] = useState<"image" | "block" | "">("");

  if (!editor) {
    return null;
  }

  const node = editor.state.selection.$head.parent;
  const marginTopValue = node.attrs?.marginTop;
  const marginBottomValue = node.attrs?.marginBottom;
  const iconValue = node.attrs?.icon;

  const handleSetUrlFromModal = (value: string) => {
    if (!linkIsFor) return;

    if (linkIsFor === "image") {
      editor.chain().focus().setImage({src: value}).run();
    }

    if (linkIsFor === "block") {
      editor.commands.setNode("BlockWithBackground", {url: value});
    }

    onClose();
    setLinkIsFor("");
  };

  return (
    <Stack
      direction="row"
      gap={2}
      sx={({palette}) => ({
        flexWrap: "wrap",
        position: "sticky",
        top: 0,
        zIndex: 9999,
        alignItems: "center",
        boxShadow:
          palette.mode === "light"
            ? "0px 1px 8px 0px rgba(217, 217, 217, 0.85)"
            : "0px 1px 8px 0px rgba(30, 30, 30, 0.85)",
        transition: "all 0.3s ease",
        ":hover": {
          boxShadow:
            palette.mode === "light"
              ? "0px 1px 8px 0px rgba(49, 172, 184, 0.4)"
              : "0px 1px 8px 0px rgba(49, 172, 184, 0.4)",
        },
        background: palette.background.paper,
        py: 4,
      })}
    >
      <Stack gap={2} sx={{width: "100%"}}>
        <Stack direction={"row"} gap={2}>
          <Button
            onClick={() => editor.commands.setNode("BigText")}
            variant="main"
            disabled={node.type.name === "BigText"}
            sx={buttonSx}
          >
            Заголовок
          </Button>

          <CustomSelect
            options={[
              {
                option: "info",
                label: <ErrorIcon color="primary" sx={{width: "20px", height: "20px"}} />,
              },
              {
                option: "support",
                label: (
                  <ContactSupportIcon
                    color="primary"
                    sx={{width: "20px", height: "20px"}}
                  />
                ),
              },
              {
                option: "check",
                label: (
                  <CheckCircleIcon color="primary" sx={{width: "20px", height: "20px"}} />
                ),
              },
            ]}
            onChange={(value) => {
              editor.commands.setNode("ImportantText", {icon: value});
            }}
            label="Заголовок с иконкой"
            value={iconValue}
          />

          <Button
            onClick={() => editor.commands.setNode("WithLeftBorder")}
            variant="main"
            disabled={node.type.name === "WithLeftBorder"}
            sx={buttonSx}
          >
            Цитата
          </Button>

          <Button
            onClick={() => {
              setLinkIsFor("image");
              onOpen();
            }}
            variant="main"
            disabled={node.type.name === "image"}
            sx={buttonSx}
          >
            Картинка
          </Button>
        </Stack>

        <Stack direction={"row"} gap={2}>
          <Button
            onClick={() => editor.commands.setNode("DefaultText")}
            variant="main"
            disabled={node.type.name === "DefaultText"}
            sx={buttonSx}
          >
            Стандартный текст
          </Button>
          <Button
            onClick={() => {
              editor.commands.clearNodes();
              editor.commands.unsetAllMarks();
            }}
            variant="main"
            sx={buttonSx}
          >
            Очистить стили
          </Button>

          <Button
            variant="main"
            onClick={() => editor.commands.toggleBold()}
            sx={buttonSx}
          >
            Жирный текст
          </Button>

          <Button
            onClick={() => editor.commands.setNode("List")}
            variant="main"
            disabled={node.type.name === "List"}
            sx={buttonSx}
          >
            Список
          </Button>

          <Button
            onClick={() => {
              setLinkIsFor("block");
              onOpen();
            }}
            variant="main"
            disabled={node.type.name === "BlockWithBackground"}
            sx={buttonSx}
          >
            Бэкграунд и кнопка
          </Button>

          <LinksInToolbar editor={editor} />
        </Stack>

        <Stack direction={"row"} gap={2}>
          <CustomSelect
            options={["0px", "4px", "8px", "12px", "16px", "20px"]}
            onChange={(value) => {
              let name = "";
              if (editor.isActive("DefaultText")) name = "DefaultText";
              else if (editor.isActive("BoldText")) name = "BoldText";

              editor.commands.updateAttributes(name, {marginTop: value});
            }}
            label="Отступ сверху"
            value={marginTopValue as string}
            disabled={!marginTopValue}
          />

          <CustomSelect
            options={["0px", "4px", "8px", "12px", "16px", "20px"]}
            onChange={(value) => {
              let name = "";
              if (editor.isActive("DefaultText")) name = "DefaultText";
              else if (editor.isActive("BoldText")) name = "BoldText";

              if (name) editor.commands.updateAttributes(name, {marginBottom: value});
            }}
            label="Отступ снизу"
            value={marginBottomValue}
            disabled={!marginBottomValue}
          />
        </Stack>
      </Stack>

      {/* 
      TODO:
      <Button
        onClick={() => editor.commands.setNode("BoldText")}
        variant="main"
        disabled={node.type.name === "BoldText"}
      >
        Жирный текст
      </Button> */}

      <Tooltip
        title={
          <List subheader={"Подсказки к пользованию:"}>
            <li>1) Отступы снизу и сверху есть только у стандартного текста</li>
            <li>
              2) Отступы у двух соседних элементов схлопываются и приравниваются к
              большему
            </li>
            <li>3) Enter - это переход на новую строку с созданием нового элемента</li>
            <li>
              4) Ctrl + Enter или Shift + Enter - это переход на новую строку внутри
              элемента
            </li>
            <li>
              5) Для запрета переноса можно использовать спец символ "&amp;nbsp;" вместо
              пробела
              <br />
              Например: для строки (п.13, статья 44, ФЗ-44). написать -
              (п.13,&amp;nbsp;статья&amp;nbsp;44,&amp;nbsp;ФЗ-44)
            </li>
          </List>
        }
      >
        <LiveHelpIcon
          color="primary"
          sx={{cursor: "help", position: "absolute", top: 0, right: 0}}
        />
      </Tooltip>

      <ChangeUrlModal
        isOpen={isOpen}
        onClose={onClose}
        handleSubmit={handleSetUrlFromModal}
        linkIsFor={linkIsFor}
      />
    </Stack>
  );
};

type ChangeUrlModalProps = {
  isOpen: boolean;
  onClose: () => void;
  handleSubmit: (str: string) => void;
  linkIsFor: "image" | "block" | "";
};

function ChangeUrlModal({isOpen, onClose, handleSubmit, linkIsFor}: ChangeUrlModalProps) {
  const [value, setValue] = useState("");

  return (
    <UI_Modal
      modalProps={{open: isOpen, onClose}}
      titleProps={{
        title:
          linkIsFor === "image" ? "Введите ссылку для картинки" : "Задать ссылку кнопке",
      }}
      sx={{p: "20px"}}
    >
      <Stack sx={{m: 2}}>
        <TextField
          label="Ссылка"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />

        <Stack
          justifyContent={"flex-end"}
          sx={{width: "100%"}}
          alignItems={"flex-end"}
          pt={6}
        >
          <Button
            variant="main"
            onClick={() => {
              handleSubmit(value);
              setValue("");
            }}
            sx={{maxWidth: "160px", width: "100%"}}
          >
            Сохранить
          </Button>
        </Stack>
      </Stack>
    </UI_Modal>
  );
}
