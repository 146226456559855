import {Box} from "@mui/material";
import {useAppSelector} from "shared/hooks/useAppSelector";

import {MessageInput} from "./components/MessageInput";
import {ArchiveButton} from "./components/ArchiveButton";
import {BlackListButton} from "./components/BlackListButton";

export const ChatFooter = () => {
  const {currentMainMenu, currentChat} = useAppSelector((state) => state.chatSlice);

  return (
    <Box sx={{display: "flex", height: "80px"}}>
      <Box
        sx={{
          display: "flex",
          p: 4,
          gap: 4,
          justifyContent: "flex-end",
          maxWidth: "420px",
          width: "100%",
          height: "100%",
          borderTop: ({palette}) => `1px solid ${palette.black500.main}`,
        }}
      >
        <ArchiveButton currentMainMenu={currentMainMenu} isDisabled={false} />
        <BlackListButton currentMainMenu={currentMainMenu} isDisabled={false} />
      </Box>
      {currentChat?.id ? (
        <MessageInput key={currentChat?.id} />
      ) : (
        <Box sx={{width: "100%", height: "100%"}} />
      )}
    </Box>
  );
};
