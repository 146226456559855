import {Box, Stack, Typography} from "@mui/material";

const Characteristics = ({product}) => {
  const {description, catalog_chars} = product;

  return (
    <Stack gap={4}>
      {description ? (
        <Stack direction={"row"} alignItems={"center"} gap={4}>
          <Typography>Описание позиции:</Typography>
          <Typography>{description}</Typography>
        </Stack>
      ) : null}
      {catalog_chars?.length ? (
        <Box>
          <Typography>Требуемые характеристики: </Typography>
          {catalog_chars.map(
            ({char_name, value_name}) => (
              // value_name ? (
              <Stack direction={"row"} alignItems={"center"} gap={4}>
                <Typography>{char_name}:</Typography>
                <Typography>{value_name || "-"}</Typography>
              </Stack>
            ),
            // ) : null,
          )}
        </Box>
      ) : null}
    </Stack>
  );
};

export {Characteristics};
