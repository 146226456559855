import {ThemeProvider} from "@mui/material/styles";
import {THEMES} from "../lib/themes";
import {ComponentType, ReactNode, useContext} from "react";
import {AppContext} from "shared/utils/context/AppContext";
import {CssBaseline} from "@mui/material";

type Props = {
  children?: ReactNode;
};

function ThemeProviderApp({children}: Props) {
  const {colorMode} = useContext(AppContext);

  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={THEMES[colorMode]}>{children}</ThemeProvider>{" "}
    </>
  );
}

export default ThemeProviderApp;

export const WithThemeProvider = (Component: ComponentType) => () => {
  return (
    <ThemeProviderApp>
      <Component />
    </ThemeProviderApp>
  );
};
