import {Components, Theme} from "@mui/material";
import {renderScrollStyle} from "shared/styles/scrollStyle";

export const muiTextField: Components<Theme>["MuiTextField"] = {
  defaultProps: {
    // sx: {
    //   "& legend": {display: "none"},
    //   "& fieldset": {top: 0},
    // },
    // fullWidth: true,
    // helperText: " ",
    // InputLabelProps: {
    //   shrink: true,
    // },
  },
  styleOverrides: {
    root: ({theme}) => ({
      "& .MuiOutlinedInput-root:hover": {
        "& > fieldset": {
          borderColor: theme.palette.primary.main,
        },
      },
      textArea: {
        ...renderScrollStyle(theme.palette),
      },
    }),
  },
};
