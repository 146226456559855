import { Fragment } from 'react';
import { Labeled, useShowContext } from 'react-admin';

export const DealOperator = () => {
  const dealStatus = useShowContext().record?.state?.messageMeta || {};

  const sortedData = {};
  Object.keys(dealStatus)
    .sort()
    .forEach(key => {
      sortedData[key] = dealStatus[key];
    });

  const isEmpty = Object.keys(sortedData).length === 0;

  return (
    <>{isEmpty ? null :
      <div>
        <h3 style={{ margin: 0, marginBottom: "6px" }}>Дополнительная информация:</h3>
        <Fragment>
          {Object.entries(sortedData).map(([key, value]) => (
            <div key={key}>
              {key}: {value as string}
            </div>
          ))}
        </Fragment>
      </div>
    }
    </>
  );
};
