import React from "react";
import {format} from "date-fns";
import {PATTERN_DATE} from "shared/constants/dates";
import {Stack} from "@mui/material";
import {TicketType} from "../TicketsEditProcedures";
import {SubtitleSpan} from "../../components/SubtitleSpan/SubtitleSpan";
import {TypoAsValue} from "../../components/TypoAsValue/TypoAsValue";

export const CustomStack = ({children}: {children: React.ReactNode}) => (
  <Stack
    direction={"row"}
    gap={"40px"}
    sx={{"& p": {":first-of-type": {maxWidth: "240px", width: "100%"}}}}
  >
    {children}
  </Stack>
);

const PaymentAndDelivery = ({ticket}: {ticket: TicketType}) => {
  const {
    prepay,
    prepay_percent,
    postpay,
    postpay_days,
    other_terms_of_delivery,
    letter_of_credit,
    delivery,
    ts_delivery_before,
    delivery_address,
    ts_pickup_before,
  } = ticket;

  return (
    <>
      <CustomStack>
        <SubtitleSpan>Предоплата</SubtitleSpan>
        <TypoAsValue>{prepay}</TypoAsValue>
      </CustomStack>

      {prepay && prepay_percent ? (
        <CustomStack>
          <SubtitleSpan>Размер предоплаты</SubtitleSpan>
          <TypoAsValue>{prepay_percent} %</TypoAsValue>
        </CustomStack>
      ) : null}

      {postpay === "Да" ? (
        <CustomStack>
          <SubtitleSpan>Постоплата</SubtitleSpan>
          <TypoAsValue>{postpay}</TypoAsValue>
        </CustomStack>
      ) : null}

      {postpay === "Да" && postpay_days ? (
        <CustomStack>
          <SubtitleSpan>Срок постоплаты в днях</SubtitleSpan>
          <TypoAsValue>{postpay_days}</TypoAsValue>
        </CustomStack>
      ) : null}

      <CustomStack>
        <SubtitleSpan>Аккредитив</SubtitleSpan>
        <TypoAsValue>{letter_of_credit}</TypoAsValue>
      </CustomStack>

      {other_terms_of_delivery ? (
        <CustomStack>
          <SubtitleSpan>Условия поставки</SubtitleSpan>
          <TypoAsValue>{other_terms_of_delivery}</TypoAsValue>
        </CustomStack>
      ) : null}

      {/* {delivery_days ? (
              <>
                <p >Срок поставки</p>
                <p >
                  {delivery_days_format} дней: {delivery_days}
                </p>
                <p >Формат поставки</p>
                <p >{delivery_format}</p>
              </>
            ) : null} */}
      {delivery ? (
        <CustomStack>
          <SubtitleSpan>Доставка</SubtitleSpan>
          <TypoAsValue>{delivery ? "Да" : "Нет"}</TypoAsValue>
        </CustomStack>
      ) : null}
      {ts_delivery_before ? (
        <CustomStack>
          <SubtitleSpan>Доставить до</SubtitleSpan>
          <TypoAsValue>{format(new Date(ts_delivery_before), PATTERN_DATE)}</TypoAsValue>
        </CustomStack>
      ) : null}
      {delivery_address ? (
        <CustomStack>
          <SubtitleSpan>Место доставки</SubtitleSpan>
          <TypoAsValue>{delivery_address}</TypoAsValue>
        </CustomStack>
      ) : null}

      {ts_pickup_before ? (
        <CustomStack>
          <SubtitleSpan>Отгрузить до</SubtitleSpan>
          <TypoAsValue>{format(new Date(ts_pickup_before), PATTERN_DATE)}</TypoAsValue>
        </CustomStack>
      ) : null}
    </>
  );
};

export default PaymentAndDelivery;
