import StarterKit from "@tiptap/starter-kit";
import WithLeftBorder from "../nodes/TypoWithLeftBorder/extension";
import DefaultText from "../nodes/DefaultText/extension";
import {Color} from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import BoldText from "../nodes/BoldText/extension";
import List from "../nodes/List/extension";
import ImportantText from "../nodes/ImportantText/extension";
import BigText from "../nodes/BigText/extension";
import BlockWithBackground from "../nodes/BlockWithBackground/extension";
import Link from "@tiptap/extension-link";
import Image from "@tiptap/extension-image";

export const extensions = [
  StarterKit,
  TextStyle,
  Color,
  WithLeftBorder,
  DefaultText,
  BoldText,
  List,
  ImportantText,
  BigText,
  BlockWithBackground,
  Link.configure({
    openOnClick: false,
    autolink: true,
  }),
  Image.configure({
    inline: true,
    allowBase64: true,
  }),
];
