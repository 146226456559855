import {useRecordContext, FunctionField} from "react-admin";

type BlockType = {
  text: string;
  color: string;
};

type ActionsType = Partial<{
  WARN: BlockType;
  BLOCK: BlockType;
  UNBLOCK: BlockType;
}>;

const DEFAULT_ACTIONS = {
  WARN: {text: "Предупреждение", color: "orange"},
  BLOCK: {text: "Блокировка", color: "red"},
  UNBLOCK: {text: "Разблокировка", color: "green"},
};

export const MyFunctionalActionField = ({
  actions = DEFAULT_ACTIONS,
}: {
  actions?: ActionsType;
}) => {
  const record = useRecordContext();

  return (
    <FunctionField
      source="action"
      label="Действие"
      render={(record) => actions[`${record.action}`].text}
      sx={({palette}) => ({
        color: palette[`${actions[`${record.action}`].color}`].main,
      })}
      textAlign="center"
    />
  );
};

MyFunctionalActionField.defaultProps = {
  label: "Действие",
};
