import {ticketsColors} from "shared/constants/ticketsStatus";

/**
 * Функция для получения [primary, secondary] из объекта ticketsColors
 * для использования цвета статуса через MUI palette
 * @see ticketsColors - объект с цветами для статусов заявок
 */
export const getColorForStatus = (value: string | undefined): (string | undefined)[] => {
  return (ticketsColors[value || ""] || "")?.split(".");
};
