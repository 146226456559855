import React, {useEffect} from "react";
import {TextInput, ImageField, NumberInput, useRecordContext, Labeled} from "react-admin";
import {useFormContext} from "react-hook-form";
import {RichTextInput} from "ra-input-rich-text";
import {WrapperImageField} from "pages/news/NewsEdit";
import {useWatch} from "react-hook-form";
import {TiptapWrapper} from "shared/components/Tiptap";

export const ArticleFields = () => {
  const record = useRecordContext();
  const formMethods = useFormContext();

  return (
    <>
      <NumberInput source="position" label="Позиция" />

      <TextInput source="title" label="Заголовок статьи" fullWidth />
      <TextInput source="sub_title" label="Подзаголовок статьи" fullWidth />

      <RichTextInput
        source="card_title"
        label="Заголовок карточки"
        toolbar={<></>}
        fullWidth
        sx={{maxHeight: "40px !important", height: "40px", minHeight: "40px"}}
      />

      <WrapperImageField />
      <CurrentImage />
      <TiptapWrapper html={record?.html || ""} formMethods={formMethods} />
    </>
  );
};

function CurrentImage() {
  const {setValue} = useFormContext();
  const watchedNewImg = useWatch({name: "preview_file"});
  const currentImg = useWatch({name: "preview_url"});

  const [prevImg, setPrevImg] = React.useState("");

  useEffect(() => {
    if (watchedNewImg && currentImg) {
      setPrevImg(currentImg);
      setValue("preview_url", "");
    }

    if (!watchedNewImg && !currentImg) {
      setValue("preview_url", prevImg);
    }
  }, [watchedNewImg, currentImg, setValue, prevImg]);

  return currentImg ? (
    <Labeled>
      <ImageField
        sx={{"& img": {width: "300px !important", height: "300px !important"}}}
        source="preview_url"
        label={"Текущее изображение"}
      />
    </Labeled>
  ) : null;
}
