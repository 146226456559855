import {PayloadAction, createSlice} from "@reduxjs/toolkit";
import {resetState} from "../../store/resetState";
import {generateActionsForSync} from "../../utils/generateActionsForSync";
import {IWSState} from "./types";

export const WSSlice = createSlice({
  name: "webSocket",

  initialState: {} as IWSState,
  reducers: {
    ...resetState,
    connected: (
      state,
      {payload}: PayloadAction<{server: string; connectedID: string}>,
    ) => {
      console.log("WSSlice connected trigger");
      const ps = payload.server;
      const s = state[ps];
      state[ps] = {
        ...s,
        isLoading: false,
        isConnected: true,
        connectedID: payload.connectedID,
      };
    },
    disconnected: (state, {payload}: PayloadAction<{server: string}>) => {
      const ps = payload.server;
      const s = state[ps];
      state[ps] = {...s, isLoading: false, isConnected: false};
    },
    initWS: (state, {payload}) => {
      const ps = payload.server;
      const s = state[ps];
      state[ps] = {...s, userId: payload.message?.userId};
    },
    setErrorWebSocket: (state, {payload}) => {
      state.error = payload;
      Object.assign(state[payload.server], {error: payload});
      Object.assign(state[payload.server], {isLoading: false});
    },
  },
});

export const WSSliceActions = generateActionsForSync(WSSlice);
export const {connected, setErrorWebSocket, initWS, disconnected} = WSSlice.actions;

export default WSSlice.reducer;
