/**
 * функция преобразует число в удобочитаемый для человека вид с разделителями разрядов
 */
export function separateValueForMoney(numStr: string | number = ""): string {
  numStr = numStr.toString();
  numStr = numStr.replace(".", ",");

  if (numStr.trim() === "" || /^[0,\s]*$/.test(numStr)) {
    return "0,00";
  }
  const strippedStr = numStr.replace(/[^\d,]/g, "");
  // eslint-disable-next-line prefer-const
  let [intStr, decStr] = strippedStr.split(",");
  let formattedIntStr = "";
  intStr = intStr.replace(/^0+/, "");
  if (!intStr) intStr = "0";

  for (let i = intStr.length - 1; i >= 0; i--) {
    const digit = intStr.charAt(i);
    const positionFromRight = intStr.length - 1 - i;
    if (positionFromRight % 3 === 0 && positionFromRight !== 0) {
      formattedIntStr = ` ${formattedIntStr}`;
    }
    formattedIntStr = `${digit}${formattedIntStr}`;
  }

  let formattedDecStr = decStr ? `,${decStr.slice(0, 2)}` : ",00";
  if (formattedDecStr.length === 2) formattedDecStr += 0;

  return `${formattedIntStr}${formattedDecStr}`;
}
