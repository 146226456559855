import axios from "axios";
import {API_URL} from "shared/constants/apiURL";

export const markInvoiceAsPaidRequest = async ({
  invoice_id,
  activate_subscriptions,
}: {
  invoice_id: string;
  activate_subscriptions: boolean;
}) =>
  await axios.post(
    `${API_URL}/fin/v2/admin/mark-invoice-as_paid?invoice_id=${invoice_id}&activate_subscriptions=${activate_subscriptions}`,
  );
