import {UserInSafeDealActionView} from "./../../../../typesFromApi/types/safeDealApi";
import axios from "axios";
import {DataProvider} from "react-admin";
import {API_URL} from "shared/constants/apiURL";
import {defaultParams, handleConvertCompanyReqBody} from "../utils/helpers";
import {defaultProvider} from "./defaultProvider";
import {ROUTES} from "shared/constants/ROUTES";

export const companyProvider = {
  ...defaultProvider(),

  getList: async (resource = "", params) => {
    const {data} = await axios.post(
      `${API_URL}/${resource}`,
      handleConvertCompanyReqBody(params),
    );
    const total = data?.totalCount || 0;

    const replacedData = (data?.items || []).map((item) => {
      item.isActive = !item.isActive;
      return item;
    });

    return {total, data: replacedData};
  },

  getOne: async (resource = "", params) => {
    if (resource === ROUTES.COMPANY_FILES.path) {
      const data = (await axios.get(`${API_URL}/${resource}?company_id=${params.id}`))
        ?.data;

      return {data: {id: params.id, files: data}};
    }

    /* default fetching single company */
    defaultParams.filter.companyId = params?.id;

    const {data} = await axios.post(
      `${API_URL}/${resource}`,
      handleConvertCompanyReqBody(defaultParams),
    );
    const replacedData = {...data.items[0], isActive: !data.items[0].isActive};

    return {data: replacedData};
  },
} as DataProvider;
