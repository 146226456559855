import {Create, SimpleForm, TextInput, NumberInput, BooleanInput} from "react-admin";
import {Box, Stack, Typography} from "@mui/material";
import {SaveToolbar} from "shared/components/SaveToolbar/SaveToolbar";
import {AdditionalErrors} from "pages/father/components/AdditionalErrors";
import {ALREADY_DISPLAYED_KEYS} from "./helpers/constants";
import {DaysMonthYearsComponent} from "./components/DaysMonthYearsComponent";
import {SelectOfGroupTariffs} from "./components/SelectOfGroupTariffs";
import SubscriptionsWrapper from "./components/SelectOfSubscriptionTypes";
import {maxSchema, minSchema} from "./helpers/validation";
import {SelectOfConditions} from "./components/SelectOfConditions";
import {useState} from "react";
import {IncludeSaleInputs} from "./components/IncludeSaleInputs";

export const TariffsCreate = () => {
  return (
    <Create redirect="list" title="Создание тарифа">
      <SimpleForm toolbar={<SaveToolbar />}>
        <Stack sx={{maxWidth: "1200px", width: "100%", gap: 1}}>
          <TextInput source="codename" label="Идентификатор" fullWidth />

          <TextInput source="name" label="Название" fullWidth />

          <TextInput source="title" label="Заголовок" fullWidth />
          <TextInput source="priceReason" label="Причина цены" fullWidth />
          <NumberInput
            source="priceWithNds"
            label="Цена с учетом НДС"
            min={0}
            validate={minSchema}
          />

          <NumberInput
            source="priceNoNds"
            label="Цена без учета НДС"
            min={0}
            validate={minSchema}
          />

          <NumberInput
            source="ndsPercent"
            label="Ставка НДС, %"
            min={0}
            max={100}
            validate={[maxSchema, minSchema]}
          />

          <NumberInput
            source="ndsAmount"
            label="Сумма НДС"
            min={0}
            validate={[minSchema]}
          />

          <TextInput source="s1Sku" label="Артикул 1С" />
          <TextInput source="s1Name" label="Название в 1С" />

          <DaysMonthYearsComponent />

          <SelectOfGroupTariffs />

          <SubscriptionsWrapper />

          <Box sx={{display: "flex", gap: 3}}>
            <BooleanInput
              source="canBuy"
              label="Возможно ли купить это тариф"
              defaultValue={true}
              helperText=""
            />
            <BooleanInput source="isArchived" label="Перенесен в архив" helperText="" />

            <BooleanInput
              source="isPromo"
              label="Является ли тариф 'Промо'"
              helperText=""
            />

            <BooleanInput source="isDemo" label="Демо" helperText="" />
          </Box>

          <SelectOfConditions />

          <NumberInput source="sorter" label="Порядковый номер" />

          <TextInput
            source="internalComment"
            label="Внутренний комментарий"
            multiline
            minRows={2}
          />

          <IncludeSaleInputs />

          <AdditionalErrors alreadyDisplayedKeys={ALREADY_DISPLAYED_KEYS} />
        </Stack>
      </SimpleForm>
    </Create>
  );
};
