import {
  CreateButton,
  Datagrid,
  DateField,
  FunctionField,
  List,
  TextField,
  TopToolbar,
} from "react-admin";
import {MyBooleanField} from "shared/components/MyBooleanField/MyBooleanField";
import {useFatherCtx} from "shared/hooks/fatherContext/useFatherCtx";
import {getDataGridStyles} from "shared/styles/dataGridStyles";

export const OperatorList = () => {
  const {availableRoles} = useFatherCtx();

  return (
    <List pagination={false} actions={<ListActions />} title="Список операторов">
      <Datagrid
        bulkActionButtons={false}
        sx={({palette}) => ({
          ...getDataGridStyles(palette),
        })}
        rowClick="edit"
      >
        <TextField source="email" sortable={false} textAlign="left" />
        <TextField source="fio" sortable={false} label="ФИО" textAlign="left" />
        <DateField
          source="createdAt"
          sortable={false}
          label="Создан"
          textAlign="center"
        />

        <MyBooleanField source="isActive" label="Заблокирован" textAlign="center" />
        <FunctionField
          source="roles"
          sortable={false}
          label="Роли"
          render={(record) =>
            record?.roles
              ?.map(
                (role) =>
                  role?.i10n || availableRoles?.find((item) => item.id === role)?.i10n,
              )
              .sort((a, b) => a.localeCompare(b))
              .join(", ")
          }
          textAlign="center"
        />
      </Datagrid>
    </List>
  );
};

function ListActions() {
  return (
    <TopToolbar>
      <CreateButton variant="main" sx={{px: 2}} label="Создать оператора" />
    </TopToolbar>
  );
}
