import axios from "axios";
import {DataProvider} from "react-admin";
import {API_URL} from "shared/constants/apiURL";
import {defaultProvider} from "./defaultProvider";
import {handleConvertInvoiceGetListReqBody} from "../utils/helpers";

export const invoiceProviders = {
  ...defaultProvider(),

  getList: async (resource = "", params) => {
    const data = (
      await axios(`${API_URL}/${resource}`, {
        method: "POST",
        headers: {"Content-Type": "multipart/form-data"},
        data: handleConvertInvoiceGetListReqBody(params),
      })
    )?.data;
    const total = data?.totalCount;
    return {total, data: data?.items || []};
  },
} as DataProvider;
