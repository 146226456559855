import {DataProvider} from "react-admin";
import {defaultProvider} from "./defaultProvider";
import axios from "axios";
import {API_URL} from "shared/constants/apiURL";

export const faqProvider = {
  ...defaultProvider(),
  create: async (resource, params) => {
    try {
      const data = (await axios.post(`${API_URL}/${resource}`, {...params.data}))?.data;

      return {data: {...params.data, id: data?.id}};
    } catch (e) {
      return Promise.reject(e);
    }
  },
  update: async (resource, params) => {
    try {
      await axios(`${API_URL}/${resource}/${params.id}`, {
        method: "PUT",
        data: params.data,
      });

      return {data: params.data};
    } catch (e) {
      return Promise.reject(e);
    }
  },
} as DataProvider;
