/**
 * Заменяет в передаваемом объекте все булевы значения на "Да" или "Нет"
 */
export function replaceBooleanData(data = {}) {
  for (const key in data) {
    if (data[key] instanceof Object) replaceBooleanData(data[key]);
    if (data[key] === false) data[key] = "Нет";
    if (data[key] === true) data[key] = "Да";
  }
}
