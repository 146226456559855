import axios from "axios";
import {DataProvider} from "react-admin";
import {API_URL} from "shared/constants/apiURL";
import {defaultProvider} from "./defaultProvider";

const api = API_URL;

export const restProviders = {
  ...defaultProvider(),

  getOne: async (resource = "", params) => {
    return defaultProvider().getOne(resource, params);
  },
} as DataProvider;
