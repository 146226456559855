import {Components, Theme} from "@mui/material/styles";

export const muiInputBase: Components<Theme>["MuiInputBase"] = {
  defaultProps: {},

  styleOverrides: {
    root: ({theme}) => ({
      "&.MuiOutlinedInput-root": {
        "& > fieldset": {
          legend: {
            fontSize: "10px",
          },
        },
      },
      // "&.MuiOutlinedInput-root:hover": {
      //   "& > fieldset": {
      //     borderColor: theme.palette.primary.main,
      //   },
      // },
      // "&.MuiOutlinedInput-root.Mui-disabled": {
      //   "& > fieldset": {
      //     borderColor: theme.palette.black800.main,
      //     backgroundColor: theme.palette.black200.main,
      //     opacity: 0.3,
      //   },
      // },
      // "&.MuiInputBase-root": {
      //   "& input": {
      //     height: "34px",
      //     paddingTop: 4,
      //     paddingBottom: 4,
      //   },
      // },
    }),
  },
};
