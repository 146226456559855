import {Typography} from "@mui/material";
import {NodeViewContent, NodeViewWrapper} from "@tiptap/react";
import {NodeView} from "@tiptap/core";
import {DefaultTextNodeAttrs} from "./extension";

export const DefaultText = ({node}: NodeView<DefaultTextNodeAttrs>) => {
  return (
    <NodeViewWrapper>
      <Typography
        variant="15px"
        sx={{mt: node.attrs.marginTop, mb: node.attrs.marginBottom}}
        component={NodeViewContent}
      ></Typography>
    </NodeViewWrapper>
  );
};
