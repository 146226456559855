import {DataProvider} from "react-admin";
import {API_URL} from "shared/constants/apiURL";
import axios from "axios";
import {getParams, handleConvertCreateBlogToFD} from "../utils/helpers";
import {defaultProvider} from "./defaultProvider";
import {
  handleTransformToFormData,
  handleTransformToFormDataV2,
} from "shared/utils/handleTransformObjToFD";

export const blogProvider = {
  ...defaultProvider(),

  getList: async (resource, params) => {
    try {
      const data = (await axios.get(`${API_URL}/news/${resource}?${getParams(params)}`))
        ?.data;

      return {total: data?.total, data};
    } catch (e) {
      return Promise.reject(e);
    }
  },

  getOne: async (resource = "", params) => {
    try {
      const data = await axios.get(`${API_URL}/news/${resource}/${params?.id}`);

      return {data: {...data.data}};
    } catch (e) {
      return {data: {id: null}};
    }
  },

  create: async (resource, params) => {
    try {
      const data = (
        await axios.post(
          `${API_URL}/news/${resource}`,
          handleConvertCreateBlogToFD(params.data),
        )
      )?.data;

      return {data: {...params.data, id: data?.id}};
    } catch (e) {
      return Promise.reject(e);
    }
  },
  update: async (resource, params) => {
    try {
      await axios(`${API_URL}/news/${resource}/${params.id}`, {
        method: "PUT",
        data: handleConvertCreateBlogToFD(params.data),
      });

      return {data: {...params.data}};
    } catch (e) {
      return Promise.reject(e);
    }
  },
  delete: async (resource = "", params) => {
    try {
      const res = await axios(`${API_URL}/news/${resource}/${params.id}`, {
        method: "DELETE",
      });

      return res;
    } catch (e) {
      return Promise.reject(e);
    }
  },
} as DataProvider;
