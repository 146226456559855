import {SnackbarProps} from "@mui/material";
import {ToastProps, Toast} from "./Toast";

export const ToastContainer = ({
  toasts,
  snackbarProps,
}: {
  toasts: ToastProps[];
  snackbarProps?: SnackbarProps;
}) => {
  return (
    <>
      {toasts.map((toast) => (
        <Toast key={toast.id} toast={toast} snackbarProps={snackbarProps} />
      ))}
    </>
  );
};
