import {Stack, Box} from "@mui/material";
import {useEffect} from "react";
import {renderScrollStyle} from "shared/styles/scrollStyle";
import {MenuBar} from "../MenuBar/MenuBar";
import {useEditor, EditorContent} from "@tiptap/react";
import {extensions} from "../constants/extensions";
import {UseFormReturn, FieldValues} from "react-hook-form";

export const TiptapWrapper = ({
  html,
  formMethods,
}: {
  html: string;
  formMethods: UseFormReturn<FieldValues, any, undefined>;
}) => {
  const editor = useEditor({
    extensions: extensions,
    content: html || "",
  });

  const {reset, setValue} = formMethods;

  useEffect(() => {
    reset();
  }, []);

  const editorHtml = editor?.getHTML();

  useEffect(() => {
    setValue("html", editorHtml);
  }, [editorHtml]);

  if (!editor) return null;

  return (
    <Stack
      sx={({palette}) => ({
        minHeight: "800px",
        height: "800px",
        overflow: "auto",
        maxWidth: "1200px",
        width: "100%",

        position: "relative",
        ...renderScrollStyle(palette),
      })}
    >
      <MenuBar editor={editor} />

      <Box
        sx={({palette}) => ({
          mx: 1,
          borderRadius: 2,
          border: `0.3px dashed ${palette.border.main}`,
          backgroundColor: palette.background.default,

          img: {width: "100%"},
        })}
      >
        <EditorContent editor={editor} className="tiptapContent" />
      </Box>
    </Stack>
  );
};
