import {TChat} from "shared/ReduxSlice/chatSlice/types";

export const findChatIndex = (chats: TChat[], chatId: number): number =>
  chats.findIndex(({id}) => id === chatId);

export const updateChatList = (
  chatList: TChat[],
  chat: TChat,
  index: number,
): TChat[] => {
  if (index !== -1) {
    return [chat, ...chatList.filter((_, i) => i !== index)];
  }
  return chatList;
};

export const addChatToList = (chatList: TChat[], chat: TChat): TChat[] => [
  chat,
  ...chatList,
];
