import axios from "axios";
import {useState, useEffect} from "react";
import {API_URL} from "shared/constants/apiURL";
import {FinV2AdminListSubscriptionTypesGetApiResponse} from "typesFromApi/types/finApi";

export const useGetSubscriptionTypes = () => {
  const [subscriptionTypes, setSubscriptionTypes] =
    useState<FinV2AdminListSubscriptionTypesGetApiResponse | null>(null);

  useEffect(() => {
    axios
      .get(`${API_URL}/fin/v2/admin/list-subscription-types`)
      .then((res) => setSubscriptionTypes(res.data));
  }, []);

  return {data: subscriptionTypes};
};
