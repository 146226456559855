import {
  Box,
  FormHelperText,
  LinearProgress,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {useSendFileMessageForm} from "./hooks/useSendFileMessageForm";
import {sxEllipsis} from "shared/styles/sxEllipsis";
import {UI_ControlledTextField} from "shared/components/UI_ControlledTextField";
import UI_DottedBox from "shared/components/UI_DottedBox";
import {bytesToShort} from "shared/utils/bytesShort/bytesShort";

type TProps = {
  onClose: () => void;
  text: string;
  onSubmit: () => void;
};

const ChatSendFileMessage = ({onClose, text, onSubmit}: TProps) => {
  const {palette} = useTheme();
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    filesArray,
    handleDeleteFile,
    handleSubmit,
    isLoading,
    formMethods,
    errorText,
    progressValue,
  } = useSendFileMessageForm({onClose, text, onSubmit});
  const {control, formState} = formMethods;

  return (
    <Stack>
      <Stack alignItems="center" gap={3}>
        <UI_DottedBox
          isHover={isDragActive}
          {...getRootProps()}
          sx={{
            width: "350px",
            minHeight: "150px",
            cursor: "pointer",
            borderRadius: "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 2,
          }}
        >
          {filesArray.length ? (
            <Stack
              gap={1}
              sx={{
                justifyContent: "center",
                maxHeight: 400,
                overflow: "auto",
              }}
            >
              {filesArray.map((file) => (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 2,
                  }}
                  key={file.name}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Typography variant="h2-regular-14" sx={{maxWidth: 220, ...sxEllipsis}}>
                    {file.name}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <Typography sx={{textWrap: "nowrap"}}>
                      {bytesToShort(file.size)}
                    </Typography>
                    <CloseOutlinedIcon onClick={() => handleDeleteFile(file.name)} />
                  </Box>
                </Box>
              ))}
            </Stack>
          ) : (
            <>
              <input {...getInputProps()} />
              <Typography
                variant="subtitle1"
                sx={{
                  p: "0 10px",
                  textAlign: "center",
                  color: isDragActive ? palette.primary.main : palette.black.main,
                }}
              >
                Перенесите сюда файлы или нажмите для выбора
              </Typography>
            </>
          )}
        </UI_DottedBox>
        <UI_ControlledTextField
          sx={{maxWidth: "350px", width: "100%"}}
          controlProps={{control, name: "text"}}
          multiline
          minRows={2}
          label="Комментарий"
        />
      </Stack>

      <Stack gap={1}>
        <Box sx={{minHeight: 14}}>
          <FormHelperText error>
            {formState.errors?.root?.message || errorText}
          </FormHelperText>
          {!!progressValue && (
            <LinearProgress
              variant="determinate"
              value={progressValue}
              color="primary"
              sx={{color: palette.primary.main, marginTop: 2}}
            />
          )}
        </Box>

        <LoadingButton
          variant="main"
          sx={{width: 200, m: "0 auto"}}
          disabled={!filesArray.length}
          loading={!!isLoading}
          loadingIndicator="Ожидайте..."
          onClick={handleSubmit}
        >
          <span>Отправить</span>
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default ChatSendFileMessage;
