import {MutationCache, QueryCache, QueryClient} from "react-query";
import {ru} from "./ru";
import {handleForbiddenError} from "../utils/handleForbiddenError";
import polyglotI18nProvider from "ra-i18n-polyglot";

export const i18nProvider = polyglotI18nProvider(() => ru, "ru");

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 10 * 1000 * 60,
      retry: true,
      refetchOnWindowFocus: false,
    },
  },
  queryCache: new QueryCache({
    onError: handleForbiddenError,
  }),
  mutationCache: new MutationCache({
    onError: handleForbiddenError,
  }),
});
