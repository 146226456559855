import {Typography} from "@mui/material";
import React from "react";
import {NodeViewContent, NodeViewWrapper} from "@tiptap/react";

export const List = () => {
  return (
    <NodeViewWrapper className="react-component-with-content">
      <Typography
        variant="15px"
        component={NodeViewContent}
        sx={{
          pl: 4,
          position: "relative",
          my: 1,

          "&:before": {
            content: '""',
            position: "absolute",
            left: 0,
            top: "50%",
            transform: "translateY(-50%)",
            background: "black",
            width: "5px",
            height: "5px",
            borderRadius: "50%",
          },
        }}
      ></Typography>
    </NodeViewWrapper>
  );
};
