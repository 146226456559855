export const FIELDS_PROCEDURES = [
  {
    name: "takenToWork",
    label: "В работе",
    flex: true,
  },
  {
    name: "takenToWorkAt",
    label: "Когда взяли в работу",
    flex: true,
  },
  {
    name: "takenToWorkBy",
    fio: {
      name: "fio",
      label: "Кто взял в работу",
      flex: true,
      br: true,
    },
  },

  {title: "Основная информация"},
  {
    label: "Тип торговой процедуры",
    name: "procedure_type",
    options: [
      {text: "Конкурс", value: "1"},
      {text: "Аукцион", value: "2"},
      {text: "Запрос предложений", value: "3"},
      {text: "Экспресс-закупка", value: "4"},
    ],
    flex: true,
  },
  {
    label: " Формат торгов",
    name: "procedure_format",
    options: [
      {text: "Торги по 223 ФЗ", value: "1"},
      {text: "Коммерческие торги", value: "2"},
    ],
    flex: true,
  },
  {
    label: "Форма проведения процедуры",
    name: "application_receipt_form",
    options: [
      {text: "Электронная", value: "1"},
      {text: "Бумажная", value: "2"},
    ],
    flex: true,
    br: true,
  },

  {
    label: "Доступность",
    tooltip: "подсказка",
    name: "availability",
    options: [
      {text: "Открытая процедура", value: "1"},
      {text: "Закрытая процедура", value: "2"},
    ],
    flex: true,
  },
  {
    label: "Этапы процедуры закупки",
    name: "steps",
    options: [
      {text: "Одноэтапная процедура", value: "1"},
      {text: "Двухэтапная процедура", value: "2"},
    ],
    flex: true,
  },
  {
    label: "Возможна переторжка",
    name: "rebidding",
    tooltip: "подсказка",
    flex: true,
    br: true,
  },

  {
    label: "Альтернативные заявки",
    name: "alternative",
    options: [
      {text: "Возможны альтернативные заявки", value: "1"},
      {text: "Невозможны альтернативные заявки", value: "2"},
    ],
    flex: true,
  },
  {
    label: "Скрыть названия компаний участников",
    name: "hide_company_names",
    flex: true,
  },
  {
    label: "Конкурс на заключение рамочного договора",
    name: "framework_contract",
    flex: true,
  },
  {
    label: "Цены конфиденциальны",
    name: "conf_price",
    flex: true,
    br: true,
  },

  {
    label: "Дата публикации",
    name: "time_publication",
    tooltip:
      "Вы можете указать дату публикации. При отложенной публикации обратите внимание, что время должно учитывать затраты на модерацию процедуры оператором (при наличии)",
    flex: true,
  },
  {
    label: "Отложенная публикация",
    name: "delayed_publication",
    flex: true,
  },
  {
    label: "Часовой пояс",
    name: "time_zone",
    tooltip:
      "Вы можете указать ваш часовой пояс, в извещении отобразится скорректированная дата (если дата выпадает на нерабочий день по МСК, то значение автоматически пересчитается на следующую возможную дату)",
    options: [
      {text: "0", value: "0"},
      {text: "+1", value: "1"},
      {text: "+2", value: "2"},
      {text: "+3", value: "3"},
      {text: "+4", value: "4"},
      {text: "+5", value: "5"},
      {text: "+6", value: "6"},
      {text: "+7", value: "7"},
      {text: "+8", value: "8"},
      {text: "+9", value: "9"},
      {text: "+10", value: "10"},
      {text: "+11", value: "11"},
    ],
    flex: true,
  },
  {
    label: "Смещение часового пояса",
    name: "on_time_zone",
    tooltip: "",
    flex: true,
  },
  {
    label: "Валюта закупки ",
    name: "purchase_currency",
    options: [{text: "Рубли", value: "1"}],
    flex: true,
    br: true,
  },

  {title: "Предмет закупки"},
  {
    label: "Упрощенное заполнение",
    name: "simple_fill_out",
    flflex: true,
  },
  {
    label: "Позиционная закупка",
    name: "one_in_lot",
    flex: true,
    br: true,
  },

  {title: "Рассмотрение заявки и подведение итогов"},
  {
    label: "Окончание подачи заявок",
    name: "ts_application_finish",
    tooltip:
      "Минимальный срок длительности подачи заявок составляет от 7-15 календарных дней",
    flex: true,
  },
  {
    label: "Конкурсная комиссия",
    name: "commission",
    options: [
      {text: "Участник 1", value: "1"},
      {text: "Участник 2", value: "2"},
      {text: "Участник 3", value: "3"},
    ],
    flex: true,
  },
  {
    label: "Адрес получения заявок",
    name: "application_receipt_address",
    br: true,
    flex: true,
  },

  {
    label: "Окончание рассмотрения заявок",
    name: "ts_processing_finish",
    tooltip:
      "Для процедур с начальной ценой больше миллиона рублей минимальный срок длительности подачи заявок составляет 5 раб.дней. Если меньше или равно число миллиону, то 1 раб.день",
    flex: true,
    br: true,
  },
  {
    label: "Адрес рассмотрения заявки",
    name: "application_processing_address",
    flex: true,
    br: true,
  },

  {
    label: "Окончание подведения итогов",
    name: "ts_summarizing",
    tooltip:
      "Минимальный срок даты и времени подведения итогов составляет число рабочий день с момента окончания рассмотрения заявок",
    flex: true,
  },
  {
    label: "Адрес подведения итогов",
    name: "summarizing_address",
    flex: true,
    br: true,
  },

  {title: "Обеспечение и гарантии"},
  {
    label: "Требуется обеспечение заявки",
    name: "application_providing",
    flex: true,
  },
  {
    label: "Требуется обеспечение договора",
    name: "contract_providing",
    flex: true,
    br: true,
  },

  {title: "Условия оплаты и поставки товара"},
  {
    label: "Сроки заключения договора",
    name: "ts_deadline_contract",
    flex: true,
  },
  {
    label: "Возможен Аккредитив",
    name: "letter_of_credit",
    tooltip: "подсказка",
    flex: true,
    br: true,
  },
  {
    label: "Предоплата",
    name: "prepay",
    flex: true,
  },
  {
    label: "Постоплата",
    name: "postpay",
    flex: true,
    br: true,
  },

  {
    label: "Дополнительные условия оплаты",
    name: "other_terms_of_payment",
  },

  {
    label: "Адрес поставки",
    name: "delivery_address",
    flex: true,
  },
  {
    label: "Срок поставки",
    name: "delivery_days",
    options: [
      {text: "15", value: "15"},
      {text: "30", value: "30"},
      {text: "45", value: "45"},
      {text: "60", value: "60"},
      {text: "75", value: "75"},
      {text: "90", value: "90"},
      {text: "105", value: "105"},
      {text: "120", value: "120"},
      {text: "135", value: "135"},
      {text: "150", value: "150"},
      {text: "165", value: "165"},
      {text: "180", value: "180"},
    ],
    flex: true,
  },
  {
    label: "Формат дней",
    name: "delivery_days_format",
    options: [
      {text: "календарных", value: "1"},
      {text: "рабочих", value: "2"},
    ],
    flex: true,
    br: true,
  },

  {
    label: "Место поставки",
    name: "delivery_place",
    options: [
      {text: "Юридический адрес", value: "1"},
      {text: "Другой адрес", value: "2"},
    ],
    flex: true,
  },
  {
    label: "Формат поставки",
    name: "delivery_format",
    flex: true,
    br: true,
  },

  {
    label: "Условия поставки",
    name: "other_terms_of_delivery",
  },

  {title: "Дополнительная информация о конкурсе"},
  {
    label: "Дополнительная информация",
    name: "addition_info",
    tooltip: "Подсказка",
  },

  // {title: 'Конкурсная документация'},

  {title: "Контактная информация"},
  {
    label: "ФИО",
    tooltip: "Добавте зарегистрированного пользователя",
    name: "contact_fio",
    options: [],
    flex: true,
  },
  {
    label: "Телефон",
    name: "contact_phone",
    flex: true,
  },
  {
    label: "E-mail",
    name: "contact_mail",
    flex: true,
    br: true,
  },
  {
    label: "Начальная максимальная цена",
    name: "price_total",
    flex: true,
  },
  {
    label: " с НДС",
    name: "price_total_nds",
    flex: true,
  },
  {
    label: "Процент от суммы закупки",
    name: "application_providing_percent",
  },
  {
    label: "Сумма обеспечения",
    name: "application_providing_sum",
  },
  {
    label: "Процент от суммы закупки",
    name: "contract_providing_percent",
  },
  {
    label: "Сумма обеспечения",
    name: "contract_providing_sum",
  },

  {
    name: "nds_rate",
    label: "Тип НДС",
    options: [
      {text: "Без НДС", value: "1"},
      {text: "0%", value: "2"},
      {text: "10%", value: "3"},
      {text: "20%", value: "4"},
    ],
  },
  {
    label: "Обеспечение договора",
    name: "securing_the_contract",
  },
  {
    label: "Процент предоплаты",
    name: "prepay_percent",
    options: [
      {text: "10%", value: "1"},
      {text: "20%", value: "2"},
      {text: "30%", value: "3"},
      {text: "40%", value: "4"},
      {text: "50%", value: "5"},
      {text: "60%", value: "6"},
      {text: "70%", value: "7"},
      {text: "80%", value: "8"},
      {text: "90%", value: "9"},
      {text: "100%", value: "10"},
    ],
  },
  {
    label: "Размер предоплаты",
    name: "prepay_sum",
  },
  {
    label: "Срок постоплаты в днях",
    name: "postpay_days",
  },
  {
    label: "Размер постоплаты",
    name: "postpay_sum",
  },
  {
    label: "Место получения заявок",
    name: "application_receipt_place",
    options: [
      {text: "Юридический адрес", value: "1"},
      {text: "Другой адрес", value: "2"},
    ],
  },

  {
    label: "Место рассмотрения заявок",
    name: "application_processing_place",
    options: [
      {text: "Юридический адрес", value: "1"},
      {text: "Другой адрес", value: "2"},
    ],
  },
  {
    label: "Место подведения итогов",
    name: "summarizing_place",
    options: [
      {text: "Юридический адрес", value: "1"},
      {text: "Другой адрес", value: "2"},
    ],
  },
  {
    label: "Конкурсная комиссия",
    name: "commission_array",
  },
  {
    label: "Считать размер обеспечание в",
    name: "application_providing_sizetype",
    options: [
      {text: "%", value: "1"},
      {text: "В натуральном выражении", value: "2"},
    ],
  },
  {
    label: "Формат обеспечения ",
    name: "application_providing_format",
    options: [
      {text: "Денежные средства", value: "1"},
      {text: "Банковская гарантия", value: "2"},
    ],
  },
  {
    label: "Формат обеспечения ",
    name: "contract_providing_format",
    options: [
      {text: "Денежные средства", value: "1"},
      {text: "Банковская гарантия", value: "2"},
    ],
  },
  {
    label: "Считать размер обеспечание в",
    name: "contract_providing_sizetype",
    options: [
      {text: "%", value: "1"},
      {text: "В натуральном выражении", value: "2"},
    ],
  },

  {
    name: "auction_response_time",
    label: "Время на ответ участника аукциона, минут",
    tooltip:
      "После подачи ценового предложения Поставщика, аукцион будет автоматически продлен на заданное время",
  },
  // date
  {
    name: "updatedAt",
    label: "Дата обновления заявки",
  },
  {
    name: "createdAt",
    label: "Дата создания заявки",
  },
  {
    label: "Крайний срок начала договора",
    name: "ts_deadline_contract_start",
    flex: true,
  },
  {
    label: "Крайний срок окончания договора",
    name: "ts_deadline_contract_end",
    flex: true,
    br: true,
  },
];
