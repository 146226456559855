import {mergeAttributes, Node} from "@tiptap/core";
import {ReactNodeViewRenderer} from "@tiptap/react";
import {DefaultText} from "./DefaultText";

export type DefaultTextNodeAttrs = {
  marginTop: string;
  marginBottom: string;
};

export default Node.create<DefaultTextNodeAttrs>({
  name: "DefaultText",

  group: "block",

  content: "inline*",

  addAttributes() {
    return {
      marginTop: {
        default: "16px",
      },
      marginBottom: {
        default: "16px",
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "DefaultText",
      },
    ];
  },

  renderHTML({HTMLAttributes}) {
    return ["DefaultText", mergeAttributes(HTMLAttributes), 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(DefaultText);
  },
});
