import {useContext} from "react";
import {FatherContext} from "./fatherContext";

export const useFatherCtx = () => {
  const context = useContext(FatherContext);
  if (!context) {
    throw new Error("useFatherCtx must be used within a FatherProvider");
  }
  return context;
};
