import axios from "axios";
import {API_URL} from "shared/constants/apiURL";
import {LoginFormData, UserProfile} from "shared/types/UserTypes";
import {getFingerprintID} from "shared/utils/getFingerprintID";

type AuthType = {
  login: (data: LoginFormData) => Promise<string>;
  logout: () => Promise<void>;
  getUserInfo: () => Promise<UserProfile>;
};

export const Auth: AuthType = {
  async login(data) {
    try {
      const fingerprint = await getFingerprintID();

      await axios.post(
        API_URL + "/auth/v2/login",
        {...data, remember: true, fingerprint},
        {
          headers: {"Content-Type": "multipart/form-data"},
        },
      );
    } catch (err) {
      const error =
        err?.response?.data?.detail?.form ||
        JSON.stringify(err?.response?.data?.detail) ||
        "Произошла ошибка";

      return error;
    }
  },

  async logout() {
    await axios.get(API_URL + "/auth/v2/logout");
  },

  async getUserInfo() {
    const {data} = await axios.get(API_URL + "/users/v2/me");
    return data;
  },
};
