import {Components, Theme} from "@mui/material";

export const muiToolbar: Components<Theme>["MuiToolbar"] = {
  defaultProps: {},
  styleOverrides: {
    root: ({theme}) => ({
      alignItems: "baseline",
    }),
  },
};
