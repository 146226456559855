import React from "react";
import {HasNoAvailableRoles} from "pages/hasNoAvailableRoles/HasNoAvailableRoles";
import {CONTENT_MANAGER, OPERATOR} from "shared/constants/ROUTES";
import {useAdminAuth} from "shared/hooks/adminAuthContext/useAdminAuth";
import {FATHER_ID} from "shared/constants/father";
import {CHAT_OPERATOR_ID} from "shared/constants/chat";

/* check permissions:  */

export const PermissionByRolesWrapper = ({
  children,
}: {
  children: (
    isContentManager: boolean,
    isOperator: boolean,
    isChatOperator: boolean,
  ) => React.ReactNode;
}) => {
  const {user} = useAdminAuth();
  const roles = React.useMemo(() => {
    return user?.roles?.map((role) => role?.name);
  }, [user]);

  const isContentManager = !!roles?.includes(CONTENT_MANAGER);
  const isOperator = !!roles?.includes(OPERATOR);
  const isFather = user?.id === FATHER_ID;
  const isChatOperator = user?.id === CHAT_OPERATOR_ID;

  if (!isContentManager && !isOperator && !isFather) {
    return <HasNoAvailableRoles />;
  }

  return <>{children(isContentManager, isOperator, isChatOperator)}</>;
};
