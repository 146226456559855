import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import {Box, useTheme} from "@mui/material";
import {ChatAvatar} from "../ChatAvatar/ChatAvatar";
import {TChatType} from "shared/ReduxSlice/chatSlice/types";
import {CHAT_TYPES} from "shared/constants/chat";
import {chatUtils} from "shared/utils/chatUtils/chatUtils";
import {useAppSelector} from "shared/hooks/useAppSelector";
import {memo, useMemo} from "react";

export const ChatItemAvatar = memo(
  ({chatType, lastUserId}: {chatType: TChatType; lastUserId: string}) => {
    const {palette} = useTheme();
    const {GetChats, GetArchivedChats, GetBlackListChats} = useAppSelector(
      (state) => state.chatSlice,
    );
    const chatUserData = useMemo(
      () => chatUtils.getChatUserById({userID: lastUserId}),
      [GetChats, GetArchivedChats, GetBlackListChats, lastUserId],
    );

    if (chatType === CHAT_TYPES.GROUP) {
      return (
        <Box
          sx={{
            bgcolor: palette.black200.main,
            borderRadius: "50%",
            height: 42,
            width: 42,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexShrink: 0,
          }}
        >
          <GroupsOutlinedIcon sx={{width: 30, height: 30, color: palette.black.main}} />
        </Box>
      );
    }
    if (chatType === CHAT_TYPES.MEETING) {
      return (
        <Box
          sx={{
            bgcolor: palette.black200.main,
            borderRadius: "50%",
            height: 42,
            width: 42,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexShrink: 0,
          }}
        >
          <PeopleAltOutlinedIcon
            sx={{width: 30, height: 30, color: palette.black.main}}
          />
        </Box>
      );
    }
    return (
      <ChatAvatar
        withStatus={chatType === CHAT_TYPES.DIRECT}
        online={chatUserData?.online.isOnline}
        src={chatUserData?.avatar?.thumb_url}
      />
    );
  },
);
