import {Stack, useTheme} from "@mui/material";
import {memo, useRef, useEffect} from "react";
import {Fragment} from "react/jsx-runtime";
import {
  setChatScrollState,
  updateLastMessageInChat,
} from "shared/ReduxSlice/chatSlice/chatSlice";
import {useAppDispatch} from "shared/hooks/useAppDispatch";
import {useAppSelector} from "shared/hooks/useAppSelector";
import {renderScrollStyle} from "shared/styles/scrollStyle";
import {chatUtils} from "shared/utils/chatUtils/chatUtils";
import MessageItem from "./components/MessageItem";
import {BorderDay, ScrollToEndButton} from "../../../../../components";
import {GetMessagesApiArg} from "typesFromApi/types/chatApi";
import {ChatEvents} from "shared/utils/chatUtils/ChatEvents";

export const MessagesList = memo(() => {
  const {GetMessagesScroll, lastRededMessage, GetMessages, currentChat} = useAppSelector(
    (state) => state.chatSlice,
  );

  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const {palette} = useTheme();
  const listMessagesRef = useRef<null | HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!lastRededMessage || lastRededMessage === 1 || !currentChat) return;

    const reqBody: GetMessagesApiArg["httpChatGetMessagesDto"]["message"] = {
      chatId: currentChat?.id,
      limit: 20,
      startOrder: "before",
      start: lastRededMessage - 1,
      middle: null,
    };

    chatUtils.bsSend(reqBody, ChatEvents.ChatService.GetMessages);
    dispatch(setChatScrollState(false));
  }, [lastRededMessage]);

  // Скролл списка сообщений к последнему непрочитанному или в конец списка
  useEffect(() => {
    if (!GetMessagesScroll) return;
    if (!listMessagesRef.current || !messagesEndRef.current) return;
    if (!currentChat?.id) return;
    if (!GetMessages[currentChat?.id]) return;

    const {firstUnreadMessageId} = currentChat;

    const msg = document.getElementById("chat_msg" + firstUnreadMessageId);
    if (msg) {
      listMessagesRef.current.style.scrollBehavior = "smooth";
      listMessagesRef.current.scrollTo(0, msg?.offsetTop || 0);
      dispatch(setChatScrollState(false));
    } else {
      messagesEndRef.current?.scrollIntoView({behavior: "smooth"});
    }
  }, [GetMessages, GetMessagesScroll, currentChat?.id, dispatch]);

  // Установка слушателя на прокручивание чата вверх
  useEffect(() => {
    if (!currentChat?.id) return;

    const lastMessageListener = () => {
      if (listMessagesRef.current && listMessagesRef.current.scrollTop === 0) {
        dispatch(updateLastMessageInChat({chatId: currentChat?.id, isNeeded: true}));
      }
    };

    listMessagesRef.current?.addEventListener("scroll", lastMessageListener);
    return () => {
      listMessagesRef.current?.removeEventListener("scroll", lastMessageListener);
    };
  }, [currentChat?.id]);

  // Загрузка предыдущих сообщений при прокрутке чата вверх
  useEffect(() => {
    if (!lastRededMessage || !listMessagesRef.current) return;
    const message = chatUtils.getMsgPosition({messageId: lastRededMessage});
    if (message) {
      listMessagesRef.current.scrollTop = message;
    }
    dispatch(updateLastMessageInChat({isNeeded: false}));
  }, [lastRededMessage]);

  return (
    <Stack sx={{width: "100%", height: "100%", position: "relative"}}>
      <Stack
        gap={1}
        sx={{padding: 2, overflow: "auto", ...renderScrollStyle(palette)}}
        ref={listMessagesRef}
      >
        {GetMessages[currentChat?.id || ""]?.map((message, index) => (
          <Fragment key={message.id}>
            <BorderDay messages={GetMessages[currentChat?.id || ""]} index={index} />
            <MessageItem message={message} />
          </Fragment>
        ))}
        <div ref={messagesEndRef} />
      </Stack>
      <ScrollToEndButton ref={listMessagesRef} messagesEndRef={messagesEndRef} />
    </Stack>
  );
});
