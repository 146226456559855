import React, {useState} from "react";
import {AdminAuthContext} from "shared/hooks/adminAuthContext/adminAuthContext";
import {UserProfile} from "shared/types/UserTypes";

export const AdminAuthProvider = ({children}: {children: React.ReactNode}) => {
  const [user, setUser] = useState<UserProfile | null>(null);

  return (
    <AdminAuthContext.Provider value={{user, setUser}}>
      {children}
    </AdminAuthContext.Provider>
  );
};
