import {reducers} from "../storeReducer";

export const resetMiddleware = (store) => (next) => (action) => {
  if (action.type === "reset") {
    const dispatch = store.dispatch;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Object.values(reducers).forEach((x: any) => {
      x.actions?.reset && dispatch(x.actions.reset(x.getInitialState()));
    });
  }
  return next(action);
};
