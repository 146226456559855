import {mergeAttributes, Node} from "@tiptap/core";
import {ReactNodeViewRenderer} from "@tiptap/react";
import {ImportantText} from "./ImportantText";

export type ImportantTextNodeAttrs = {
  icon: string;
};

export default Node.create<ImportantTextNodeAttrs>({
  name: "ImportantText",

  group: "block",

  content: "inline*",

  addAttributes() {
    return {
      icon: {
        default: "info",
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "ImportantText",
      },
    ];
  },

  renderHTML({HTMLAttributes}) {
    return ["ImportantText", mergeAttributes(HTMLAttributes), 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(ImportantText);
  },
});
