import {ArchiveOutlined, BlockOutlined} from "@mui/icons-material";
import {Menu, MenuItem} from "@mui/material";
import {ArchiveApiArg, UserBlacklistApiArg} from "typesFromApi/types/chatApi";
import {TChat} from "shared/ReduxSlice/chatSlice/types";
import {CHAT_TYPES} from "shared/constants/chat";
import {ChatEvents} from "shared/utils/chatUtils/ChatEvents";
import {chatUtils} from "shared/utils/chatUtils/chatUtils";
import {useAppDispatch} from "shared/hooks/useAppDispatch";
import {
  resetArchivedChats,
  resetBlackListChats,
  resetGetChats,
  setCurrentMainMenu,
} from "shared/ReduxSlice/chatSlice/chatSlice";

type TProps = {
  chat: TChat;
  contextMenu: {mouseX: number; mouseY: number} | null;
  handleClose: () => void;
};

export const ChatItemContextMenu = ({chat, contextMenu, handleClose}: TProps) => {
  const {id, users, inArchive, type, userInBlackList} = chat;
  const dispatch = useAppDispatch();

  // отправка или возвращение из черного списка
  function handleToBlackList(value = false) {
    const reqBody: UserBlacklistApiArg["httpChatUserBlacklistDto"]["message"] = {
      chatId: id,
      userId: users[0],
      value,
    };
    dispatch(resetBlackListChats());
    dispatch(resetGetChats());
    chatUtils.bsSend(reqBody, ChatEvents.ChatService.UserBlacklist);
    handleClose();
    dispatch(setCurrentMainMenu("BLACKLIST"));
  }

  // отправка или возвращение из архив
  function handleToArchive(value = false) {
    const reqBody: ArchiveApiArg["httpChatArchiveDto"]["message"] = {
      chatId: id,
      value,
    };
    dispatch(resetArchivedChats());
    dispatch(resetGetChats());
    chatUtils.bsSend(reqBody, ChatEvents.ChatService.Archive);
    handleClose();
    dispatch(setCurrentMainMenu("ARCHIVE"));
  }

  return (
    <Menu
      open={contextMenu !== null}
      onClose={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={
        contextMenu !== null
          ? {top: contextMenu.mouseY, left: contextMenu.mouseX}
          : undefined
      }
    >
      <MenuItem
        onClick={() => handleToArchive(!inArchive)}
        sx={{display: "flex", flexDirection: "row", alignItems: "center", gap: 2}}
      >
        <ArchiveOutlined />
        {inArchive ? "Убрать из архива" : "В архив"}
      </MenuItem>
      {type === CHAT_TYPES.DIRECT && (
        <MenuItem
          onClick={() => handleToBlackList(!userInBlackList)}
          sx={{display: "flex", flexDirection: "row", alignItems: "center", gap: 2}}
        >
          <BlockOutlined />
          {userInBlackList ? "Убрать из черного списка" : "В черный список"}
        </MenuItem>
      )}
    </Menu>
  );
};
