import {FormHelperText} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useFormContext} from "react-hook-form";

export const AdditionalErrors = ({
  alreadyDisplayedKeys = [],
}: {
  alreadyDisplayedKeys: string[];
}) => {
  const {formState, clearErrors} = useFormContext();
  const [addErrors, setAddErrors] = useState<{[key: string]: any}>({});

  useEffect(() => {
    const additionalErrors = Object.keys(formState.errors).filter(
      (key) => !alreadyDisplayedKeys.includes(key),
    );

    if (additionalErrors.length > 0) {
      const errorsObj = {};
      additionalErrors.forEach((key) => {
        errorsObj[key] = formState?.errors[key]?.message;
      });

      setAddErrors(errorsObj);
      clearErrors();
    }
  }, [formState]);

  return (
    <>
      {Object.keys(addErrors).length > 0 ? (
        <FormHelperText error>
          {Object.keys(addErrors).map((key) => (
            <React.Fragment key={key}>
              {addErrors[key] || "Произошла ошибка"}
              <br />
            </React.Fragment>
          ))}
        </FormHelperText>
      ) : null}
    </>
  );
};
