import {Box} from "@mui/material";
import React from "react";
import {useAppSelector} from "shared/hooks/useAppSelector";

export const MessagesUnread = () => {
  const {GetUnreadMessagesCnt} = useAppSelector((state) => state.chatSlice);
  return (
    <>
      {GetUnreadMessagesCnt > 0 && (
        <Box
          sx={({palette}) => ({
            fontSize: "10px",
            width: 16,
            height: 16,
            borderRadius: "50%",
            position: "absolute",
            textAlign: "center",
            top: "-8px",
            right: "-6px",
            backgroundColor: palette.purple.main,
            color: palette.white.main,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: 400,
          })}
        >
          {GetUnreadMessagesCnt > 99 ? "99" : GetUnreadMessagesCnt}
        </Box>
      )}
    </>
  );
};
