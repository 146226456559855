import {Box, Card, Stack, Typography} from "@mui/material";
import DoNotTouchOutlinedIcon from "@mui/icons-material/DoNotTouchOutlined";

/**
 * Страница для отображения, если такого url не существует либо у пользователя нет прав для посещения страницы
 */
export const NotFound = () => (
  <Box
    sx={{
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <Card
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Stack sx={{alignItems: "center"}}>
        <DoNotTouchOutlinedIcon
          sx={({palette}) => ({fontSize: "140px", color: palette.primary.light})}
        />
        <Typography
          variant="h3"
          sx={({palette}) => ({
            color: palette.text.secondary,
          })}
        >
          Доступ запрещен
        </Typography>
      </Stack>
    </Card>
  </Box>
);
