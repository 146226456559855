import {api} from "typesFromApi/api";
// import {chatSliceActions} from "shared/ReduxSlices/chatSlice/chatSlice";
import {createStateSyncMiddleware} from "redux-state-sync";
import {resetMiddleware} from "./middlewares/resetMiddleware";
import {listenerMiddleware} from "./middlewares/listenerMiddleware";

import {chatSliceActions} from "shared/ReduxSlice/chatSlice/chatSlice";
import {bcSliceActions} from "shared/ReduxSlice/bcSlice/bcSlice";
import {WSSliceActions} from "shared/ReduxSlice/webSocketSlice/WSSlice";

//массив диспатчей, для синхронизации
// например ["chatSlice/chatInit", ...]
export const middlewares = [
  listenerMiddleware.middleware,
  api.middleware,
  resetMiddleware,
  createStateSyncMiddleware({
    whitelist: [...chatSliceActions, ...bcSliceActions, ...WSSliceActions],
  }),
];
