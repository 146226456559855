import {
  Labeled,
  Show,
  SimpleShowLayout,
  TextField,
  useRedirect,
  useShowContext,
  useShowController,
} from "react-admin";
import TicketsEditButton from "../components/TicketsEditButton/TicketsEditButton";
import {replaceBooleanData} from "shared/utils/replaceBooleanData";
import {
  Box,
  Card,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import Loader from "shared/components/Loader/Loader";
import {DeepRequired, ModifiedBooleanToString} from "shared/helpers/typeHelpers";
import {LotCommercial} from "./components/LotsCommercial";
import PaymentAndDelivery from "./components/PaymentAndDelivery";
import {Guarantee} from "./components/Guarantee";
import {Dates} from "./components/Dates";
import Contact from "./components/Contact";
import FileList from "shared/components/FileList/FileList";
import {separateValueForMoney} from "shared/utils/separateValueForMoney";
import Time from "./components/Time";
import {replaceData} from "./utils";
import {SubtitleSpan} from "../components/SubtitleSpan/SubtitleSpan";
import {SectionTitle} from "./components/SectionTitle";
import {TypoAsValue} from "../components/TypoAsValue/TypoAsValue";
import {TicketsOneApiResponse} from "typesFromApi/types/proceduresApi";
import {ROUTES} from "shared/constants/ROUTES";
import {MyUrlField} from "shared/components/MyUrlField/MyUrlField";
import {getColorForStatus} from "shared/utils/getColorForStatus";

const colsWidth = ["80px", "auto", "100px", "150px", "150px"];
const colsNames = ["№", "Наименование", "Ед.", "Кол.", "Возможен аналог"];

const TicketsEditProcedures = () => {
  const redirect = useRedirect();
  useShowController({
    queryOptions: {
      onError: (error) => {
        redirect("/");
      },
    },
  });

  return (
    <Show>
      <Fields />
    </Show>
  );
};

type RequiredTicket = DeepRequired<TicketsOneApiResponse>;
export type TicketType = ModifiedBooleanToString<RequiredTicket["ticket"]>;

function Fields() {
  const {record = {} as TicketType, isLoading} = useShowContext<TicketType>();

  replaceBooleanData(record);
  replaceData(record);

  const {
    time_publication,
    price_total_nds,
    ts_summarizing,
    ts_application_finish,
    addition_info,
  } = record;

  const isExpress = record?.procedure_type + "" === "Экспресс-закупка";

  const time3 =
    typeof time_publication === "string"
      ? {name: "Опубликовано", time: time_publication}
      : time_publication;

  const time2 = {name: "Подача заявок до", time: ts_application_finish};
  const time1 = {name: "Подведение итогов", time: ts_summarizing};

  const [primary, secondary] = getColorForStatus(record?.ticket_status);

  if (isLoading) return <Loader />;
  return (
    <SimpleShowLayout>
      <Labeled sx={{width: "100%"}}>
        <TextField
          sx={({palette}) => ({
            color:
              primary && secondary ? palette[primary][secondary] : palette.text.primary,
          })}
          source={"ticket_status"}
          label={"Статус"}
          fontWeight={"bold"}
        />
      </Labeled>

      {record.resolution && (
        <TextField
          color={"orange"}
          source={"resolution"}
          label={"Комментарий администратора"}
        />
      )}

      <Card sx={{mt: 1, p: "10px 40px"}}>
        <Box sx={{display: "flex", gap: "40px"}}>
          <Stack gap={"8px"}>
            <Labeled sx={{mb: 0}}>
              <TextField source="id" label="Процедура" sx={{lineHeight: 1.5}} />
            </Labeled>

            <Labeled label={"Организатор"} sx={{mb: 0}}>
              <MyUrlField
                source={"company.name"}
                label="Организатор"
                to={(record) =>
                  `/${ROUTES.COMPANY_LIST.path}/${record?.company?.id}/show`
                }
              />
            </Labeled>

            <Labeled sx={{mb: 0}}>
              <TextField
                source="contact_fio"
                label="Контактное лицо"
                sx={{lineHeight: 1.5}}
              />
            </Labeled>
          </Stack>
          <Stack gap={"8px"}>
            <Labeled sx={{mb: 0}}>
              <TextField
                source="procedure_type"
                label="Вид процедуры"
                sx={{lineHeight: 1.5}}
              />
            </Labeled>
            <Labeled sx={{mb: 0}}>
              <TextField
                source="availability"
                label="Тип процедуры"
                sx={{lineHeight: 1.5}}
              />
            </Labeled>
            <Stack>
              <SubtitleSpan>Начальная цена</SubtitleSpan>
              <TypoAsValue>
                {isExpress ? "-" : separateValueForMoney(price_total_nds)}
              </TypoAsValue>
            </Stack>
          </Stack>
          <Stack gap={"8px"}>
            <Stack>
              <SubtitleSpan>{(time3 as any)?.name || "Опубликовано"}</SubtitleSpan>
              {time3 ? <Time timeObj={time3} /> : <TypoAsValue>-</TypoAsValue>}
            </Stack>

            <Stack>
              <SubtitleSpan>{time2?.name}</SubtitleSpan>
              {time2?.time ? <Time timeObj={time2} /> : <TypoAsValue>-</TypoAsValue>}
            </Stack>

            <Stack>
              <SubtitleSpan>{time1?.name}</SubtitleSpan>
              {time1?.time ? <Time timeObj={time1} /> : <TypoAsValue>-</TypoAsValue>}
            </Stack>
          </Stack>
        </Box>
      </Card>

      <Labeled>
        <TextField source="purchase_subject_desc" label="Предмет закупки" />
      </Labeled>

      {/* OLD_VERSION: */}
      {/* {Object.keys(record?.commission_array || {}).map((el, i) => {
        return i === 0 ? (
          <Labeled key={i}>
            <TextField
              record={{fio: record?.commission_array[el]}}
              label={"Состав комиссии"}
              source={"fio"}
            />
          </Labeled>
        ) : (
          <div key={i}>
            <TextField record={{fio: record?.commission_array[el]}} source={"fio"} />
          </div>
        );
      })} */}

      <Box sx={{mt: 2}}>
        {/* OLD_VERSION:: */}
        {/* {record?.lots?.map((el, i) => (
          <Accordion key={i}>
            <AccordionSummary expandIcon={"▼"}>
              <Box display={"flex"} gap={2}>
                <Typography sx={{fontWeight: "bold"}}>Лот {i + 1}</Typography>
              </Box>
            </AccordionSummary>

            <AccordionDetails>
              <SimpleShowLayout record={el}>
                <Box display={"flex"} gap={2}>
                  <Labeled>
                    <TextField source="lot_name" label={"Название"} />
                  </Labeled>
                  <Labeled>
                    <TextField source="lot_count" label={`Кол-во в ${el.lot_unit}`} />
                  </Labeled>
                  <Labeled>
                    <TextField source="alternative_lot" label={"Возможен аналог"} />
                  </Labeled>
                  <Labeled>
                    <TextField source="description" label={"Описание"} />
                  </Labeled>
                  <Labeled>
                    <TextField source="price_nds" label={"Цена с НДС"} />
                  </Labeled>

                  <Labeled>
                    <TextField source="okpd2" label={"ОКПД2"} />
                  </Labeled>
                  <Labeled>
                    <TextField source="auction_options.min_step" label={"Шаг аукциона"} />
                  </Labeled>
                  <Labeled>
                    <TextField source="price" label={"Цена"} />
                  </Labeled>
                </Box>
              </SimpleShowLayout>

              <SubLots record={el?.products} />
            </AccordionDetails>
          </Accordion>
        ))} */}

        {isExpress ? (
          <Table>
            <colgroup>
              {colsWidth.map((width, i) => (
                <col key={i} width={width} />
              ))}
            </colgroup>
            <TableHead>
              <TableRow
                sx={({palette}) => ({
                  backgroundColor: palette.primary.light,
                  color: "white",
                })}
              >
                {colsNames.map((name, i) => (
                  <TableCell key={i} sx={{color: "white"}}>
                    {name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* 
        //@ts-ignore DIFF_TYPE: lots: null*/}
              {(record?.lots || [])?.map((lot: any, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <Tooltip title={lot.lot_name}>
                      <Typography textAlign={"left"}>{lot.lot_name}</Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell>{lot.lot_unit}</TableCell>
                  <TableCell>{lot.lot_count}</TableCell>
                  <TableCell>{lot.alternative_lot ? "Да" : "Нет"}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          // @ts-ignore DIFF_TYPE: lots: null
          (record?.lots || [])?.map((lot, index) => (
            <LotCommercial lot={lot} lotIndex={index} key={index} />
          ))
        )}
      </Box>

      <SectionTitle>Условия оплаты и поставки товара</SectionTitle>
      <PaymentAndDelivery ticket={record} />

      <SectionTitle>Обеспечение</SectionTitle>
      <Guarantee ticket={record} />

      <SectionTitle>Сроки проведения процедуры</SectionTitle>
      <Dates ticket={record} />

      <SectionTitle>Контактная информация</SectionTitle>
      <Contact ticket={record} />

      <FileList />

      {addition_info && (
        <>
          <SectionTitle>Дополнительная информация</SectionTitle>
          <TypoAsValue>{addition_info}</TypoAsValue>
        </>
      )}

      {/* OLD_VERSION: */}
      {/* {parseFields(record, FIELDS_PROCEDURES)} */}
      <TicketsEditButton />
    </SimpleShowLayout>
  );
}

/* OLD_VERSION: */
// function SubLots({record = []}: {record: any}) {
//   if (!record?.length) return <Typography>Товаров нет</Typography>;
//   return record?.map((el, i) => (
//     <Accordion key={i}>
//       <AccordionSummary expandIcon={"▼"}>{el?.prod_name}</AccordionSummary>
//       <AccordionDetails>
//         <SimpleShowLayout record={el}>
//           {/* <Labeled>
//             <TextField source="catalog_prod.name" label={"Наименование"} />
//           </Labeled> */}
//           <Box display={"flex"} gap={2}>
//             {/* <Labeled>
//               <TextField source="okpd2" label={"ОКПД2"} />
//             </Labeled> */}
//             <Labeled>
//               <TextField source="count" label={"Кол-во"} />
//             </Labeled>
//             <Labeled>
//               <TextField source="unit.name" label={"Единица"} />
//             </Labeled>
//             <Labeled>
//               <TextField source="price_one" label={"Цена шт."} />
//             </Labeled>
//             <Labeled>
//               <TextField source="price_all" label={"Цена общ."} />
//             </Labeled>
//             <Labeled>
//               <TextField source="price_all_nds" label={"Цена общ. c НДС"} />
//             </Labeled>
//             <Labeled>
//               <TextField source="nds_rate" label={"Значение НДС"} />
//             </Labeled>
//             <Labeled>
//               <TextField source="nds_sum" label={"Сумма НДС"} />
//             </Labeled>
//           </Box>
//           <TextField  source="description" label={"Описание"} />

//           <ProductFeatures record={el?.catalog_chars} />
//         </SimpleShowLayout>
//       </AccordionDetails>
//     </Accordion>
//   ));
// }

/* OLD_VERSION: */
// function ProductFeatures({record = []}) {
//   return (
//     <>
//       <Typography sx={{fontWeight: "600"}}>Характеристики:</Typography>
//       {record?.map((el: any, i) => (
//         <Box key={i}>
//           {el?.char_name}: <TextField record={el}  source={"value_name"} />
//         </Box>
//       ))}
//     </>
//   );
// }

export default TicketsEditProcedures;
