import {SnackbarProps, Snackbar, Alert, AlertColor} from "@mui/material";

import {useToast} from "../useToast";

export type ToastProps = {
  id: number;
  severity: AlertColor;
  content: string;
  persisted: boolean;
};

export const Toast = ({
  toast,
  snackbarProps,
}: {
  toast: ToastProps;
  snackbarProps?: SnackbarProps;
}) => {
  const {removeToast} = useToast();

  const {id, content, severity, persisted} = toast;

  return (
    <Snackbar
      open={true}
      onClose={() => removeToast(id)}
      autoHideDuration={persisted ? null : 4000}
      anchorOrigin={{vertical: "top", horizontal: "right"}}
      {...snackbarProps}
    >
      <Alert onClose={() => removeToast(id)} severity={severity} sx={{width: "100%"}}>
        {content}
      </Alert>
    </Snackbar>
  );
};
