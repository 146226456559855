import {Components, Theme} from "@mui/material/styles";
import {palette} from "../paleteOverrides/paleteOverrides";

export const muiInput: Components<Theme>["MuiInput"] = {
  defaultProps: {},
  styleOverrides: {
    input: ({theme}) => ({
      // height: "100%",
      // padding: "14px",
      // fontSize: "16px",
      // fontStyle: "normal",
      // fontWeight: "400",
      // lineHeight: "normal",
      // boxSizing: "border-box",
      // "::placeholder": {
      //   color: "#9B9B9A",
      // },
      // ":disabled": {
      //   color: theme?.palette.text.disabled,
      // },
    }),
    // error: ({theme}) => ({
    //   borderColor: theme?.palette.error.main,
    // }),
    // root: ({theme}) => ({
    //   overflow: "hidden",
    //   border: `1px solid ${palette.border.main}`,
    //   borderRadius: "4px",
    //   height: "42px",
    //   // "::before": {
    //   //   display: "none",
    //   // },
    //   // ":after": {
    //   //   display: "none",
    //   // },
    //   ":hover": {
    //     border: `1px solid ${theme.palette.primary.main}`,
    //   },
    //   "&.Mui-focused": {
    //     border: `1px solid ${palette.primary.main}`,
    //   },
    //   "& .MuiOutlinedInput-root:hover": {
    //     "& > fieldset": {
    //       borderColor: theme.palette.primary.main,
    //     },
    //   },
    // }),
  },
};
