import {FIELDS_PROCEDURES} from "./fields";
import {Is} from "shared/utils/is";
import {DateField, Labeled, TextField} from "react-admin";
import {Typography} from "@mui/material";

/**
 * Функция, которая заменяет значения в полученных данных с учетом значений из FIELDS_PROCEDURES.
 */
export function replaceData(dataReplace = {}) {
  for (const key in dataReplace) {
    if (dataReplace[key] instanceof Object && key !== "methods")
      replaceData(dataReplace[key]);
    const filterField = FIELDS_PROCEDURES.find((el) => el.name === key) || {};
    dataReplace[key] =
      (filterField as any)?.options?.find((el) => el.value == dataReplace[key])?.text ||
      dataReplace[key];

    if (Array.isArray(dataReplace)) {
      dataReplace.forEach((el) => {
        replaceData(el);
      });
    }
  }
}

const exclude = ["id", "status", "ticket_status", "status_from_read"];

/**
 * @deprecated
 * */
export function parseFields(record = {}, fieldsObj: any = []) {
  const array = Is.array(fieldsObj) ? fieldsObj : Object.keys(fieldsObj);

  return array.map((el: any, i) => {
    const label = el?.label || fieldsObj[el]?.label;
    const source = el?.name || fieldsObj[el]?.name;
    const flex = el?.flex || fieldsObj[el]?.flex;
    const br = el?.br || fieldsObj[el]?.br;

    if (source in record) {
      if (Is.primitive(record[el.name]) && !exclude.includes(el.name) && label) {
        const style = {order: i + 1, width: flex ? "auto" : "100%"};
        if (/\d{4}-\d{2}-\d{2}T/.test(record[el.name])) {
          return (
            <span key={i}>
              <Labeled sx={style}>
                <DateField record={record} showTime source={source} label={label} />
              </Labeled>
              {br && <br />}
            </span>
          );
        }

        return (
          <span key={i}>
            <Labeled sx={style}>
              <TextField record={record} source={source} label={label} />
            </Labeled>
            {br && <br />}
          </span>
        );
      }

      if (Is.object(record[el.name]) && !exclude.includes(el.name)) {
        return parseFields(record[el.name], el || []);
      }
    }
    if (el.title) {
      return (
        <Typography key={i} sx={{marginTop: 3, fontWeight: "600"}} fontSize={18}>
          {el.title}
        </Typography>
      );
    }
  });
}
