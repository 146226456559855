import {Components} from "@mui/material/styles";

export const muiTable: Components["MuiTable"] = {
  defaultProps: {},
  styleOverrides: {
    root: ({theme}) => ({
      borderTopLeftRadius: "8px",
      borderTopRightRadius: "8px",
      overflow: "hidden",
    }),
  },
};
