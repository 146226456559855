import React from "react";
import {NoPermissionLogout} from "pages/noPermissionLogout/NoPermissionLogout";
import {useAdminAuth} from "shared/hooks/adminAuthContext/useAdminAuth";

export const AdminWrapper = ({children}: {children: React.ReactNode}) => {
  const {user} = useAdminAuth();
  const isAdmin = React.useMemo(() => user?.role === "Администратор", [user]);

  return isAdmin ? <>{children}</> : <NoPermissionLogout />;
};
