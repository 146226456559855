import axios from "axios";
import {ROUTES} from "shared/constants/ROUTES";
import {API_URL} from "shared/constants/apiURL";
import {WarnOrBlockAtSystemLevelRequestSchema} from "typesFromApi/types/userApi";

export const blockingRequest = async (body: WarnOrBlockAtSystemLevelRequestSchema) => {
  const fd = Object.entries(body).reduce((formData, [key, value]) => {
    formData.append(key, `${value}`);
    return formData;
  }, new FormData());
  return (await axios.post(`${API_URL}/${ROUTES.BLOCKING_USER_OR_COMPANY.path}`, fd))
    ?.data;
};

export const validateError = (detail: Record<string, string>): string => {
  if ("form" in detail) return detail.form;

  return "Произошла ошибка";
};
