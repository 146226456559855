import React, {useState} from "react";
import {Box, IconButton, Menu, MenuItem} from "@mui/material";
import {
  AttachFileOutlined,
  MoreVertOutlined,
  PeopleOutlined,
  QuestionAnswerOutlined,
} from "@mui/icons-material";
import {useAppDispatch} from "shared/hooks/useAppDispatch";
import {useAppSelector} from "shared/hooks/useAppSelector";
import {TChatType} from "shared/ReduxSlice/chatSlice/types";
import {CHAT_TYPES} from "shared/constants/chat";
import {setCurrentChatMenu} from "shared/ReduxSlice/chatSlice/chatSlice";

type ChatMenuType = {
  name: string;
  inChat: TChatType[];
  handler: () => void;
  // eslint-disable-next-line no-undef
  icon: JSX.Element;
  disable?: boolean;
  labelDisable?: string;
  hide?: boolean;
};

export default function ChatMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useAppDispatch();
  const {currentChat} = useAppSelector((state) => state.chatSlice);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const CHAT_MENU: ChatMenuType[] = [
    {
      name: "Сообщения",
      inChat: [CHAT_TYPES.GROUP, CHAT_TYPES.DIRECT, CHAT_TYPES.MEETING],
      handler: () => dispatch(setCurrentChatMenu("MESSAGES_LIST")),
      icon: <QuestionAnswerOutlined />,
    },
    {
      name: "Участники",
      inChat: [CHAT_TYPES.GROUP, CHAT_TYPES.DIRECT, CHAT_TYPES.MEETING],
      handler: () => dispatch(setCurrentChatMenu("USERS_LIST")),
      icon: <PeopleOutlined />,
    },
    {
      name: "Файлы",
      inChat: [CHAT_TYPES.GROUP, CHAT_TYPES.DIRECT, CHAT_TYPES.MEETING],
      handler: () => dispatch(setCurrentChatMenu("FILE_LIST")),
      icon: <AttachFileOutlined />,
    },
  ];

  if (!currentChat) return <></>;

  return (
    <Box>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertOutlined />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{"aria-labelledby": "long-button"}}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {CHAT_MENU.filter(
          (option) => option.inChat.includes(currentChat.type) && !option?.hide,
        )?.map(({disable, handler, icon, name}, i) => (
          <MenuItem
            key={i}
            disabled={disable}
            onClick={() => {
              handler();
              handleClose();
            }}
            sx={{display: "flex", flexDirection: "row", alignItems: "center", gap: 2}}
          >
            {icon} {name}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
