import {combineDataProviders} from "react-admin";
import {FATHER_ROUTES_LIST, ROUTES, TICKET_ROUTES} from "shared/constants/ROUTES";
import {newsProvider} from "./providers/newsProvider";
import {faqProvider} from "./providers/faqProvider";
import {ticketsListProvider} from "./providers/ticketsProvider";
import {oneTicketProvider} from "./providers/oneTicketProvider";
import {restProviders} from "./providers/restProviders";
import {usersProvider} from "./providers/usersProvider";
import {blogProvider} from "./providers/blogProvider";
import {companyProvider} from "./providers/companyProvider";
import {blocksProvider} from "./providers/blocksProvider";
import {fatherProvider} from "./providers/fatherProvider";
import {safeDealProviders} from "./providers/safeDealProvider";
import {tariffProviders} from "./providers/groupTariffsProvider";
import {promoProviders} from "./providers/promoProvider";
import {invoiceProviders} from "./providers/invoiceProvider";
import {assetsProviders} from "./providers/assetsProvider";

export const combinedDataProvider = combineDataProviders((resource) => {
  switch (true) {
    case resource === "news/news":
      return newsProvider;

    case resource === "news/faq":
      return faqProvider;

    case resource === ROUTES.GROUP_TARIFFS.path || resource === ROUTES.TARIFFS.path:
      return tariffProviders;

    case resource === ROUTES.PROMO.path:
      return promoProviders;

    case resource.startsWith(ROUTES.INVOICE.path):
      return invoiceProviders;

    case resource.startsWith(ROUTES.ASSETS.path):
      return assetsProviders;

    case FATHER_ROUTES_LIST.includes(resource):
      return fatherProvider;

    case resource.startsWith(ROUTES.USERS_AND_COMPANY_BLOCKS_LIST.path):
      return blocksProvider;

    case Object.keys(TICKET_ROUTES).includes(resource):
      return ticketsListProvider;

    case resource.startsWith("company/v2") && !resource.includes("tickets"):
      return companyProvider;

    case resource?.split("/")[1] === "tickets" || resource?.split("/")[2] === "tickets":
      return oneTicketProvider;

    case resource.startsWith("users/v2/admin/list-users"):
      return usersProvider;

    case resource === "blog":
      return blogProvider;

    case resource.startsWith("safe-deal"):
      return safeDealProviders;

    default:
      return restProviders;
  }
});
