import {Stack, Tooltip} from "@mui/material";
import {ArchiveOutlined} from "@mui/icons-material";
import {useAppDispatch} from "shared/hooks/useAppDispatch";

import {setCurrentChat, setCurrentMainMenu} from "shared/ReduxSlice/chatSlice/chatSlice";
import {TChatMenuType} from "shared/ReduxSlice/chatSlice/types";
import {StyledChatButton} from "../../components/StyledChatButton/StyledChatButton";

type TProps = {
  currentMainMenu: TChatMenuType;
  isDisabled: boolean;
};

export const ArchiveButton = ({currentMainMenu, isDisabled}: TProps) => {
  const dispatch = useAppDispatch();

  function setArchive() {
    currentMainMenu === "ARCHIVE"
      ? dispatch(setCurrentMainMenu("COMMON"))
      : dispatch(setCurrentMainMenu("ARCHIVE"));

    dispatch(setCurrentChat(null));
  }

  return (
    <Tooltip title={isDisabled ? "Компания не зарегистрирована" : "Архив"}>
      <Stack component={"span"} alignItems={"center"} justifyContent={"center"}>
        <StyledChatButton
          isActive={currentMainMenu === "ARCHIVE"}
          onClick={setArchive}
          disabled={isDisabled}
        >
          <ArchiveOutlined sx={{color: ({palette}) => palette.white.main}} />
        </StyledChatButton>
      </Stack>
    </Tooltip>
  );
};
