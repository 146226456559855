export const usersFieldForSorting = {
  createdAt: "created_at",
  updatedAt: "updated_at",
  phoneNumber: "phone_number",
  "company.inn": "company_inn",
  "company.kpp": "company_kpp",
  "company.name": "company_name",
  "company.opf": "company_opf",
  "company.customer": "company_customer",
  "company.supplier": "company_supplier",
  "company.srm_access": "company_srm_access",
  "company.isVerified": "company_is_verified",
} as const;

export const usersFilters = [
  "sortBlockedLast",
  "hideNonActive",
  "hideDismissed",
  "hideBlockedInComp",
] as const;
