import {Box, IconButton, Tooltip, useTheme} from "@mui/material";
import {NoteAdd, Description} from "@mui/icons-material";
import {TChatMessage} from "shared/ReduxSlice/chatSlice/types";
import {useAppSelector} from "shared/hooks/useAppSelector";
import {RootState} from "shared/store/store";
import {MessageInProtocolDto} from "typesFromApi/types/chatApi";
import {chatUtils} from "shared/utils/chatUtils/chatUtils";
import {ChatEvents} from "shared/utils/chatUtils/ChatEvents";

/** кнопка добавить / убрать из протокола
 * используется только в чатах типа meeting
 */
const ProtocolButton = ({
  message,
  isRight,
}: {
  message: TChatMessage;
  isRight: boolean;
}) => {
  const {currentChat} = useAppSelector((state) => state.chatSlice);
  const {palette} = useTheme();

  const handleOnMessageClick = () => {
    const reqBody: MessageInProtocolDto = {
      chatId: currentChat?.id || -1,
      messageId: message.id,
      value: !message.inProtocol,
    };
    chatUtils.bsSend(reqBody, ChatEvents.ChatService.InProtocol);
  };

  // используется только в чатах типа "переговорная комната"
  if (!currentChat || currentChat.type !== "MEETING") return <></>;

  // если оппонент добавил своё сообщение в протокол
  if (!isRight && message.inProtocol)
    return (
      <Tooltip title={"В протоколе"}>
        <NoteAdd sx={{width: 20, color: palette.primary.main}} />
      </Tooltip>
    );

  // если это не моё сообщение, то нельзя добавить в протокол
  if (!isRight) return <></>;

  return (
    <IconButton onClick={handleOnMessageClick} sx={{width: 20, height: 20, p: 0}}>
      <Tooltip title={message.inProtocol ? "В протоколе" : "Добавить в протокол"}>
        <Box>
          {message.inProtocol ? (
            <NoteAdd sx={{width: 20, color: palette.primary.main}} />
          ) : (
            <Description sx={{width: 20, color: palette.primary.main}} />
          )}
        </Box>
      </Tooltip>
    </IconButton>
  );
};

export default ProtocolButton;
