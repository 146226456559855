import React, {useEffect, useState} from "react";
import axios from "axios";
import {Box, CircularProgress} from "@mui/material";
import {LoginForm} from "pages/login/Login";
import {API_URL} from "shared/constants/apiURL";
import {useAdminAuth} from "shared/hooks/adminAuthContext/useAdminAuth";

export const AuthWrapper = ({children}: {children: React.ReactNode}) => {
  const [isLoading, setIsLoading] = useState(false);
  const {user, setUser} = useAdminAuth();
  const isAuth = React.useMemo(() => !!user?.id, [user]);

  useEffect(() => {
    if (!isAuth) {
      setIsLoading(true);
      axios
        .get(API_URL + "/users/v2/me")
        .then((res) => setUser(res?.data))
        .finally(() => setIsLoading(false));
    }
  }, [isAuth, setUser]);

  if (isLoading)
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          maxWidth: "100vw",
          width: "100%",
          height: "100vh",
        }}
      >
        <CircularProgress size={120} />
      </Box>
    );

  return !isAuth ? <LoginForm /> : <>{children}</>;
};
