import {
  DateField,
  FunctionField,
  Labeled,
  Show,
  SimpleShowLayout,
  TextField,
  useRedirect,
  useShowContext,
  useShowController,
} from "react-admin";
import {Box} from "@mui/material";
import {replaceBooleanData} from "shared/utils/replaceBooleanData";
import FileList from "shared/components/FileList/FileList";
import Loader from "shared/components/Loader/Loader";
import {ModifiedBooleanToString} from "shared/helpers/typeHelpers";
import TicketsEditButton from "../components/TicketsEditButton/TicketsEditButton";
import {ViewTicket} from "typesFromApi/types/companyApi";
import {MyUrlField} from "shared/components/MyUrlField/MyUrlField";
import {ROUTES} from "shared/constants/ROUTES";
import {MyBooleanField} from "shared/components/MyBooleanField/MyBooleanField";
import {PATTERN_DATE_TIME_FULL} from "shared/constants/dates";
import {format} from "date-fns";
import {getColorForStatus} from "shared/utils/getColorForStatus";

const TicketsEditCompany = () => {
  const redirect = useRedirect();

  const {isLoading} = useShowController({
    queryOptions: {
      onError: () => {
        redirect("/");
      },
    },
  });
  return <Show>{isLoading ? <Loader /> : <Fields />}</Show>;
};

function Fields() {
  const {record = {} as ModifiedBooleanToString<ViewTicket>} =
    useShowContext<ModifiedBooleanToString<ViewTicket>>();

  replaceBooleanData(record);
  const [primary, secondary] = getColorForStatus(record?.status);

  return (
    <SimpleShowLayout>
      <TextField
        sx={({palette}) => ({
          color:
            primary && secondary ? palette[primary][secondary] : palette.text.primary,
        })}
        source={"status"}
        label={"Статус"}
        fontWeight={"bold"}
      />

      {record.resolution && (
        <TextField
          color={"orange"}
          source={"resolution"}
          label={"Комментарий администратора"}
        />
      )}

      <TextField source={"hid"} label={"Номер запроса"} />

      <TextField source={"takenToWork"} label={"В работе"} />

      {record?.takenToWork === "Да" && (
        <Box display={"flex"} gap={3}>
          <Labeled label={"Кто взял в работу"}>
            <TextField source={"takenToWorkBy.fio"} />
          </Labeled>
          <Labeled label={"Когда взял в работу"}>
            <FunctionField
              source="takenToWorkAt"
              render={(record) =>
                record?.takenToWorkAt
                  ? format(record.takenToWorkAt, PATTERN_DATE_TIME_FULL)
                  : null
              }
            />
          </Labeled>
        </Box>
      )}

      {record?.company && (
        <Box display={"flex"} gap={3}>
          <Labeled label={"Компания"}>
            <MyUrlField
              source={"company.name"}
              label="Компания"
              to={(record) => `/${ROUTES.COMPANY_LIST.path}/${record?.company?.id}/show`}
            />
          </Labeled>
          <Labeled label={"Тип компании"}>
            <TextField source={"company.opf"} />
          </Labeled>
        </Box>
      )}
      <MyUrlField
        source={"createdBy.fio"}
        label={"Создатель запроса"}
        to={(record) => `/${ROUTES.USERS.path}?userId=${record?.createdBy?.id}`}
      />
      <TextField source={"theme"} label={"Тип запроса"} fontWeight={""} />
      {record?.state?.inn && <TextField source={"state.inn"} label={"ИНН"} />}
      {record?.state?.name && <TextField source={"state.name"} label={"Имя"} />}
      {record?.state?.snils && <TextField source={"state.snils"} label={"СНИЛС"} />}
      {record?.state?.address && <TextField source={"state.address"} label={"Адрес"} />}
      {record?.state?.phoneNumber && (
        <TextField source={"state.phoneNumber"} label={"Телефон"} />
      )}

      <Box display={"flex"} gap={3} sx={{textAlign: "center"}}>
        <Labeled label={"Запрос по ЭЦП"}>
          <TextField source={"state.byEcp"} />
        </Labeled>

        <Labeled label={"Руководитель"}>
          <TextField source={"state.isTopManager"} />
        </Labeled>

        <Labeled label={"Заказчик"}>
          <TextField source={"state.customer"} />
        </Labeled>

        <Labeled label={"Поставщик"}>
          <TextField source={"state.supplier"} />
        </Labeled>

        <Labeled label={"Производитель"}>
          <TextField source={"state.producer"} />
        </Labeled>
      </Box>

      <FileList />
      <TicketsEditButton />
    </SimpleShowLayout>
  );
}

export default TicketsEditCompany;
