import React, {forwardRef} from "react";
import {Box} from "@mui/material";
import style from "./style.module.scss";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const UI_DottedBox = forwardRef<any, any>(({children, ...otherProps}, ref) => {
  const {sx, className, isHover, ...other} = otherProps;

  return (
    <Box
      sx={({palette}) => ({
        outline: `1px solid ${palette.black400.main}`,
        "--c": palette.primary.main, // цвет границы
        "--b": "3px", // толщина границы
        "--g": "10px", // отступ
        // overflow: "hidden",
        ...sx,
      })}
      className={`${style.container} ${isHover ? style.hover : ""} ${className}`}
      {...other}
      ref={ref}
    >
      {children}
    </Box>
  );
});

export default UI_DottedBox;
