import {Stack} from "@mui/material";
import {ChatWithUser} from "./components/ChatWithUser/ChatWithUser";
import {ChatList} from "./components/ChatList/ChatList";

export const ChatBody = () => {
  return (
    <Stack
      direction="row"
      sx={{
        position: "relative",
        overflow: "hidden",
        flex: 1,
      }}
    >
      <ChatList />

      <ChatWithUser />
    </Stack>
  );
};
