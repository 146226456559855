import {FunctionField} from "react-admin";
import {MyBooleanField} from "shared/components/MyBooleanField/MyBooleanField";

export const CustomBooleanField = () => {
  return (
    <FunctionField
      label={
        <>
          Блок <br /> площадкой
        </>
      }
      sortable={false}
      render={(record) => (
        <MyBooleanField
          source={record.entity === "USER" ? `user.isActive` : "company.isActive"}
        />
      )}
      textAlign="center"
    />
  );
};

CustomBooleanField.defaultProps = {
  label: (
    <>
      Блок <br /> площадкой
    </>
  ),
};
