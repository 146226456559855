import {ChatEvents} from "./ChatEvents";
import {store} from "shared/store/store";
import {
  setArchivedChats,
  setBlackListChats,
  setChatLoaders,
  setChatMedia,
  setChatMessages,
  setChatScrollState,
  setChats,
  setDirectSearchUsers,
  setGroupSearchUsers,
  setLastCreatedChat,
  setMeetingSearchUsers,
  setMessageIsRead,
  setMessageSendSuccess,
  setNewMessageInChat,
  setTypingInChat,
  setUnreadMessagesCnt,
  setUserIsOnline,
  setNewChatInChats,
} from "shared/ReduxSlice/chatSlice/chatSlice";
import {
  ArchiveApiResponse,
  ClosedApiResponse,
  DirectCreateSearchApiResponse,
  GetArchivedChatsApiResponse,
  GetBlacklistChatsApiResponse,
  GetChatApiResponse,
  GetChatsApiResponse,
  GetMediaApiResponse,
  GetMessagesApiResponse,
  GetUnreadMessagesCntApiResponse,
  GroupCreateSearchApiResponse,
  GroupLeaveApiResponse,
  IsDeliveredApiResponse,
  IsReadApiResponse,
  MeetingCreateApiResponse,
  MeetingCreateSearchApiResponse,
  MessageSendApiResponse,
  OpenedApiResponse,
  TypingApiResponse,
  UserBlacklistApiResponse,
} from "typesFromApi/types/chatApi";
import {chatUtils} from "./chatUtils";
import {CHAT_TYPES} from "./chatConstants";

// eslint-disable-next-line no-undef
let IsReadTimer: NodeJS.Timeout;

export const chatEventsHandler = {
  // Распределение логики в зависимости от приходящего евента
  [ChatEvents.ChatService.GetChats]: (payload: GetChatsApiResponse) => {
    store.dispatch(setChats(payload.message));
  },
  /* евент используется для обновления списка чатов при получении нового сообщения или приглашения */
  [ChatEvents.ChatService.GetChat]: (payload: GetChatApiResponse) => {
    store.dispatch(setNewChatInChats(payload.message));
  },
  [ChatEvents.ChatService.GetUnreadMessagesCnt]: (
    payload: GetUnreadMessagesCntApiResponse,
  ) => {
    store.dispatch(setUnreadMessagesCnt(payload.message));
  },
  // получение архивных чатов
  [ChatEvents.ChatService.GetArchivedChats]: (payload: GetArchivedChatsApiResponse) => {
    store.dispatch(setArchivedChats(payload.message));
  },
  // обновление списка архивных чатов, после его изменения
  [ChatEvents.ChatService.Archive]: (payload: ArchiveApiResponse) => {
    chatUtils.bsSend({}, ChatEvents.ChatService.GetArchivedChats);
    chatUtils.bsSend({}, ChatEvents.ChatService.GetChats);
  },
  // получение чатов из черного списка
  [ChatEvents.ChatService.GetBlackListChats]: (payload: GetBlacklistChatsApiResponse) => {
    store.dispatch(setBlackListChats(payload.message));
  },
  // обновление списка чатов из черного списка, после его изменения
  [ChatEvents.ChatService.UserBlacklist]: (payload: UserBlacklistApiResponse) => {
    chatUtils.bsSend({}, ChatEvents.ChatService.GetBlackListChats);
    chatUtils.bsSend({}, ChatEvents.ChatService.GetChats);
  },
  [ChatEvents.ChatService.GetMessages]: (payload: GetMessagesApiResponse) => {
    store.dispatch(setChatMessages(payload.message));
  },
  [ChatEvents.ChatService.GetMedia]: (payload: GetMediaApiResponse) => {
    store.dispatch(setChatMedia(payload.message));
  },
  [ChatEvents.ChatService.SendSuccess]: (payload: MessageSendApiResponse) => {
    store.dispatch(setMessageSendSuccess(payload.message));
  },
  [ChatEvents.ChatService.MessageReceived]: (payload: MessageSendApiResponse) => {
    chatUtils.playNewMessageJingle();
    store.dispatch(setNewMessageInChat(payload.message));
    store.dispatch(
      setUnreadMessagesCnt({
        unreadMessagesCnt: store.getState().chatSlice.GetUnreadMessagesCnt + 1,
      }),
    );
  },
  [ChatEvents.ChatService.Typing]: (payload: TypingApiResponse) => {
    store.dispatch(setTypingInChat(payload.message));
  },

  [ChatEvents.ChatService.UserIsOnline]: (payload: OpenedApiResponse) => {
    store.dispatch(setUserIsOnline({user: payload.message, online: true}));
  },
  [ChatEvents.ChatService.UserIsOffline]: (payload: ClosedApiResponse) => {
    store.dispatch(setUserIsOnline({user: payload.message, online: false}));
  },

  /* TODO: is it needed? */
  [ChatEvents.ChatService.DirectSearch]: (payload: DirectCreateSearchApiResponse) => {
    store.dispatch(setDirectSearchUsers(payload.message));
  },
  [ChatEvents.ChatService.GroupSearch]: (payload: GroupCreateSearchApiResponse) => {
    store.dispatch(setGroupSearchUsers(payload.message));
  },
  [ChatEvents.ChatService.MeetingSearch]: (payload: MeetingCreateSearchApiResponse) => {
    store.dispatch(setMeetingSearchUsers(payload.message));
  },

  /* TODO: is it needed ? */
  [ChatEvents.ChatService.ChatCreated]: (payload: MeetingCreateApiResponse) => {
    chatUtils.bsSend({}, ChatEvents.ChatService.GetChats);
    const type = payload.message?.chat?.type;
    if (type === CHAT_TYPES.GROUP) {
      store.dispatch(setChatLoaders({[ChatEvents.ChatService.GroupCreate]: false}));
    } else if (type === CHAT_TYPES.DIRECT) {
      store.dispatch(setLastCreatedChat(payload.message));
      store.dispatch(setChatLoaders({[ChatEvents.ChatService.DirectCreate]: false}));
    } else if (type === CHAT_TYPES.MEETING) {
      store.dispatch(setChatLoaders({[ChatEvents.ChatService.MeetingCreate]: false}));
    }
  },
  [ChatEvents.ChatService.IsRead]: (payload: IsReadApiResponse) => {
    store.dispatch(setMessageIsRead(payload.message));
    clearTimeout(IsReadTimer);
    IsReadTimer = setTimeout(() => {
      chatUtils.bsSend({}, ChatEvents.ChatService.GetUnreadMessagesCnt);
    }, 10000);
  },
  [ChatEvents.ChatService.MessageSend]: (payload: IsReadApiResponse) => {
    store.dispatch(setChatScrollState(true));
  },
  [ChatEvents.ChatService.IsDelivered]: (payload: IsDeliveredApiResponse) => {
    store.dispatch(setMessageSendSuccess(payload.message));
  },

  /* TODO: is it needed ? */
  [ChatEvents.ChatService.ChatDeleted]: () => {
    chatUtils.bsSend({}, ChatEvents.ChatService.GetChats);
  },
  [ChatEvents.ChatService.ChatLeaved]: (payload: GroupLeaveApiResponse) => {
    chatUtils.bsSend({}, ChatEvents.ChatService.GetChats);
  },
};
