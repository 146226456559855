import {Labeled, TextField, useShowContext} from "react-admin";

export const DealMessage = () => {
  const dealStatus = useShowContext().record?.state?.message || {};

  const isEmpty = Object.keys(dealStatus).length === 0;

  return (
    <>
      {isEmpty ? null : (
        <div>
          <h3 style={{margin: 0, marginBottom: "6px"}}>Сообщение для оператора:</h3>
          <TextField source={`state.message`} />
        </div>
      )}
    </>
  );
};
