import {Box} from "@mui/material";
import {ChatCurrentUserHeader} from "./components/ChatCurrentUserHeader";

export const ChatHeader = () => {
  return (
    <Box sx={{display: "flex", minHeight: "50px"}}>
      <Box
        sx={{
          maxWidth: "420px",
          width: "100%",
          borderBottom: ({palette}) => `1px solid ${palette.black500.main}`,
        }}
      >
        {/* Search Input (service in progress) */}
      </Box>
      <ChatCurrentUserHeader />
    </Box>
  );
};
