import ProfileData from "./ProfileData";
import Loader from "shared/components/Loader/Loader";
import {useAdminAuth} from "shared/hooks/adminAuthContext/useAdminAuth";

const Profile = () => {
  const {user} = useAdminAuth();

  return !user ? <Loader /> : <ProfileData userData={user} />;
};

export default Profile;
