import {
  useRecordContext,
  List,
  Datagrid,
  FunctionField,
  DateField,
  TextField,
  SelectInput,
} from "react-admin";
import {MyBooleanField} from "shared/components/MyBooleanField/MyBooleanField";
import {MyUrlField} from "shared/components/MyUrlField/MyUrlField";
import {ROUTES} from "shared/constants/ROUTES";
import {getDataGridStyles} from "shared/styles/dataGridStyles";
import {BlocksForm} from "widgets/BlocksForm/BlocksForm";
import {CustomBooleanField} from "./CustomBooleanField";
import {MyFunctionalActionField} from "./MyFunctionalActionField";
import {format} from "date-fns";
import {PATTERN_DATE_TIME_FULL} from "shared/constants/dates";
import {textAlignLeft} from "shared/styles/textFieldAlignLeft";

const filters = [
  <SelectInput
    choices={[
      {id: "WARN", name: "Предупреждения"},
      {id: "BLOCK", name: "Блокировка"},
      {id: "UNBLOCK", name: "Разблокировка"},
    ]}
    source={"action"}
    label="Действие"
    alwaysOn
  />,

  <SelectInput
    alwaysOn
    choices={[
      {id: "USER", name: "Пользователь"},
      {id: "COMPANY", name: "Компания"},
    ]}
    label="Субъект"
    source="entity"
  />,
];

export const CompanyBlocks = () => {
  const record = useRecordContext();

  return (
    <>
      <List
        resource={`${ROUTES.USERS_AND_COMPANY_BLOCKS_LIST.path}?company_id=${record.id}`}
        hasCreate={false}
        filters={filters}
        filterDefaultValues={{companyId: record.id, entity: null}}
        sort={{field: "createdAt", order: "DESC"}}
        actions={false}
      >
        <Datagrid
          bulkActionButtons={false}
          sx={({palette}) => ({
            ...getDataGridStyles(palette),
          })}
        >
          <FunctionField
            source="entity"
            label="Субъект"
            render={(record) => (record.entity === "USER" ? "Пользователь" : "Компания")}
            textAlign="center"
          />

          <FunctionField
            label="Дата"
            source="createdAt"
            render={(record) =>
              record?.createdAt ? format(record.createdAt, PATTERN_DATE_TIME_FULL) : null
            }
            textAlign="center"
          />

          <MyUrlField
            source="user.fio"
            to={(record) => `/${ROUTES.USERS.path}?userId=${record?.user?.id}`}
            label="Нарушитель"
            sortable={false}
            isAlignLeft={true}
          />

          <MyUrlField
            source="createdBy.fioShort"
            to={(record) => `/${ROUTES.USERS.path}?userId=${record?.createdBy?.id}`}
            label="Арбитр"
            sortable={false}
            isAlignLeft={true}
          />

          <CustomBooleanField />

          <MyUrlField
            source="company.name"
            label="Компания"
            to={(record) => `/${ROUTES.COMPANY_LIST.path}/${record?.company?.id}/show`}
            isAlignLeft={true}
          />

          <MyBooleanField
            source="user.blockedInCompany"
            label={
              <>
                Блок <br /> компанией
              </>
            }
            sortable={false}
            textAlign="center"
          />

          <MyFunctionalActionField label="Действие" />
          <TextField source="reason" label="Причина" sortable={false} textAlign="left" />
          <TextField
            source="internalNotes"
            label={
              <>
                Внутренний <br /> комментарий
              </>
            }
            sortable={false}
            textAlign="left"
          />
        </Datagrid>
      </List>

      <BlocksForm entity="COMPANY" actions={["WARN", "BLOCK", "UNBLOCK"]} />
    </>
  );
};
