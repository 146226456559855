import axios from "axios";
import {API_URL} from "shared/constants/apiURL";
import {BsaRequestSchema} from "typesFromApi/types/userApi";

export const blockingOperatorRequest = async (body: BsaRequestSchema) => {
  const fd = Object.entries(body).reduce((formData, [key, value]) => {
    formData.append(key, `${value}`);
    return formData;
  }, new FormData());
  return (await axios.post(`${API_URL}/users/v2/r/bo`, fd))?.data;
};
