import {Box, Button, Stack, Typography} from "@mui/material";
import {useAdminAuth} from "shared/hooks/adminAuthContext/useAdminAuth";
import {Auth} from "shared/services/auth";

/**
 * Компонент для отображения сообщения о том, что у пользователя нет доступных ролей или прав для управления,
 * при условии, что у пользователя роль Администратор.
 *
 * Предоставляет возможность выхода из учетной записи.
 */
export const HasNoAvailableRoles = () => {
  const {setUser} = useAdminAuth();
  return (
    <Stack sx={{margin: "auto", pt: 10, maxWidth: "440px", gap: "10px"}}>
      <Typography sx={{}}>У вас нет доступных ролей либо прав для управления</Typography>

      <Box sx={{display: "flex", justifyContent: "center"}}>
        <Button
          sx={{}}
          onClick={async () => {
            await Auth.logout();
            setUser(null);
          }}
          variant="main"
        >
          Выйти
        </Button>
      </Box>
    </Stack>
  );
};
