import React from "react";
import {Typography} from "@mui/material";
import {NodeViewContent, NodeViewWrapper} from "@tiptap/react";

export const TypoWithLeftBorder = ({children}: {children: React.ReactNode}) => (
  <NodeViewWrapper className="react-component-with-content">
    <Typography
      variant="20px"
      component={NodeViewContent}
      sx={({palette}) => ({
        borderLeft: `2px solid ${palette.primary700.main}`,
        fontStyle: "italic",
        pl: 4,
        my: 6,
        fontWeight: 500,
      })}
    ></Typography>
  </NodeViewWrapper>
);
