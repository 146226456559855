import {
  Datagrid,
  List,
  TextField,
  TextInput,
  useListContext,
  DateField,
  minLength,
  CheckboxGroupInput,
  Filter,
  FunctionField,
  ListActions,
} from "react-admin";
import {getDataGridStyles} from "shared/styles/dataGridStyles";
import {CompanyInfoAdmin} from "typesFromApi/types/companyApi";
import Loader from "shared/components/Loader/Loader";
import {InputAdornment, Tooltip} from "@mui/material";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import {MyBooleanField} from "shared/components/MyBooleanField/MyBooleanField";
import {OPF_RECORD} from "shared/constants/OPFRecord";

const CustomFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput
        source="search"
        resettable
        label={"Поиск"}
        alwaysOn
        validate={minLength(3)}
        InputProps={{
          endAdornment: (
            <InputAdornment position={"end"}>
              <Tooltip
                title={
                  <>
                    Поиск осуществляется при длине больше 3ех символов.
                    <br />
                    По частичному совпадению: название компании, телефона, email.
                    <br />
                    По полному совпадению: ИНН, КПП, ОГРН, companyId.
                  </>
                }
              >
                <LiveHelpIcon sx={{cursor: "help"}} />
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />
      <CheckboxGroupInput
        source="hide"
        choices={[
          {
            id: "only",
            name: "Только",
          },
          {
            id: "supplier",
            name: "Поставщики",
          },
          {id: "customer", name: "Заказчики"},
          {
            id: "producer",
            name: "Производители",
          },
        ]}
        label=""
        alwaysOn={true}
        sx={{ml: 4, display: "flex", flexWrap: "wrap", my: 0}}
      />
    </Filter>
  );
};

export const CompaniesList = () => {
  return (
    <>
      <List
        sort={{field: "createdAt", order: "DESC"}}
        perPage={25}
        filterDefaultValues={{filter: {hide: ["all"]}}}
        filters={<CustomFilter />}
        title="Список компании"
        actions={<ListActions exporter={false} />}
      >
        <Fields />
      </List>
    </>
  );
};

function Fields() {
  const {isLoading = false} = useListContext<CompanyInfoAdmin>();

  if (isLoading) return <Loader />;
  return (
    <Datagrid
      bulkActionButtons={false}
      rowClick={"show"}
      sx={({palette}) => ({
        ...getDataGridStyles(palette),
      })}
    >
      <TextField source="name" label={"Компания"} textAlign="left" />
      <TextField source="inn" label={"ИНН"} textAlign="center" />
      <FunctionField
        source={"opf"}
        label={"ОПФ"}
        render={(record) =>
          OPF_RECORD[record.opf] ? OPF_RECORD[record.opf] : record.opf
        }
        textAlign="center"
      />
      <TextField source={"ogrn"} label={"ОГРН"} textAlign="center" />
      {/* <TextField source={"kpp"} label={"КПП"} /> */}
      <MyBooleanField source={"customer"} label={"Заказчик"} textAlign="center" />

      <MyBooleanField source={"supplier"} label={"Поставщик"} textAlign="center" />

      <MyBooleanField source={"producer"} label={"Производитель"} textAlign="center" />

      <MyBooleanField
        source={"isVerified"}
        label={
          <Tooltip title="Подтвержден">
            <DoneAllIcon />
          </Tooltip>
        }
        textAlign="center"
        sortable={false}
      />
      <TextField
        source={"verification_type"}
        label={
          <>
            Способ <br /> подтверждения
          </>
        }
        sortable={false}
        textAlign="center"
      />

      <MyBooleanField
        source={"isActive"}
        label={
          <>
            Блок <br />
            площадкой
          </>
        }
        sortable={false}
        textAlign="center"
      />
      <TextField source="phoneNumber" label={"Телефон"} textAlign="center" />
      <DateField source={"createdAt"} label={"Создана"} textAlign="center" />
      <DateField source={"updatedAt"} label={"Обновлена"} textAlign="center" />
    </Datagrid>
  );
}
