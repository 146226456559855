import {Stack} from "@mui/material";
import React from "react";
import {useAppSelector} from "shared/hooks/useAppSelector";
import {renderScrollStyle} from "shared/styles/scrollStyle";
import {usePreloadFileList} from "./hooks/usePreloadFileList";
import {BorderDay} from "../../../../../components";
import ChatFileItem from "../ChatFileItem";

export const FileListInChat = () => {
  usePreloadFileList();

  const {GetMedia} = useAppSelector((state) => state.chatSlice);

  return (
    <Stack
      sx={({palette}) => ({
        width: "100%",
        height: "100%",
        borderTop: `1px solid ${palette.black300.main}`,
      })}
    >
      <Stack
        sx={({palette}) => ({
          overflow: "auto",
          backgroundColor: palette.background.default,
          gap: 2,
          pl: 3,
          ...renderScrollStyle(palette),
        })}
      >
        {GetMedia.map((item, index) => {
          return (
            <React.Fragment key={item.id}>
              <BorderDay messages={GetMedia} index={index} />
              <ChatFileItem file={item.media[0]} key={item.id} />
            </React.Fragment>
          );
        })}
      </Stack>
    </Stack>
  );
};
