import {Box, Typography, Link} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";

type ShowAndDownloadBlockProps = {
  onShowFile: () => void;
  onDownloadFile: () => void;
  fileName: string;
};

export const ShowAndDownloadBlock = ({
  onShowFile,
  fileName,
  onDownloadFile,
}: ShowAndDownloadBlockProps) => {
  return (
    <Box display="flex" gap="20px" alignItems="center">
      <Link
        onClick={onShowFile}
        sx={{
          cursor: "pointer",
          textDecoration: "none",
          ":hover": {textDecoration: "underline"},
        }}
      >
        <Typography>{fileName}</Typography>
      </Link>
      <Link sx={{cursor: "pointer"}} onClick={onDownloadFile}>
        <DownloadIcon />
      </Link>
    </Box>
  );
};
