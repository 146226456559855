/**
 * @deprecated
 */
export class Is {
  static array = (data) => Array.isArray(data);
  static data = (data) => Array.isArray(data);
  static object = (data) =>
    !!(typeof data === "object" && !Array.isArray(data) && data !== null);
  static primitive = (data) =>
    !this.array(data) &&
    !this.object(data) &&
    typeof data !== "function" &&
    data !== null;
}
