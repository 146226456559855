import {DataProvider} from "react-admin";
import {defaultProvider} from "./defaultProvider";
import axios from "axios";
import {API_URL} from "shared/constants/apiURL";

export const oneTicketProvider = {
  ...defaultProvider(),
  getOne: async (resource = "", params) => {
    const data = await axios.get(`${API_URL}/${resource}/${params.id}`);
    return {data: {...data.data?.ticket, methods: data.data?.methods}};
  },
} as DataProvider;
