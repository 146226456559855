import axios from "axios";
import {useState, useEffect} from "react";
import {API_URL} from "shared/constants/apiURL";
import {FinV2AdminListTariffConditionsGetApiResponse} from "typesFromApi/types/finApi";

export const useGetConditions = () => {
  const [conditions, setConditions] =
    useState<FinV2AdminListTariffConditionsGetApiResponse | null>(null);

  useEffect(() => {
    axios
      .get(`${API_URL}/fin/v2/admin/list-tariff-conditions`)
      .then((res) => setConditions(res.data));
  }, []);

  return {data: conditions};
};
