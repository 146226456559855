import {Box, IconButton, Stack} from "@mui/material";
import React from "react";
import {
  Datagrid,
  DateField,
  EditButton,
  FunctionField,
  Labeled,
  ListBase,
  NumberField,
  RichTextField,
  Show,
  TabbedShowLayout,
  TextField,
  useGetList,
  useRecordContext,
  WrapperField,
} from "react-admin";
import {MyBooleanField} from "shared/components/MyBooleanField/MyBooleanField";
import {MyUrlField} from "shared/components/MyUrlField/MyUrlField";
import {ROUTES} from "shared/constants/ROUTES";
import {getDataGridStyles} from "shared/styles/dataGridStyles";
import {Tariff} from "typesFromApi/types/finApi";
import EditIcon from "@mui/icons-material/Edit";
import {Link} from "react-router-dom";
import {getDurationFromDMY} from "shared/utils/getDurationFromDMY";

export const GroupTariffsShow = () => {
  return (
    <Show>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="Информация о группе тарифов">
          <Labeled label="Id">
            <TextField source="id" />
          </Labeled>

          <Labeled label="Название">
            <TextField source="title" />
          </Labeled>

          <Labeled label="Внутренний код">
            <TextField source="codename" />
          </Labeled>

          <Labeled label="Описание">
            <TextField source="description" />
          </Labeled>

          <Labeled label="Опубликована">
            <MyBooleanField
              source="publish"
              sx={{display: "flex", justifyContent: "center"}}
            />
          </Labeled>

          <Labeled label="Порядок сортировки">
            <TextField source="sorter" />
          </Labeled>

          <Stack direction="row" spacing={3}>
            <Labeled label="Создана">
              <DateField source="createdAt" />
            </Labeled>
            <Labeled label="Обновлена">
              <DateField source="updatedAt" />
            </Labeled>
          </Stack>

          <Labeled label="Кто создал">
            <MyUrlField
              source={"createdById"}
              label={"Создатель запроса"}
              to={(record) => `/${ROUTES.USERS.path}?userId=${record?.createdById}`}
            />
          </Labeled>

          <Labeled label="Кем обновлена">
            <MyUrlField
              source={"updatedById"}
              label={"Создатель запроса"}
              to={(record) => `/${ROUTES.USERS.path}?userId=${record?.updatedById}`}
            />
          </Labeled>

          <Labeled label="Внутренний комментарий">
            <RichTextField source="internalComment" />
          </Labeled>
        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab label="Включаемые тарифы">
          <ShowTariffsInGroup />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  );
};

function ShowTariffsInGroup() {
  const {data, isError, isLoading} = useGetList<Tariff>(ROUTES.TARIFFS.path);
  const record = useRecordContext();

  const neededTariffs = React.useMemo(() => {
    return (data || []).filter((tariff) => tariff?.tgroupId === record?.id);
  }, [data, record]);

  if (isLoading) {
    return <>Идет загрузка...</>;
  }

  if (isError) {
    return <>Произошла ошибка...</>;
  }

  return (
    <ListBase>
      <Datagrid
        bulkActionButtons={false}
        sx={({palette}) => ({
          ...getDataGridStyles(palette),
        })}
        data={neededTariffs || []}
        total={neededTariffs.length}
        empty={<Box>Нет тарифов</Box>}
      >
        <TextField source="codename" label={"Код"} sortable={false} />
        <TextField source="name" label={"Название"} sortable={false} />
        <TextField source="priceWithNds" label={"Цена с НДС"} sortable={false} />
        <TextField source="priceNoNds" label={"Цена без НДС"} sortable={false} />
        <TextField source="ndsPercent" label={"Процент НДС"} sortable={false} />
        <TextField source="ndsAmount" label={"Сумма НДС"} sortable={false} />
        <TextField source="s1Sku" label={"1С Артикул"} sortable={false} />
        <TextField source="s1Name" label={"1С Название"} sortable={false} />

        <FunctionField
          source="month"
          label={
            <>
              Кол-во <br />
              дней
            </>
          }
          render={getDurationFromDMY}
          sortable={false}
        />

        <NumberField
          source="sorter"
          label={
            <>
              Порядковый <br /> номер
            </>
          }
          sortable={false}
        />
        <MyBooleanField source="publish" sortable={false} label={"Опубликована"} />
        <TextField
          source="internalComment"
          label={
            <>
              Внутренний <br /> комментарий
            </>
          }
          sortable={false}
        />

        <FunctionField
          render={(record) => (
            <IconButton component={Link} to={`/${ROUTES.TARIFFS.path}/${record.id}`}>
              <EditIcon />
            </IconButton>
          )}
        />
      </Datagrid>
    </ListBase>
  );
}
