import React from "react";
import {Typography} from "@mui/material";

export const SectionTitle = ({children}: {children: React.ReactNode}) => {
  return (
    <Typography sx={({palette}) => ({fontSize: "16px", fontWeight: 700})}>
      {children}
    </Typography>
  );
};
