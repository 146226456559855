import {TextFieldProps, TextField} from "@mui/material";
import {FieldValues, UseControllerProps, useController} from "react-hook-form";

type TProps<T extends FieldValues> = {
  controlProps: UseControllerProps<T>;
} & TextFieldProps;

/**
 * Контролируемый ввод для форм
 * @param controlProps - сюда передать объектом управление в виде controlProps={{control: methods.control, name: "firstName"}}, где name вернется с подсказкой после задания control
 * @param textFieldProps - дополнительно принимает параметры для TextField (sx, label и прочие)
 * @returns
 */
export function UI_ControlledTextField<T extends FieldValues>({
  controlProps,
  ...textFieldProps
}: TProps<T>) {
  const {
    field,
    fieldState: {error},
  } = useController(controlProps);
  return (
    <TextField
      {...field}
      {...textFieldProps}
      value={field.value || ""}
      onChange={field.onChange}
      error={!!error}
      helperText={error && error.message}
      onBlur={() => field.onChange((`${field.value}` || "").trim())}
    />
  );
}
