import {format} from "date-fns";
import {TypoAsValue} from "../../components/TypoAsValue/TypoAsValue";
import {PATTERN_DATE} from "shared/constants/dates";
import {timeLeft} from "shared/utils/timeLeft";

interface TimeProps {
  timeObj: null | {time: string; name: string};
}
const DATE_END_APP = "Подача заявок до";

const Time = ({timeObj}: TimeProps) => {
  return (
    <>
      {timeObj && timeObj?.time ? (
        <TypoAsValue>
          {format(new Date(timeObj.time), PATTERN_DATE)}
          {timeObj.name === DATE_END_APP ? ` (${timeLeft(timeObj.time)})` : null}
        </TypoAsValue>
      ) : null}
    </>
  );
};

export default Time;
