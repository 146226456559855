import {Tooltip, IconButton} from "@mui/material";
import {
  ClearButtons,
  ColorButtons,
  FormatButtons,
  ImageButtons,
  LevelSelect,
  LinkButtons,
  ListButtons,
  QuoteButtons,
  RichTextInputToolbar,
  RichTextInputToolbarProps,
  useTiptapEditor,
} from "ra-input-rich-text";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import PlaylistRemoveOutlinedIcon from "@mui/icons-material/PlaylistRemoveOutlined";

export const MyRichTextInputToolbar = (props: RichTextInputToolbarProps) => {
  const editor = useTiptapEditor();

  if (!editor) return;
  return (
    <RichTextInputToolbar {...props}>
      <LevelSelect size={"medium"} />
      <FormatButtons size={"medium"} />
      <ColorButtons size={"medium"} />
      <ListButtons size={"medium"} />
      <LinkButtons size={"medium"} />
      <ImageButtons size={"medium"} />
      <QuoteButtons size={"medium"} />
      <ClearButtons size={"medium"} />

      <Tooltip title="Вложенный список">
        <IconButton
          onClick={() => editor.chain().focus().sinkListItem("listItem").run()}
          sx={{
            border: "1px solid rgba(0, 0, 0, 0.12)",
            borderRadius: 1,
            minWidth: 38,
            minHeight: 38,
            maxWidth: 38,
            maxHeight: 38,
            width: "100%",
          }}
        >
          <ListAltOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Поднять список">
        <IconButton
          onClick={() => editor.chain().focus().liftListItem("listItem").run()}
          sx={{
            border: "1px solid rgba(0, 0, 0, 0.12)",
            borderRadius: 1,
            minWidth: 38,
            minHeight: 38,
            maxWidth: 38,
            maxHeight: 38,
            width: "100%",
          }}
        >
          <PlaylistRemoveOutlinedIcon />
        </IconButton>
      </Tooltip>
    </RichTextInputToolbar>
  );
};
