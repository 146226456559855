import {PayloadAction, createSlice} from "@reduxjs/toolkit";
import {generateActionsForSync} from "shared/utils/generateActionsForSync";
import {BcService} from "shared/services/BroadcastChannelService";
import {initialStateChat} from "./initialState_draft";
import {
  TChat,
  TChatMenuType,
  TChatMessage,
  TChatWithUserType,
  TInitialStateChat,
} from "./types";
import {
  ClosedApiResponse,
  DirectCreateSearchApiResponse,
  GetArchivedChatsApiResponse,
  GetBlacklistChatsApiResponse,
  GetChatApiResponse,
  GetChatsApiResponse,
  GetMediaApiResponse,
  GetMessagesApiResponse,
  GetUnreadMessagesCntApiResponse,
  GroupCreateSearchApiResponse,
  IsReadApiResponse,
  MeetingCreateApiResponse,
  MeetingCreateSearchApiResponse,
  MessageSendApiResponse,
  OpenedApiResponse,
  TypingApiResponse,
} from "typesFromApi/types/chatApi";
import {ChatEvents} from "shared/utils/chatUtils/ChatEvents";
import {findChatIndex, updateChatList, addChatToList} from "shared/utils/chatUtils/utils";

const initEvents = [
  ChatEvents.ChatService.GetUnreadMessagesCnt,
  ChatEvents.ChatService.GetChats,
  ChatEvents.ChatService.GetArchivedChats,
  ChatEvents.ChatService.GetBlackListChats,
];

export const chatSlice = createSlice({
  name: "chatSlice",
  initialState: initialStateChat,
  reducers: {
    // ...resetState,
    clearChatsState: () => initialStateChat,
    setChatsIsInit: (state, {payload}: PayloadAction<boolean>) => {
      state.isInit = payload;
    },
    chatsInit: (state) => {
      state.isInit = true;

      for (const event of initEvents) {
        state.loaders[event] = true;
        BcService.handleBcSendGlobalMessage({
          request: {
            message: {},
            event: ChatEvents.ChatServiceEvent + event,
          },
        });
      }
    },
    assignChatsState: (state, {payload}) => {
      Object.assign(state, payload);
    },

    setUnreadMessagesCnt: (
      state,
      {payload}: PayloadAction<GetUnreadMessagesCntApiResponse["message"]>,
    ) => {
      state.GetUnreadMessagesCnt = payload.unreadMessagesCnt;
    },
    setChats: (state, {payload}: PayloadAction<GetChatsApiResponse["message"]>) => {
      state.GetChats = {
        chats: [...state.GetChats.chats, ...payload.chats],
        users: [...state.GetChats.users, ...payload.users],
        totalCount: payload.totalCount,
      };
    },

    // собеседник печатает
    setTypingInChat: (state, {payload}: PayloadAction<TypingApiResponse["message"]>) => {
      const newChatsArr = state.GetChats.chats.map((chat) =>
        chat.id === payload.chatId
          ? {...chat, typingUsers: {userId: payload.userId, startTime: Date.now()}}
          : chat,
      );
      state.GetChats = {...state.GetChats, chats: newChatsArr};
    },
    // событие пользователь в сети
    setUserIsOnline: (
      state,
      {
        payload,
      }: PayloadAction<{
        user: OpenedApiResponse["message"] | ClosedApiResponse["message"];
        online: boolean;
      }>,
    ) => {
      const newUsersArr = state.GetChats.users.map((user) =>
        user.id === payload.user.userId
          ? {...user, online: {...user.online, isOnline: payload.online}}
          : user,
      );
      state.GetChats = {...state.GetChats, users: newUsersArr};
    },
    setArchivedChats: (
      state,
      {payload}: PayloadAction<GetArchivedChatsApiResponse["message"]>,
    ) => {
      state.GetArchivedChats = {
        chats: [...state.GetArchivedChats.chats, ...payload.chats],
        users: [...state.GetArchivedChats.users, ...payload.users],
        totalCount: payload.totalCount,
      };
    },

    setBlackListChats: (
      state,
      {payload}: PayloadAction<GetBlacklistChatsApiResponse["message"]>,
    ) => {
      state.GetBlackListChats = {
        chats: [...state.GetBlackListChats.chats, ...payload.chats],
        users: [...state.GetBlackListChats.users, ...payload.users],
        totalCount: payload.totalCount,
      };
    },

    setChatMessages: (
      state,
      {payload}: PayloadAction<GetMessagesApiResponse["message"]>,
    ) => {
      const chatId = payload[0].chatId as TChat["id"];

      state.GetMessages = {
        ...state.GetMessages,
        [chatId]: [...payload, ...(state.GetMessages[chatId] || [])],
      };

      state.loaders[ChatEvents.ChatService.GetMessages + chatId] = false;
    },

    resetChatMessages: (state, {payload}: PayloadAction<number>) => {
      state.GetMessages[payload] = [];
      state.GetMessagesScroll = true;
    },

    // метод запрещающий прокручивание при нескольких непрочитанных сообщениях
    setChatScrollState: (state, {payload}: PayloadAction<boolean>) => {
      state.GetMessagesScroll = payload;
    },
    setChatMedia: (state, {payload}: PayloadAction<GetMediaApiResponse["message"]>) => {
      state.GetMedia = payload;
    },
    // получение нового сообщения в чате
    setNewMessageInChat: (
      state,
      {payload}: PayloadAction<Partial<MessageSendApiResponse["message"]>>,
    ) => {
      // обновление списка сообщений когда чат открыт и скролл к сообщению
      if (
        state.GetMessages[payload.chatId]?.length &&
        state.currentChat?.id === payload.chatId
      ) {
        state.GetMessages[payload.chatId].push(payload as TChatMessage);
        state.GetMessagesScroll = true;
      }

      BcService.handleBcSendGlobalMessage({
        request: {
          message: {chatId: payload.chatId},
          event: ChatEvents.ChatServiceEvent + ChatEvents.ChatService.GetChat,
        },
      });
    },
    // событие успешной доставки сообщения на сервер
    setMessageSendSuccess: (
      state,
      {payload}: PayloadAction<MessageSendApiResponse["message"]>,
    ) => {
      // обновление статуса сообщения в обозначении чата
      let chat = state.GetChats.chats.find(({id}) => id === payload.chatId);
      if (
        chat &&
        (chat.lastMessage.id === payload.id || chat.lastMessage.id === payload.messageId)
      ) {
        chat = {...chat, lastMessage: {...chat.lastMessage, isDelivered: true}};
        state.GetChats = {
          ...state.GetChats,
          chats: state.GetChats.chats.map((item) =>
            item.id === chat?.id ? {...chat} : item,
          ),
        };
        state.GetMessagesScroll = true;
      }
      // обновление статуса сообщения в переписке
      if (!state.GetMessages[payload.chatId]) return;
      const messageIndex = state.GetMessages[payload.chatId].findLastIndex(
        ({id}) => id === payload.id || id === payload.messageId,
      );
      const changedMessage = {
        ...state.GetMessages[payload.chatId][messageIndex],
        isDelivered: true,
      };
      state.GetMessages[payload.chatId].splice(messageIndex, 1, changedMessage);

      BcService.handleBcSendGlobalMessage({
        request: {
          message: {chatId: payload.chatId},
          event: ChatEvents.ChatServiceEvent + ChatEvents.ChatService.GetChat,
        },
      });
    },
    // событие прочтения сообщения собеседником или собой
    setMessageIsRead: (state, {payload}: PayloadAction<IsReadApiResponse["message"]>) => {
      // обновление статуса сообщения в обозначении чата
      let chat = state.GetChats.chats.find(({id}) => id === payload.chatId);

      // обновление своего отправленного сообщения
      if (chat && chat.lastMessage.id === payload.messageId) {
        chat = {
          ...chat,
          lastMessage: {...chat.lastMessage, isRead: true, isDelivered: true},
        };
      }
      // обновление количества непрочитанных сообщений в списке чатов
      if (chat && chat.unreadMessagesCnt > 0) {
        chat = {...chat, unreadMessagesCnt: chat.unreadMessagesCnt - 1};
      }
      state.GetChats = {
        ...state.GetChats,
        chats: state.GetChats.chats.map((item) =>
          item.id === chat?.id ? {...chat} : item,
        ),
      };
      // обновление статуса сообщения в переписке
      if (!state.GetMessages[payload.chatId]) return;
      const messageIndex = state.GetMessages[payload.chatId].findLastIndex(
        ({id}) => id === payload.messageId,
      );

      const changedMessage = {
        ...state.GetMessages[payload.chatId][messageIndex],
        isRead: true,
      };

      state.GetMessages[payload.chatId].splice(messageIndex, 1, changedMessage);
      state.GetUnreadMessagesCnt = state.GetUnreadMessagesCnt - 1;
    },

    setCurrentChat: (state, {payload}: PayloadAction<TChat | null>) => {
      state.currentChat = payload;
    },

    setCurrentChatMenu: (state, {payload}: PayloadAction<TChatWithUserType>) => {
      state.currentChatMenu = payload;
    },
    setCurrentMainMenu: (state, {payload}: PayloadAction<TChatMenuType>) => {
      state.currentMainMenu = payload;
    },

    setMuteChat: (state, {payload}: PayloadAction<boolean>) => {
      state.isMuteChat = payload;
    },

    setChatLoaders: (
      state,
      {payload}: PayloadAction<Partial<TInitialStateChat["loaders"]>>,
    ) => {
      state.loaders = {...state.loaders, ...payload};
    },

    setDirectSearchUsers: (
      state,
      {payload}: PayloadAction<Partial<DirectCreateSearchApiResponse["message"]>>,
    ) => {
      state.DirectSearch = payload.users || [];
    },
    setGroupSearchUsers: (
      state,
      {payload}: PayloadAction<Partial<GroupCreateSearchApiResponse["message"]>>,
    ) => {
      state.GroupSearch = payload.users || [];
    },
    setMeetingSearchUsers: (
      state,
      {payload}: PayloadAction<MeetingCreateSearchApiResponse["message"]>,
    ) => {
      state.MeetingSearch = payload || [];
    },
    // для хука открытия чата из любого места на площадке
    setLastCreatedChat: (
      state,
      {payload}: PayloadAction<MeetingCreateApiResponse["message"] | undefined>,
    ) => {
      state.lastCreatedChat = payload?.chat;
    },
    /**
     *  для загрузки предыдущих сообщений и запрета скролла до предыдущих
     */
    updateLastMessageInChat: (
      state,
      {payload}: PayloadAction<{chatId: TChat["id"]; isNeeded: true} | {isNeeded: false}>,
    ) => {
      if (payload.isNeeded && payload.chatId) {
        state.lastRededMessage = state.GetMessages[payload.chatId][0]?.id;
      } else {
        state.lastRededMessage = undefined;
      }
    },

    resetGetChats: (state) => {
      state.GetChats = initialStateChat.GetChats;
    },

    resetArchivedChats: (state) => {
      state.GetArchivedChats = initialStateChat.GetArchivedChats;
    },
    resetBlackListChats: (state) => {
      state.GetBlackListChats = initialStateChat.GetBlackListChats;
    },

    setNewChatInChats: (
      state,
      {payload}: PayloadAction<GetChatApiResponse["message"]>,
    ) => {
      const newChat = payload.chat;

      const allChatIndex = findChatIndex(state.GetChats.chats, newChat.id);
      const archivedChatIndex = findChatIndex(state.GetArchivedChats.chats, newChat.id);
      const blackListChatIndex = findChatIndex(state.GetBlackListChats.chats, newChat.id);

      if (allChatIndex !== -1 || archivedChatIndex !== -1 || blackListChatIndex !== -1) {
        state.GetChats.chats = updateChatList(
          state.GetChats.chats,
          newChat,
          allChatIndex,
        );
        state.GetArchivedChats.chats = updateChatList(
          state.GetArchivedChats.chats,
          newChat,
          archivedChatIndex,
        );
        state.GetBlackListChats.chats = updateChatList(
          state.GetBlackListChats.chats,
          newChat,
          blackListChatIndex,
        );
      } else {
        const isInArchive = newChat.inArchive;
        const isInBlackList = newChat.userInBlackList;

        if (isInArchive) {
          state.GetArchivedChats.chats = addChatToList(
            state.GetArchivedChats.chats,
            newChat,
          );
        } else if (isInBlackList) {
          state.GetBlackListChats.chats = addChatToList(
            state.GetBlackListChats.chats,
            newChat,
          );
        } else {
          state.GetChats.chats = addChatToList(state.GetChats.chats, newChat);
        }
      }
    },
  },
});

export const {
  clearChatsState,
  setChatsIsInit,
  chatsInit,
  assignChatsState,
  setUnreadMessagesCnt,
  setChats,
  setTypingInChat,
  setUserIsOnline,
  setArchivedChats,
  setBlackListChats,
  setChatMessages,
  resetChatMessages,
  setChatScrollState,
  setChatMedia,
  setCurrentChat,
  setCurrentChatMenu,
  setCurrentMainMenu,
  setMuteChat,
  setChatLoaders,
  setNewMessageInChat,
  setMessageSendSuccess,
  setMessageIsRead,
  setDirectSearchUsers,
  setGroupSearchUsers,
  setMeetingSearchUsers,
  setLastCreatedChat,
  updateLastMessageInChat,
  resetGetChats,
  resetArchivedChats,
  resetBlackListChats,
  setNewChatInChats,
} = chatSlice.actions;

type ChatActionsKeys = keyof typeof chatSlice.actions;

const chatBlackList: ChatActionsKeys[] = [
  "setChatsIsInit",
  "chatsInit",
  "setCurrentChat",
  "setChatScrollState",
  "setChatMedia",
  "setNewMessageInChat",
  "setMessageSendSuccess",
  "setMessageIsRead",
  "setCurrentChatMenu",
  "setCurrentMainMenu",
  "setChatLoaders",
  "setDirectSearchUsers",
  "setGroupSearchUsers",
  "setMeetingSearchUsers",
  "setLastCreatedChat",
  "updateLastMessageInChat",
];

export const chatSliceActions = generateActionsForSync(chatSlice, {
  blackList: chatBlackList,
});

export default chatSlice.reducer;
