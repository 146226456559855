import axios from "axios";
import {API_URL} from "shared/constants/apiURL";
import {BcService} from "shared/services/BroadcastChannelService";

export async function _getToken() {
  return (await axios.get(`${API_URL}/auth/v2/one-time-token`))?.data;
}

export function _handleErrorCode(code: number) {
  switch (code) {
    case 1006: // WebSocket connection to 'wss://api-stage.ab-trade.ru/ws/v2/066614fa-fe84-71c7-f1c7-d08509aceac8' failed: (e.reason пустой)
      // for example : switching vpn and getting code 1006
      // https://kapeli.com/cheat_sheets/WebSocket_Status_Codes.docset/Contents/Resources/Documents/index --> CLOSE_ABNORMAL
      console.error("connection by WebSocket is failed");
      break;
    case 3409: // User already connected
      BcService.handleBcSendGlobalMessage({alreadyConnected: true});
      break;
    /* -------------USED----------------- */
    case 3423: // Session Expired
      BcService.handleBcSendGlobalMessage({expired: true});
      break;
    case 3424: // User manually logged out
      BcService.handleBcSendGlobalMessage({manuallyLoggedOut: true});
      break;
    /* -------------------------------------- */

    case 3425: // User just logged in from some other place
      BcService.handleBcSendGlobalMessage({loggedOtherPlace: true});
      break;
    case 3426: // User is blocked at company level
      BcService.handleBcSendGlobalMessage({blockedCompanyLevel: true});
      break;
    case 3427: // User is blocked at system level
      BcService.handleBcSendGlobalMessage({blockedSystemLevel: true});
      break;
    case 3428: // Company of user is blocked at system level
      BcService.handleBcSendGlobalMessage({CompanyBlocked: true});
      break;
    case 3503: // Application shutdown ("умер" один из сервисов, что привело к закрытию соединения по ws)
      console.error("Application shutdown");
      break;
  }
}
