import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {setTabsCount, setIsMainTabApp} from "shared/ReduxSlice/bcSlice/bcSlice";

export const useWebWorker = () => {
  const [isMainTab, setIsMainTab] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const worker = new SharedWorker(new URL("./counter.ts", import.meta.url));
    worker.port.postMessage({type: "connect"});

    worker.port.onmessage = ({data}) => {
      const {tabsCount, tabNumber} = data;

      dispatch(setTabsCount(tabsCount));

      const isMain = tabNumber === 1;
      setIsMainTab(isMain);
      dispatch(setIsMainTabApp(isMain));
    };

    const unloadHandler = () => {
      worker.port.postMessage({type: "disconnect"});
      worker.port.close();
    };

    window.addEventListener("beforeunload", unloadHandler);

    return () => {
      unloadHandler();
      window.removeEventListener("beforeunload", unloadHandler);
    };
  }, [dispatch]);

  return {isMainTab};
};
