import axios from "axios";
import {useState} from "react";

/**
 *  хук позволяет скачивать файлы с сервера
 *  с токеном доступа
 *  возвращает функцию download
 *  и состояние загрузки
 */
export function useDownloadFileWithToken() {
  const isLoadingState = useState(null);

  async function download(url = "", fileName = "fileName", id?, clickLink = true) {
    try {
      isLoadingState[1](id);
      const res = await axios.get(url, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
        },
        responseType: "blob",
      });
      const blob = URL.createObjectURL(res.data);
      const link = document.createElement("a");
      link.setAttribute("download", fileName);
      link.href = blob;
      clickLink && link.click();
      return blob;
    } catch (e) {
      console.log("ошибка загрузки файла", e);
    } finally {
      isLoadingState[1](null);
    }
  }

  return {
    download,
    isLoading: isLoadingState[0],
  };
}
