import {Components, Theme} from "@mui/material/styles";

export const muiTab: Components<Theme>["MuiTab"] = {
  styleOverrides: {
    root: ({theme}) => ({
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "18px",
      textTransform: "none",
      color: theme.palette.text.primary,
      padding: "0px 0px 5px",
      minHeight: "30px",

      "&.Mui-disabled": {
        color: theme.palette.text.disabled,
      },
    }),
  },
};
