import {AppDispatch, store} from "shared/store/store";
import {BcService} from "shared/services/BroadcastChannelService";
import {ChatEvents} from "./ChatEvents";
import {
  TChatMenuType,
  TChatUser,
  TInitialStateChat,
} from "shared/ReduxSlice/chatSlice/types";
import {chatEventsHandler} from "./chatEventsHandler";
import {setChatLoaders} from "shared/ReduxSlice/chatSlice/chatSlice";
import newMsgMP3 from "shared/static/sound/newMessage.mp3";

let isPlay = false;

type ChatUtilsType = {
  store: () => TInitialStateChat;
  dispatch: AppDispatch;
  _isChatEvent: (event: MessageEvent) => boolean;
  _chatMessagesListener: (event: MessageEvent) => void;
  chatMessagesStartListener: () => void;
  bsSend: (data: unknown, event: keyof typeof ChatEvents.ChatService) => void;
  getChatUserById: (params: {
    userID: string;
    type?: TChatMenuType;
  }) => TChatUser | undefined;
  getMsgPosition: ({messageId}: {messageId?: number}) => number | null | undefined;
  playNewMessageJingle: () => void;
};

export const chatUtils: ChatUtilsType = {
  store: () => store.getState().chatSlice,
  dispatch: store.dispatch,
  // для определения что получаемое сообщение относится к уведомлениям

  _isChatEvent: (event: MessageEvent) => {
    return event?.data?.response?.event?.indexOf(ChatEvents.ChatServiceEvent) === 0;
  },
  // callback для установки слушателя, которая предаёт сообщение в store

  _chatMessagesListener: (event: MessageEvent) => {
    if (chatUtils._isChatEvent(event)) {
      const chatEvent: string = event.data.response.event?.split(".");
      if (event.data.response.statusCode !== 200) {
        chatUtils.dispatch(setChatLoaders({[chatEvent[1]]: null}));
      } else {
        chatUtils.dispatch(setChatLoaders({[chatEvent[1]]: false}));
      }
      if (chatEventsHandler[chatEvent[1]]) {
        chatEventsHandler[chatEvent[1]](event.data.response);
        return;
      }
    }
  },
  /**
   * установка слушателя, для передачи сообщений в store
   */
  chatMessagesStartListener: () => {
    BcService.messageReceiver.addEventListener(
      "message",
      chatUtils._chatMessagesListener,
    );
  },

  /**
   * Отправка сообщений через Broadcast для отправки по ws
   */
  bsSend: function (data = {}, event: keyof typeof ChatEvents.ChatService | "") {
    chatUtils.dispatch(setChatLoaders({[event]: true}));
    BcService.handleBcSendGlobalMessage({
      request: {message: data, event: ChatEvents.ChatServiceEvent + event},
    });
  },

  getChatUserById: ({
    userID,
    type = chatUtils.store().currentMainMenu,
  }: {
    userID: string;
    type?: TChatMenuType;
  }) => {
    let usersArray: TChatUser[] = [];
    if (type === "COMMON") {
      usersArray = chatUtils.store().GetChats.users;
    } else if (type === "ARCHIVE") {
      usersArray = chatUtils.store().GetArchivedChats.users;
    } else {
      usersArray = chatUtils.store().GetBlackListChats.users;
    }
    try {
      return usersArray.find((user) => user.id === userID);
    } catch (e) {
      console.warn("не удалось получить чат. ошибка:");
      console.warn(e);
    }
  },

  /**
   * утилита для поиска сообщения, возвращающий его позицию в чате
   * @param param0
   * @returns
   */
  getMsgPosition: ({messageId = 0}: {messageId?: number}) => {
    try {
      const messages = chatUtils.store().GetMessages;
      const currentChat = chatUtils.store().currentChat;
      const myId = chatUtils.store().userId;
      let msgID: number | undefined;

      const neededMessages = messages[currentChat?.id || ""] || [];

      if (!messageId) {
        msgID = neededMessages.find(({isRead, userId}) => !isRead && myId !== userId)?.id;
      } else {
        msgID = neededMessages.find(({id}) => messageId === id)?.id;
      }
      const msg = document.getElementById("chat_msg" + msgID);
      return msg ? msg.offsetTop : null;
    } catch (e) {
      console.warn("Не удалось получить позицию сообщения. ошибка:");
      console.warn(e);
    }
  },

  playNewMessageJingle: () => {
    if (!chatUtils.store().isMuteChat) {
      const audio = new Audio(newMsgMP3);
      !isPlay && audio.play().catch((e) => console.error(e));
      isPlay = true;
      audio.addEventListener("play", () => (isPlay = true));
      audio.addEventListener("ended", () => (isPlay = false));
    }
  },
};
