import {
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import React, {useEffect} from "react";
import {useGetConditions} from "../hooks/useGetConditions";
import {useFormContext} from "react-hook-form";

export const SelectOfConditions = ({conditions = []}: {conditions?: string[]}) => {
  const {data} = useGetConditions();

  const {setValue, getValues} = useFormContext();
  const isPromo = getValues("isPromo");

  const [chosenConditions, setChosenConditions] = React.useState<string[]>(
    conditions || [],
  );

  const handleChange = (event: SelectChangeEvent<typeof chosenConditions>) => {
    const {
      target: {value},
    } = event;
    const valueAsArray = typeof value === "string" ? value.split(",") : value;
    setChosenConditions(valueAsArray);
    setValue("conditions", valueAsArray, {shouldDirty: true});
  };

  useEffect(() => {
    if (isPromo) {
      setValue("conditions", []);
    }
  }, [isPromo, setValue]);

  useEffect(() => {
    const conditionsAsValue = getValues("conditions");

    if (!conditionsAsValue || !conditionsAsValue.length) return;

    const allAvailableConditions = Object.keys(data || {});
    const newConditionsAsValue = conditionsAsValue.filter(
      (condition) => !allAvailableConditions.includes(condition),
    );

    setValue("conditions", newConditionsAsValue);
  }, []);

  return (
    <FormControl sx={{mb: 5}}>
      <InputLabel id="availableConditions-label">
        {isPromo ? "У промо тарифа нет условий" : "Условия"}
      </InputLabel>
      <Select
        labelId="availableConditions-label"
        id="availableConditions"
        multiple
        value={isPromo ? [] : chosenConditions}
        onChange={handleChange}
        input={<OutlinedInput label="Условия" />}
        disabled={isPromo}
      >
        {Object.entries(data || {})?.map(([key, value]) => {
          return (
            <MenuItem key={key} value={key}>
              {value}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
