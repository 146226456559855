import {format} from "date-fns";
import React from "react";
import {
  List,
  Datagrid,
  FunctionField,
  TextField,
  minLength,
  SearchInput,
  WrapperField,
} from "react-admin";
import {MyBooleanField} from "shared/components/MyBooleanField/MyBooleanField";
import {MyUrlField} from "shared/components/MyUrlField/MyUrlField";
import {PATTERN_DATE_TIME_FULL, PATTERN_DATE_SHORT} from "shared/constants/dates";
import {ROUTES} from "shared/constants/ROUTES";
import {getDataGridStyles} from "shared/styles/dataGridStyles";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import {InputAdornment, Tooltip} from "@mui/material";

const filters = [
  <SearchInput
    source="search"
    alwaysOn
    validate={minLength(3)}
    InputProps={{
      endAdornment: (
        <InputAdornment position={"end"}>
          <Tooltip
            title={
              <>
                Поиск осуществляется при длине больше 3ех символов.
                <br />
                Поиск по названию компании, инн компании, hid промо, фио оператора.
              </>
            }
          >
            <LiveHelpIcon sx={{cursor: "help"}} />
          </Tooltip>
        </InputAdornment>
      ),
    }}
  />,
];

export const AssetsList = ({
  companyId,
  isCompanyList = false,
}: {
  companyId?: string;
  isCompanyList?: boolean;
}) => {
  const params = React.useMemo(() => {
    const filters = {companyId};
    const filteredFilters = Object.fromEntries(
      Object.entries(filters).filter(([key, value]) => !!value),
    );
    let resource = ROUTES.ASSETS.path;

    if (Object.keys(filteredFilters).length) {
      const queryParams = Object.entries(filteredFilters)
        .map(([key, value]) => `${key}=${value}`)
        .join("&");
      resource += `?${queryParams}`;
    }
    return {filters: filteredFilters, resource};
  }, [companyId]);

  return (
    <List
      actions={false}
      sort={{field: "createdAt", order: "DESC"}}
      resource={params.resource}
      filterDefaultValues={params.filters}
      filters={filters}
    >
      <Fields isCompanyList={isCompanyList} />
    </List>
  );
};

function Fields({isCompanyList}: {isCompanyList: boolean}) {
  return (
    <Datagrid
      bulkActionButtons={false}
      sx={({palette}) => ({
        ...getDataGridStyles(palette),
      })}
    >
      <TextField source={"hid"} label={"HId"} />

      <WrapperField label="Дата создания" sortable sortBy="createdAt">
        <FunctionField
          label={
            <>
              Дата <br /> создания
            </>
          }
          source="createdAt"
          render={(record) =>
            record?.createdAt ? format(record.createdAt, PATTERN_DATE_TIME_FULL) : null
          }
        />
      </WrapperField>

      {!isCompanyList && (
        <MyUrlField
          source="company.name"
          to={(record) => `/${ROUTES.COMPANY_LIST.path}/${record?.company?.id}/show`}
          label="Компания"
          isAlignLeft={true}
        />
      )}

      <MyUrlField
        source="createdBy.fioShort"
        to={(record) => `/${ROUTES.USERS.path}?userId=${record?.createdBy?.id}`}
        label={
          <>
            Кто <br /> создал
          </>
        }
        isAlignLeft={true}
        sortable={false}
      />

      <FunctionField
        label={
          <>
            Дата <br /> активации
          </>
        }
        source="activatedAt"
        render={(record) =>
          record?.activatedAt ? format(record.activatedAt, PATTERN_DATE_TIME_FULL) : null
        }
        sortable={false}
      />

      <MyBooleanField source="isActivated" label="Активирован" sortable={false} />
      <MyUrlField
        source="activatedBy.fioShort"
        to={(record) => `/${ROUTES.USERS.path}?userId=${record?.activatedBy?.id}`}
        label={
          <>
            Кто <br /> активировал
          </>
        }
        isAlignLeft={true}
        sortable={false}
      />

      <FunctionField
        label={
          <>
            Действует <br /> с
          </>
        }
        source="activationStats.outcome.commonOldActiveTo"
        render={(record) => {
          const statsObj = record?.activationStats;
          const hasPreviousSubscriptions = statsObj?.stats?.filter(
            (subs) => !!subs.old,
          ).length;
          const commonOldActiveTo = statsObj?.outcome?.commonOldActiveTo;
          const commonNewActiveFrom = statsObj?.outcome?.commonNewActiveFrom;
          return commonOldActiveTo
            ? format(new Date(commonOldActiveTo), PATTERN_DATE_SHORT)
            : !hasPreviousSubscriptions && commonNewActiveFrom
            ? format(new Date(commonNewActiveFrom), PATTERN_DATE_SHORT)
            : null;
        }}
        sortable={false}
      />

      <FunctionField
        label={
          <>
            Действует <br /> до
          </>
        }
        source="activationStats.outcome.commonNewActiveTo"
        render={(record) =>
          record?.activationStats?.outcome?.commonNewActiveTo
            ? format(
                new Date(record?.activationStats?.outcome?.commonNewActiveTo),
                PATTERN_DATE_SHORT,
              )
            : null
        }
        sortable={false}
      />

      <FunctionField
        label="Источник"
        source="source"
        sortable={false}
        render={(record) => (record?.source === "PROMO" ? "Бонус" : "Счет")}
      />

      <TextField
        label={
          <>
            Название <br /> тарифа
          </>
        }
        source="name"
        sortable={false}
      />
    </Datagrid>
  );
}
