import {InputAdornment, Tooltip} from "@mui/material";
import {format} from "date-fns";
import {
  CreateButton,
  Datagrid,
  FunctionField,
  List,
  minLength,
  SearchInput,
  TextField,
  TopToolbar,
} from "react-admin";
import {MyUrlField} from "shared/components/MyUrlField/MyUrlField";
import {PATTERN_DATE_SHORT, PATTERN_DATE_TIME_FULL} from "shared/constants/dates";
import {ROUTES} from "shared/constants/ROUTES";
import {getDataGridStyles} from "shared/styles/dataGridStyles";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import {getDurationFromDMY} from "shared/utils/getDurationFromDMY";
import {MyBooleanField} from "shared/components/MyBooleanField/MyBooleanField";

const ListActions = () => (
  <TopToolbar>
    <CreateButton variant="main" sx={{px: 2}} />
  </TopToolbar>
);

const filters = [
  <SearchInput
    source="search"
    alwaysOn
    validate={minLength(3)}
    InputProps={{
      endAdornment: (
        <InputAdornment position={"end"}>
          <Tooltip
            title={
              <>
                Поиск осуществляется при длине больше 3ех символов.
                <br />
                Поиск по названию компании, инн компании, hid промо, фио пользователя
              </>
            }
          >
            <LiveHelpIcon sx={{cursor: "help"}} />
          </Tooltip>
        </InputAdornment>
      ),
    }}
  />,
];

export const PromoList = () => {
  return (
    <List
      actions={<ListActions />}
      sort={{field: "createdAt", order: "DESC"}}
      filters={filters}
    >
      <Fields />
    </List>
  );
};

function Fields() {
  return (
    <Datagrid
      bulkActionButtons={false}
      sx={({palette}) => ({
        ...getDataGridStyles(palette),
      })}
    >
      <TextField source="hid" label="Hid" />
      <TextField source="name" label="Название" sortable={false} />

      <FunctionField
        source="month"
        label={
          <>
            Кол-во <br />
            дней
          </>
        }
        render={getDurationFromDMY}
        sortable={false}
      />

      <FunctionField
        label="Создана"
        source="createdAt"
        render={(record) =>
          record?.createdAt ? format(record.createdAt, PATTERN_DATE_TIME_FULL) : null
        }
      />

      <MyUrlField
        source="createdBy.fioShort"
        to={(record) => `/${ROUTES.USERS.path}?userId=${record?.createdBy?.id}`}
        label="Кто создал"
        isAlignLeft={true}
      />

      <MyUrlField
        source="company.name"
        to={(record) => `/${ROUTES.COMPANY_LIST.path}/${record?.company?.id}/show`}
        label="Компания"
        isAlignLeft={true}
      />
      <MyBooleanField source="asset.isActivated" label="Активирован" sortable={false} />

      <FunctionField
        label={
          <>
            Действует <br /> с
          </>
        }
        source="activationStats.outcome.commonOldActiveTo"
        render={(record) => {
          const statsObj = record?.asset?.activationStats;
          const hasPreviousSubscriptions = statsObj?.stats?.filter(
            (subs) => !!subs.old,
          ).length;
          const commonOldActiveTo = statsObj?.outcome?.commonOldActiveTo;
          const commonNewActiveFrom = statsObj?.outcome?.commonNewActiveFrom;
          return commonOldActiveTo
            ? format(new Date(commonOldActiveTo), PATTERN_DATE_SHORT)
            : !hasPreviousSubscriptions && commonNewActiveFrom
            ? format(new Date(commonNewActiveFrom), PATTERN_DATE_SHORT)
            : null;
        }}
        sortable={false}
      />

      <FunctionField
        label={
          <>
            Действует <br /> до
          </>
        }
        source="asset.activationStats.outcome.commonNewActiveTo"
        render={(record) =>
          record?.asset?.activationStats?.outcome?.commonNewActiveTo
            ? format(
                new Date(record?.asset?.activationStats?.outcome?.commonNewActiveTo),
                PATTERN_DATE_SHORT,
              )
            : null
        }
        sortable={false}
      />

      <TextField
        source="internalComment"
        label={
          <>
            Внутренний <br /> комментарий
          </>
        }
        sortable={false}
      />
    </Datagrid>
  );
}
