import React, {useState} from "react";
import {Box, Button, Typography} from "@mui/material";
import axios from "axios";
import {API_URL} from "shared/constants/apiURL";
import {useToast} from "shared/hooks/toastContext/useToast";

const Control = () => {
  const [isDisabled, setIsDisabled] = useState(false);
  const {addToast} = useToast();

  const handleUpdateCompanyDb = () => {
    setIsDisabled(true);

    axios
      .post(`${API_URL}/company/v2/admin/update-companies-by-kontur-data`)
      .then((res) => {
        addToast(res.data.message);
      });

    setTimeout(() => {
      setIsDisabled(false);
    }, 30000);
  };

  return (
    <Box sx={{px: 4, py: 4}}>
      <Box sx={{display: "flex", maxWidth: "500px", gap: "20px", alignItems: "center"}}>
        <Typography>
          Обновляет некоторые поля всех компаний в локальной БД на основании данных,
          полученных из Контура:
        </Typography>
        <Box sx={{display: "flex", alignItems: "center"}}>
          <Button onClick={handleUpdateCompanyDb} disabled={isDisabled} variant="main">
            Обновить
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Control;
