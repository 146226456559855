import {useFormContext} from "react-hook-form";
import {useGetSubscriptionTypes} from "../hooks/useGetSubscriptionTypes";
import {
  SelectChangeEvent,
  Box,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  FormHelperText,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import React, {useState, ChangeEvent} from "react";
import {
  ListSubscriptionTypesResponse,
  SubscriptionType,
  SubscriptionTypeDict,
} from "typesFromApi/types/finApi";
import {useRecordContext} from "react-admin";
import ClearIcon from "@mui/icons-material/Clear";

function SubscriptionsWrapper() {
  const {getValues} = useFormContext();
  const record = useRecordContext();
  const subscriptionTypes = getValues("subscriptionTypes");
  const {data} = useGetSubscriptionTypes();

  return [...(subscriptionTypes || []), {}].map((item, index) => (
    <SelectOfSubscriptionTypes
      key={item?.code ? index : `empty`}
      currentItem={record?.subscriptionTypes?.find((sub) => sub?.code === item?.code)}
      allSubscriptionTypes={data}
    />
  ));
}

function SelectOfSubscriptionTypes({
  currentItem,
  allSubscriptionTypes: data,
}: {
  currentItem: SubscriptionTypeDict | undefined;
  allSubscriptionTypes: ListSubscriptionTypesResponse | null;
}) {
  const {
    setValue,
    getValues,
    formState: {errors},
    clearErrors,
  } = useFormContext();

  const [subscriptionTypes, setSubscriptionTypes] = useState<SubscriptionType["code"]>(
    currentItem?.code || "",
  );

  const [currentAmount, setCurrentAmount] = useState(currentItem?.amount || "");

  const subscription = getValues("subscriptionTypes");

  const availableMenuItems = React.useMemo(() => {
    if (subscriptionTypes) {
      return data?.items.filter(
        (item) =>
          !subscription.some(
            (sub) => item.code !== subscriptionTypes && sub.code === item.code,
          ),
      );
    }

    return subscription?.length
      ? data?.items.filter((item) => !subscription.some((sub) => sub.code === item.code))
      : data?.items;
  }, [data?.items, subscription, subscriptionTypes]);

  const handleChange = (event: SelectChangeEvent<typeof subscriptionTypes>) => {
    const {
      target: {value},
    } = event;

    const selectedValue = data?.items?.find((item) => item.code === value);

    const subscription = getValues("subscriptionTypes") || [];

    const existingIndex = subscription.findIndex((item) => item.code === value);

    let updatedSubscription;

    if (existingIndex !== -1) {
      updatedSubscription = subscription.map((item, index) =>
        index === existingIndex
          ? {...item, ...selectedValue, amount: item.amount + 1}
          : item,
      );
    } else {
      const founded = subscription.find((item) => item.code === subscriptionTypes);
      if (founded) {
        updatedSubscription = subscription.filter(
          (item) => item.code !== subscriptionTypes,
        );

        updatedSubscription = [...updatedSubscription, {...selectedValue, amount: 1}];
      } else {
        updatedSubscription = [...subscription, {...selectedValue, amount: 1}];
      }
    }

    setValue("subscriptionTypes", updatedSubscription, {shouldDirty: true});

    setSubscriptionTypes(value);
    clearErrors("subscriptionTypes");
  };

  const handleChangeAmount = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (/^\d*$/.test(value)) {
      const subscription = getValues("subscriptionTypes") || [];
      const updatedSubscription = subscription.map((item) =>
        item.code === subscriptionTypes ? {...item, amount: Number(value)} : item,
      );

      setCurrentAmount(e.target.value);
      setValue("subscriptionTypes", updatedSubscription, {shouldDirty: true});
      clearErrors("subscriptionTypes");
    }
  };

  const handleClearSelect = () => {
    const subscription = getValues("subscriptionTypes");

    const updatedSubscription = subscription.filter(
      (item) => item.code !== subscriptionTypes,
    );

    setSubscriptionTypes("");
    setCurrentAmount("");
    setValue("subscriptionTypes", updatedSubscription, {shouldDirty: true});
  };

  return (
    <Box sx={{display: "flex", gap: 4, alignItems: "flex-start"}}>
      <FormControl sx={{width: "calc(100% - 200px)"}} fullWidth>
        <InputLabel id="availableSubscriptionTypes-label">Типы подписок</InputLabel>
        <Select
          labelId="availableSubscriptionTypes-label"
          id="availableSubscriptionTypes"
          value={subscriptionTypes}
          onChange={handleChange}
          input={<OutlinedInput label="Типы подписок" />}
          disabled={!availableMenuItems?.length}
          error={!!errors?.subscriptionTypes?.message}
          endAdornment={
            subscriptionTypes && (
              <InputAdornment sx={{marginRight: "15px"}} position="end">
                <IconButton onClick={handleClearSelect}>
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            )
          }
        >
          {availableMenuItems?.map((item) => (
            <MenuItem key={item.code} value={item.code}>
              {item.name}
            </MenuItem>
          ))}
        </Select>

        <FormHelperText error>
          {errors?.subscriptionTypes?.message?.toString() || " "}
        </FormHelperText>
      </FormControl>

      {subscriptionTypes && (
        <TextField
          label="Кол-во"
          onChange={handleChangeAmount}
          value={currentAmount || "1"}
          sx={{">div > input": {height: "100%"}}}
          inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
          defaultValue={"1"}
        />
      )}
    </Box>
  );
}

export {SelectOfSubscriptionTypes};
export default SubscriptionsWrapper;
