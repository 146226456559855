import {Typography} from "@mui/material";
import {NodeViewContent, NodeViewWrapper} from "@tiptap/react";
import {BoldTextNodeAttrs} from "./extension";
import {NodeView} from "@tiptap/core";

export const BoldText = ({node}: NodeView<BoldTextNodeAttrs>) => {
  return (
    <NodeViewWrapper className="react-component-with-content">
      <Typography
        variant="15px"
        sx={{
          fontWeight: 600,
          mt: node.attrs.marginTop,
          mb: node.attrs.marginBottom,
        }}
        component={NodeViewContent}
      ></Typography>
    </NodeViewWrapper>
  );
};
