/**
 * Фильтры для безопасной сделки для отображения в таблице запросов
 *
 */
export const safeDealTheme = [
  "Безопасная сделка: Подтверждение создания",
  "Безопасная сделка: Уведомление для оператора",
  "Безопасная сделка: Подтверждение оплаты Поставщику",
  "Безопасная сделка: Неурегулированный спор",
];
