import {
  Box,
  BoxProps,
  Button,
  Modal,
  ModalProps,
  Typography,
  TypographyProps,
} from "@mui/material";
import {FC, ReactNode} from "react";
import {Close} from "@mui/icons-material";

interface UI_ModalProps extends BoxProps {
  modalProps: Omit<ModalProps, "children">;
  titleProps?: {title: string} & TypographyProps;
  children: ReactNode;
  putCloseButton?: boolean;
}

const UI_Modal: FC<UI_ModalProps> = ({
  modalProps,
  children,
  titleProps,
  putCloseButton = true,
  ...props
}) => {
  const {sx, ...rest} = props;

  return (
    <Modal disableAutoFocus {...modalProps}>
      <Box
        sx={[
          {
            color: ({palette}) => palette.text.primary,
            backgroundColor: ({palette}) => palette.backgroundBlock.default,
            borderRadius: 2,
            width: "100%",
            maxWidth: "400px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            flexDirection: "column",
            display: "flex",
            p: "40px",
            maxHeight: "90vh",
            overflow: "auto",
            mx: 2,
            // ...(sx ? (sx as any) : {}),
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        {...rest}
      >
        {putCloseButton && (
          <Button
            sx={({palette}) => ({
              position: "absolute",
              top: 0,
              right: 0,
              backgroundColor: palette.primary.main,
              width: "40px",
              height: "40px",
              minWidth: "40px",
              borderRadius: "0 4px 0 0",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",

              "&:focus-visible": {
                outline: "none",
              },

              "&:hover": {
                backgroundColor: palette.red.main,
              },
            })}
            onClick={() => {
              modalProps?.onClose && modalProps?.onClose({}, "escapeKeyDown");
            }}
          >
            <Close sx={({palette}) => ({color: palette.white.main})} />
          </Button>
        )}
        {titleProps ? (
          <Typography paragraph variant="h1-medium-20" {...titleProps} mb={6}>
            {titleProps.title}
          </Typography>
        ) : null}
        {children}
      </Box>
    </Modal>
  );
};

export {UI_Modal};
