export const PAYMENTS_STATUS_I18 = {
  NEW: " Новый платёж",
  CREATED: "Создан",
  WAIT_PAID: "Ждёт оплаты",
  WAIT_VERIFY: "Ждёт проверки",
  WAIT_SEND: "Ждёт отправки",
  PAID: "Оплачен",
  K2: "Картотека",
  CANCELED: "Отменён",
} as const;

export const PAYMENTS_STATUS_COLOR = {
  NEW: "primary.main",
  CREATED: "primary700.main",
  WAIT_PAID: "warning.main",
  WAIT_VERIFY: "warning.main",
  WAIT_SEND: "warning.main",
  PAID: "green.main",
  K2: "redLight.main",
  CANCELED: "error.main",
} as const;
