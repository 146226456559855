import {separateValueForMoney} from "shared/utils/separateValueForMoney";
import {CustomStack} from "./PaymentAndDelivery";
import {TicketType} from "../TicketsEditProcedures";
import {SubtitleSpan} from "../../components/SubtitleSpan/SubtitleSpan";
import {TypoAsValue} from "../../components/TypoAsValue/TypoAsValue";

const Guarantee = ({ticket}: {ticket: TicketType}) => {
  const {
    application_providing,
    application_providing_format,
    application_providing_sum,
    contract_providing,
    contract_providing_format,
    // @ts-ignore
    contract_providing_sum, //нет ее ? TODO: FIXME:
    purchase_currency: sign,
  } = ticket;

  return (
    <>
      <CustomStack>
        <SubtitleSpan>Обеспечение заявки</SubtitleSpan>
        <TypoAsValue>{application_providing}</TypoAsValue>
        {application_providing === "Да" ? (
          <>
            <SubtitleSpan>Формат обеспечения</SubtitleSpan>
            <TypoAsValue>{application_providing_format} </TypoAsValue>
            <SubtitleSpan>Сумма обеспечения</SubtitleSpan>
            <TypoAsValue>
              {separateValueForMoney(application_providing_sum)} {sign}
            </TypoAsValue>
          </>
        ) : null}
      </CustomStack>

      <CustomStack>
        <SubtitleSpan>Обеспечение договора</SubtitleSpan>
        <TypoAsValue>{contract_providing}</TypoAsValue>
        {contract_providing === "Да" ? (
          <>
            <SubtitleSpan>Формат обеспечения</SubtitleSpan>
            <TypoAsValue>{contract_providing_format}</TypoAsValue>
            <SubtitleSpan>Сумма обеспечения</SubtitleSpan>
            <TypoAsValue>
              {separateValueForMoney(contract_providing_sum)} {sign}
            </TypoAsValue>
          </>
        ) : null}
      </CustomStack>
    </>
  );
};

export {Guarantee};
