import {useState} from "react";
import {useDropzone} from "react-dropzone";
import {useAppSelector} from "shared/hooks/useAppSelector";
import {useAppDispatch} from "shared/hooks/useAppDispatch";
import {object, string} from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {setChatLoaders} from "shared/ReduxSlice/chatSlice/chatSlice";
import {ChatEvents} from "shared/utils/chatUtils/ChatEvents";
import {useHandleUpdateFiles} from "./useHandleUpdateFiles";
import {useSendNewMessage} from "./useSendNewMessage";

type TProps = {
  onClose: () => void;
  text: string;
  onSubmit: () => void;
};

// валидация формы
const validationScheme = object({
  text: string().nullable(),
});

/**
 * Используется для отправки файлов в чат
 */
export const useSendFileMessageForm = ({onClose, text, onSubmit}: TProps) => {
  const formMethods = useForm({
    mode: "all",
    defaultValues: {text},
    resolver: yupResolver(validationScheme),
  });
  const dispatch = useAppDispatch();
  const [filesArray, setFilesArray] = useState<File[]>([]);
  const [errorText, setErrorText] = useState("");
  const {handleSendNewMessage} = useSendNewMessage();
  const {handleUpdateFiles, progressValue} = useHandleUpdateFiles();
  const {loaders} = useAppSelector((state) => state.chatSlice);

  const handleDeleteFile = (fileName: string) => {
    setFilesArray((prev) => prev.filter(({name}) => name !== fileName));
  };

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop: (files: File[]) => setFilesArray([...filesArray, ...files]),
    multiple: true,
    maxFiles: 10,
    maxSize: 5242880,
  });

  const handleSubmit = () => {
    if (loaders[ChatEvents.ChatService.UploadFiles]) return;
    setErrorText("");

    if (filesArray.length) {
      dispatch(setChatLoaders({[ChatEvents.ChatService.UploadFiles]: true}));

      handleUpdateFiles(filesArray)
        .then((res) => {
          dispatch(setChatLoaders({[ChatEvents.ChatService.UploadFiles]: false}));

          const errors = res.filter((fileRes) => "error" in fileRes);
          if (errors.length) {
            const errMessage = errors[0].error?.data?.detail?.form || "Произошла ошибка";
            return setErrorText(errMessage);
          }
          const text = formMethods.getValues("text") || "";
          const media = res.filter((fileRes) => !("error" in fileRes));
          handleSendNewMessage({media, text});
          formMethods.reset({text: ""});
          setFilesArray([]);
          onSubmit();
          onClose();
        })
        .catch((error) => {
          dispatch(setChatLoaders({[ChatEvents.ChatService.UploadFiles]: false}));
          setErrorText(error.message || "Произошла ошибка");
        });
    } else {
      handleSendNewMessage({text});
      formMethods.reset({text: ""});
      setFilesArray([]);
      onSubmit();
      onClose();
    }
  };

  return {
    getRootProps,
    getInputProps,
    isDragActive,
    filesArray,
    handleDeleteFile,
    handleSubmit,
    isLoading: loaders[ChatEvents.ChatService.UploadFiles],
    formMethods,
    errorText,
    progressValue,
  };
};
