import {Box, Button, Stack, Typography} from "@mui/material";
import axios from "axios";
import React, {useState} from "react";
import {useRefresh, useRecordContext} from "react-admin";
import {UI_Modal} from "shared/components/UI_Modal/UI_Modal";
import {API_URL} from "shared/constants/apiURL";
import {useToast} from "shared/hooks/toastContext/useToast";
import {useDisclosure} from "shared/hooks/useDisclosure";
import {handleTransformToFormData} from "shared/utils/handleTransformObjToFD";

type ObjectBtn = {
  key: string;
  value: boolean;
};

type ButtonsToggleStatusProps = {
  isSupplier: boolean;
  isCustomer: boolean;
  companyName: string;
  companyId: string;
};

const STATUS_KEYS = {
  isSupplier: "поставщик",
  isCustomer: "заказчик",
} as const;

const STATUS_ENTITIES = {
  isSupplier: "IS_SUPPLIER",
  isCustomer: "IS_CUSTOMER",
} as const;

const ButtonsToggleStatus: React.FC<ButtonsToggleStatusProps> = (
  props: ButtonsToggleStatusProps,
) => {
  const {companyName, companyId} = props;
  const {isOpen, onClose, onOpen} = useDisclosure();
  const {addToast, addErrorToast} = useToast();
  const refresh = useRefresh();
  const [selectedButton, setSelectedButton] = useState<ObjectBtn | null>(null);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleCloseModal = () => {
    onClose();
    setSelectedButton(null);
  };

  const handleToggleStatus = (selected: ObjectBtn | null) => {
    if (!selected) return;
    setIsDisabled(true);
    const request = {
      entity: STATUS_ENTITIES[selected.key],
      action: selected.value ? "UNSET" : "SET",
      companyId,
    };

    axios
      .patch(
        `${API_URL}/company/v2/update-company-status`,
        handleTransformToFormData(request),
      )
      .then((res) => {
        addToast("Статус обновлен");
        refresh();
        handleCloseModal();
      })
      .catch((err) => addErrorToast("Произошла ошибка"))
      .finally(() => setIsDisabled(false));
  };

  return (
    <>
      <Box sx={{display: "flex", gap: 4}}>
        {["isSupplier", "isCustomer"].map((key) => (
          <Button
            key={key}
            variant={props[key] ? "reject" : "green-hover-primary"}
            onClick={() => {
              onOpen();
              setSelectedButton({
                key,
                value: props[key],
              });
            }}
            sx={{maxWidth: "180px", width: "100%"}}
          >
            {props[key] ? "Убрать" : "Установить"} {STATUS_KEYS[key]}
          </Button>
        ))}
      </Box>
      <UI_Modal
        modalProps={{
          open: isOpen,
          onClose: handleCloseModal,
        }}
        titleProps={{
          title: "Вы уверены?",
          variant: "16px",
          sx: {fontWeight: 600},
        }}
        sx={{
          maxWidth: "900px",
        }}
      >
        <Stack gap={4}>
          <Typography variant="15px">
            {selectedButton?.value ? "Убрать" : "Установить"} статус{" "}
            {STATUS_KEYS[selectedButton?.key || ""]} у компании {companyName}
          </Typography>
          <Button
            onClick={() => handleToggleStatus(selectedButton)}
            variant="green-hover-primary"
            sx={{maxWidth: "180px", width: "100%"}}
            disabled={isDisabled}
          >
            Да
          </Button>
        </Stack>
      </UI_Modal>
    </>
  );
};

export const ButtonsRecordContextWrapper = () => {
  const record = useRecordContext();
  return (
    <ButtonsToggleStatus
      companyName={record?.name}
      isCustomer={!!record.customer}
      isSupplier={!!record?.supplier}
      companyId={record.id as string}
    />
  );
};
