import {Box, Typography} from "@mui/material";
import {NodeViewContent, NodeViewWrapper} from "@tiptap/react";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {ImportantTextNodeAttrs} from "./extension";
import {NodeView} from "@tiptap/core";
import ErrorIcon from "@mui/icons-material/Error";

const iconObj = {
  info: <ErrorIcon color="primary" />,
  support: <ContactSupportIcon color="primary" />,
  check: <CheckCircleIcon color="primary" />,
};

export const ImportantText = ({node}: NodeView<ImportantTextNodeAttrs>) => {
  return (
    <NodeViewWrapper className="react-component-with-content">
      <Box sx={{display: "flex", alignItems: "center", gap: 2, mb: "-12px"}}>
        {iconObj[node.attrs.icon]}
        <Typography
          variant="20px"
          sx={({palette}) => ({color: palette.primary.main})}
          component={NodeViewContent}
        ></Typography>
      </Box>
    </NodeViewWrapper>
  );
};
