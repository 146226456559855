import {
  Box,
  Button,
  Menu,
  MenuItem,
  OutlinedTextFieldProps,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
  useTheme,
} from "@mui/material";
import {FC, MouseEvent, forwardRef, useState} from "react";
import {
  getCountries,
  getCountryCallingCode,
  parsePhoneNumber,
} from "react-phone-number-input";
import PhoneInputInput from "react-phone-number-input/input";
import flags from "react-phone-number-input/flags";

import {useAnchor} from "shared/hooks/useAnchor";
import {renderScrollStyle} from "shared/styles/scrollStyle";

const countries = getCountries();
const CountrySelect = forwardRef<any, any>((propsProvidedFromPhoneNumber, ref) => {
  const {options, onChange, value, iconComponent, ...props} =
    propsProvidedFromPhoneNumber;
  const {anchorEl, handleClose, open, handleClick} = useAnchor();
  const {palette} = useTheme();

  const handleMenuItemClick = (event: MouseEvent<HTMLElement>, v: string) => {
    onChange(v);
    handleClose();
  };

  return (
    <>
      <Button
        variant="text"
        onClick={handleClick}
        sx={{
          minWidth: "unset",
          p: 0,
          mr: 4,
          minHeight: "unset",
          height: "34px",
        }}
      >
        <Stack
          sx={{
            width: 20,
            height: 15,
          }}
        >
          {value in flags ? flags[value]({title: ""}) : "-"}
        </Stack>
      </Button>
      <Menu
        TransitionComponent={undefined}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          ".MuiMenu-paper": {
            ...renderScrollStyle(palette),
            maxWidth: "100px",
            minWidth: "100px !important",
          },
        }}
      >
        <MenuItem
          selected={value === ""}
          onClick={(event) => handleMenuItemClick(event, "")}
          value={""}
          sx={{gap: 1, alignItems: "center", maxWidth: "100px"}}
        >
          <Typography>-</Typography>
        </MenuItem>
        {countries.map((country) => (
          <MenuItem
            key={country}
            value={country}
            sx={{gap: 1, alignItems: "center", maxWidth: "100px"}}
            selected={value === country}
            onClick={(event) => handleMenuItemClick(event, country)}
          >
            {country in flags ? (
              <Box
                sx={{
                  width: 20,
                  height: 15,
                }}
              >
                {/* @ts-ignore */}
                {flags[country]({title: ""})}{" "}
              </Box>
            ) : null}
            <Typography>+{getCountryCallingCode(country)}</Typography>{" "}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
});

interface UI_PhoneTextFieldProps extends Omit<OutlinedTextFieldProps, "variant"> {
  value: any;
  onChange: (v: any) => void;
  variant?: TextFieldProps["variant"];
}

const UI_PhoneTextField: FC<UI_PhoneTextFieldProps> = ({onChange, value, ...props}) => {
  const [country, setCountry] = useState<string | undefined>("RU");

  const handleSetCountryToPhone = (newCountry) => {
    if (newCountry) {
      const countryFromPhone = parsePhoneNumber(value);
      const newCountryCallingCode = getCountryCallingCode(newCountry as any);

      if (countryFromPhone?.country) {
        const newPhone = value.replace(
          countryFromPhone.countryCallingCode,
          newCountryCallingCode,
        );
        onChange(newPhone);
        setCountry(newCountry);
        return;
      }
      if (newCountryCallingCode) {
        onChange(newCountryCallingCode);
        setCountry(newCountry);
        return;
      }
      setCountry(newCountry);
    }
  };

  const onChangeInput = (phone) => {
    if (phone) {
      const currentPhoneInputCountry = parsePhoneNumber(phone);

      if (country !== currentPhoneInputCountry?.country) {
        setCountry(currentPhoneInputCountry?.country);
      }
    }
  };

  return (
    <TextField
      {...props}
      value={value}
      InputProps={{
        inputComponent: PhoneInputInput as any,
        value: value,
        //@ts-ignore
        onChange: (value) => {
          onChangeInput(value);
          onChange(value);
        },
        startAdornment: (
          <CountrySelect value={country} onChange={handleSetCountryToPhone} />
        ),
        inputProps: {
          withCountryCallingCode: true,
          country,
          component: PhoneInputInput,
          international: true,
        },
      }}
    />
  );
};

export {UI_PhoneTextField};
