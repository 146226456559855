import {format} from "date-fns";
import {TicketType} from "../TicketsEditProcedures";
import {PATTERN_DATE} from "shared/constants/dates";
import {CustomStack} from "./PaymentAndDelivery";
import {SubtitleSpan} from "../../components/SubtitleSpan/SubtitleSpan";
import {TypoAsValue} from "../../components/TypoAsValue/TypoAsValue";

const Dates = ({ticket}: {ticket: TicketType}) => {
  const {
    time_publication,
    ts_application_finish,
    ts_processing_finish,
    procedure_type,
    ts_summarizing,
    ts_deadline_contract_start,
    ts_deadline_contract_end,
    lots,
  } = ticket;

  return (
    <div>
      {time_publication && (
        <CustomStack>
          {/* 
//@ts-ignore DIFF_TYPE: time_publication: string*/}
          <SubtitleSpan>{time_publication?.name || "Опубликовано"}</SubtitleSpan>
          <TypoAsValue>
            {format(
              new Date((time_publication as any)?.time || (time_publication as string)),
              PATTERN_DATE,
            )}
          </TypoAsValue>
        </CustomStack>
      )}
      {ts_application_finish ? (
        <CustomStack>
          <SubtitleSpan>Дата окончания подачи заявок</SubtitleSpan>
          <TypoAsValue>
            {format(new Date(ts_application_finish), PATTERN_DATE)}
          </TypoAsValue>
        </CustomStack>
      ) : null}

      {ts_processing_finish ? (
        <CustomStack>
          <SubtitleSpan>Дата окончания рассмотрения заявок</SubtitleSpan>
          <TypoAsValue>
            {format(new Date(ts_processing_finish), PATTERN_DATE)}
          </TypoAsValue>
        </CustomStack>
      ) : null}

      {/* 
//@ts-ignore DIFF_TYPE: procedure_type: number, lots:null*/}
      {procedure_type === "Аукцион"
        ? (lots as any)?.map(({auction_options, id}) => {
            return (
              <CustomStack key={id}>
                <SubtitleSpan>Дата проведения аукциона</SubtitleSpan>
                <TypoAsValue>
                  {format(new Date(auction_options?.ts_start as string), PATTERN_DATE)}
                </TypoAsValue>
              </CustomStack>
            );
          })
        : null}

      {ts_summarizing ? (
        <CustomStack>
          <SubtitleSpan>Дата окончания подведения итогов</SubtitleSpan>
          <TypoAsValue>{format(new Date(ts_summarizing), PATTERN_DATE)}</TypoAsValue>
        </CustomStack>
      ) : null}

      {ts_deadline_contract_start && ts_deadline_contract_end ? (
        <CustomStack>
          <SubtitleSpan>Сроки заключения договора</SubtitleSpan>
          <TypoAsValue>
            {format(new Date(ts_deadline_contract_start), PATTERN_DATE)} -{" "}
            {format(new Date(ts_deadline_contract_end), PATTERN_DATE)}
          </TypoAsValue>
        </CustomStack>
      ) : null}
    </div>
  );
};

export {Dates};
