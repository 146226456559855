export interface IBroadcastState {
  isMainApp: boolean;
  tabNumber: number;
  tabsCount: number;
}
export const initialState: IBroadcastState = {
  isMainApp: false,
  tabNumber: 0,
  tabsCount: 0,
};
