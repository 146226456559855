import {WS_SERVERS} from "shared/constants/wsServers";

class BroadcastService {
  private _broadcaster: BroadcastChannel; // для отправки сообщений между вкладками
  public messageReceiver: BroadcastChannel; // для подключения слушателя на получение сообщений

  constructor() {
    this._broadcaster = new BroadcastChannel(WS_SERVERS.v2.name);
    this.messageReceiver = new BroadcastChannel(WS_SERVERS.v2.name);
    // закрытие каналов перед закрытием вкладки
    // window.addEventListener("beforeunload", () => {
    //   this._broadcaster.close();
    //   this.messageReceiver.close();
    // });
  }

  /**
   * внешняя функция для отправки сообщения между вкладками
   * без срабатывания по текущей вкладке
   */
  handleBcSendMessage = (message) => {
    this.messageReceiver.postMessage(message);
    // this._broadcaster.postMessage(message);
  };
  /**
   * внешняя функция для отправки сообщения между вкладками
   * со срабатыванием слушателя даже на текущей вкладке
   */
  handleBcSendGlobalMessage = (message) => {
    this._broadcaster.postMessage(message);
  };
}

export const BcService = new BroadcastService();
