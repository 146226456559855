export const CHAT_TYPES = {
  GROUP: "GROUP",
  DIRECT: "DIRECT",
  MEETING: "MEETING",
  ADD_USER_GROUP: "ADD_USER_GROUP",
} as const;

export const CHAT_ADMIN = {
  operator: "Оператор площадки",
  tech: "Техническая поддержка",
} as const;
export const INVITATION_STATE = {
  REQUEST: "REQUEST",
  ACCEPT: "ACCEPT",
} as const;

export const EVENT_BY_CURRENT_MAIN_MENU = {
  COMMON: "GetChats",
  ARCHIVE: "GetArchivedChats",
  BLACKLIST: "GetBlackListChats",
} as const;

export const CHAT_OPERATOR_ID = "cec86b8b-465e-48aa-808b-5c4c93a14b65";
