import {ReactNode} from "react";
import {ColorModeContext} from "./ThemeContext";
import {getObjectValuesType} from "shared/helpers/typeHelpers";

export const themes = {
  LIGHT: "light",
  DARK: "dark",
} as const;

const ColorModeProvider = ({children}: {children: ReactNode}) => {
  return (
    <ColorModeContext.Provider
      value={{setColorMode: (value: getObjectValuesType<typeof themes>) => value}}
    >
      {children}
    </ColorModeContext.Provider>
  );
};

export default ColorModeProvider;
