import {useState} from "react";

/**
 * Используется для получения функций управления всплывающими элементами
 *
 * (UI_Modal, Popup,...)
 *
 */
export const useDisclosure = (options = {defaultIsOpen: false}) => {
  const [isOpen, setIsOpen] = useState(options.defaultIsOpen);
  const onOpen = () => {
    setIsOpen(true);
  };
  const onClose = () => {
    setIsOpen(false);
  };
  const onToggle = () => {
    setIsOpen((prev) => !prev);
  };
  return {isOpen, onOpen, onClose, onToggle};
};
