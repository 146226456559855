export const companyFilters = ["customer", "supplier", "producer"] as const;

export const companiesFieldForSorting = {
  createdAt: "created_at",
  updatedAt: "updated_at",
  phoneNumber: "phone_number",
} as const;

export const blocksFieldForSorting = {
  createdAt: "created_at",
  companyId: "company_id",
  companyInn: "company_inn",
  companyName: "company_name",
  companyOpf: "company_opf",
  "company.name": "company_name",
  updatedAt: "updated_at",
  createdById: "created_by_id",
  updatedById: "updated_by_id",
} as const;

export const promoListFieldForSorting = {
  createdAt: "created_at",
  companyInn: "company_inn",
  companyName: "company_name",
  "company.name": "company_name",
  userFio: "user_fio",
} as const;

export const assetsListFieldForSorting = {
  createdAt: "created_at",
  companyInn: "company_inn",
  companyName: "company_name",
  "company.name": "company_name",
  userFio: "user_fio",
} as const;

export const invoicesListFieldForSorting = {
  createdAt: "created_at",
  companyInn: "company_inn",
  companyName: "company_name",
  "company.name": "company_name",
  "company.inn": "company_inn",
  userFio: "user_fio",
} as const;
