import {Box, InputAdornment, Link, Tooltip} from "@mui/material";
import {format} from "date-fns";
import {
  Datagrid,
  FunctionField,
  List,
  TextField,
  TextInput,
  minLength,
} from "react-admin";
import {PATTERN_DATE_TIME_FULL} from "shared/constants/dates";
import {getDataGridStyles} from "shared/styles/dataGridStyles";
import {textAlignLeft} from "shared/styles/textFieldAlignLeft";
import {separateValueForMoney} from "shared/utils/separateValueForMoney";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import {ROUTES} from "shared/constants/ROUTES";
import {useSearchParams} from "react-router-dom";
import {MyUrlField} from "shared/components/MyUrlField/MyUrlField";

const filters = [
  <TextInput
    source="search"
    resettable
    label={"Поиск"}
    alwaysOn
    validate={minLength(3)}
    InputProps={{
      endAdornment: (
        <InputAdornment position={"end"}>
          <Tooltip title="Поиск по номеру сделки, ИНН, названию компании, сумме сделки">
            <LiveHelpIcon sx={{cursor: "help"}} />
          </Tooltip>
        </InputAdornment>
      ),
    }}
  />,
  <TextInput
    source="supplier_inn"
    alwaysOn
    label="ИНН заказчика"
    validate={minLength(3)}
  />,
  <TextInput
    source="customer_inn"
    alwaysOn
    label="ИНН поставщика"
    validate={minLength(3)}
  />,
];

export const SafeDealsList = () => {
  const [searchParams] = useSearchParams();
  const sdID = searchParams.get("sdID") || "";

  return (
    <List
      actions={false}
      sort={{field: "createdAt", order: "DESC"}}
      filters={filters}
      hasCreate={false}
      resource={
        sdID
          ? ROUTES.SAFE_DEAL_ALL_DEALS.path + `?sdID=${sdID}`
          : ROUTES.SAFE_DEAL_ALL_DEALS.path
      }
      filterDefaultValues={{search: sdID}}
    >
      <Datagrid
        bulkActionButtons={false}
        sx={({palette}) => ({
          ...getDataGridStyles(palette),
        })}
      >
        <TextField source="hid" label="ID" sortable={false} textAlign="center" />
        <FunctionField
          label={
            <>
              Дата <br /> создания
            </>
          }
          source="createdAt"
          render={(record) =>
            record?.createdAt ? format(record.createdAt, PATTERN_DATE_TIME_FULL) : null
          }
          textAlign="center"
        />

        <TextField source="supplierName" label="Заказчик" sortable={false} />
        <TextField
          source="supplierInn"
          label={
            <>
              ИНН
              <br /> заказчика
            </>
          }
          sortable={false}
          textAlign="center"
        />

        <TextField source="customerName" label="Поставщик" sortable={false} />
        <TextField
          source="customerInn"
          label={
            <>
              ИНН
              <br /> поставщика
            </>
          }
          sortable={false}
          textAlign="center"
        />

        <FunctionField
          source="contractAmount"
          label={
            <>
              Сумма <br />
              контракта
            </>
          }
          sortable={false}
          render={(record) => (
            <span style={{whiteSpace: "nowrap"}}>
              {separateValueForMoney(record?.contractAmount || "")}
            </span>
          )}
        />

        <TextField source="statusReadable" label="Статус" sortable={false} />

        <FunctionField
          label={
            <>
              Посмотреть <br /> платежи
            </>
          }
          sortable={false}
          render={(record) =>
            record?.id ? (
              <Box
                component={Link}
                href={`/${ROUTES.SAFE_DEAL_PAYMENTS.path}?paymentsSD_ID=${record.id}`}
                sx={({palette}) => ({
                  textDecoration: "none",
                  color: palette.primary.main,
                })}
                target="_self"
              >
                Платежи
              </Box>
            ) : null
          }
          textAlign="center"
        />
      </Datagrid>
    </List>
  );
};
