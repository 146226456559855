import React, {useState} from "react";
import {Avatar, Button, Card, Stack, Typography, useTheme} from "@mui/material";
import {UserProfile} from "shared/types/UserTypes";

type TProps = {
  userData: UserProfile;
};

const ProfileData = ({userData}: TProps) => {
  const {breakpoints} = useTheme();

  const {avatar, fio, roles, phoneNumber, email, position, department, hid} = userData;

  return (
    <Stack gap={4} sx={{pt: 4}}>
      <Card
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "32px 36px",
          gap: 8,
          [breakpoints.down(1024)]: {
            flexWrap: "wrap",
          },
        }}
      >
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            gap: 8,
            width: "50%",
            maxWidth: "560px",
            [breakpoints.down(1024)]: {
              width: "100%",
            },
            [breakpoints.down(450)]: {
              flexDirection: "column",
              gap: 3,
            },
          }}
        >
          {avatar?.url && (
            <Avatar src={avatar.url} sx={{height: "100px", width: "100px"}} />
          )}
          <Stack gap={2}>
            <Typography sx={{lineHeight: "130%"}}>ID: {hid}</Typography>
            <Typography>{fio}</Typography>
          </Stack>
        </Stack>
      </Card>
      <Card
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(6, 1fr)",
          gridTemplateRows: "1fr",
          padding: "32px 36px",
          gap: 10,
          [breakpoints.down(1201)]: {
            gridTemplateColumns: "repeat(3, 1fr)",
            // gridTemplateRows: "repeat(2, 1fr)",
            gap: 4,
          },
          [breakpoints.down(901)]: {
            gridTemplateColumns: "repeat(2, 1fr)",
            // gridTemplateRows: "repeat(3, 1fr)",
          },
          [breakpoints.down(450)]: {
            gridTemplateColumns: "repeat(1, 1fr)",
            // gridTemplateRows: "repeat(6, 1fr)",
            gap: 3,
          },
        }}
      >
        <Stack gap={1}>
          <StyledHeadingTypography>Email:</StyledHeadingTypography>
          <Typography>{email || "Не указан"}</Typography>
        </Stack>
        <Stack gap={1}>
          <StyledHeadingTypography>Телефон:</StyledHeadingTypography>
          <Typography>{phoneNumber || "Не указан"}</Typography>
        </Stack>
        <Stack gap={1}></Stack>

        <Stack gap={1}>
          <StyledHeadingTypography>Должность:</StyledHeadingTypography>
          <Typography>{position || "Не указан"}</Typography>
        </Stack>
        <Stack gap={1}>
          <StyledHeadingTypography>Отдел:</StyledHeadingTypography>
          <Typography>{department || "Не указан"}</Typography>
        </Stack>
      </Card>
    </Stack>
  );
};

export default ProfileData;

// серый текст заголовка
function StyledHeadingTypography({children}: {children: React.ReactNode}) {
  return <Typography>{children}</Typography>;
}
