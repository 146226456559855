import {useContext} from "react";
import {AdminAuthContext} from "./adminAuthContext";

export const useAdminAuth = () => {
  const context = useContext(AdminAuthContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};
