import {Stack} from "@mui/material";
import FileList from "shared/components/FileList/FileList";
import ExecsFileList from "shared/components/ExecsFileList/ExecsFileList";
import Loader from "shared/components/Loader/Loader";
import TicketsEditButton from "../tickets/components/TicketsEditButton/TicketsEditButton";
import {
  DateField,
  FunctionField,
  Labeled,
  Show,
  SimpleShowLayout,
  TextField,
  useRedirect,
  useShowContext,
  useShowController,
} from "react-admin";
import {DealDate} from "./components/DealDate";
import {DealText} from "./components/DealText";
import {DealBoolean} from "./components/DealBoolean";
import {DealOperator} from "./components/DealOperator";
import {DealOperatorFooter} from "./components/DealOperatorFooter";
import {DealMessage} from "./components/DealMessage";
import {ROUTES} from "shared/constants/ROUTES";
import {MyUrlField} from "shared/components/MyUrlField/MyUrlField";
import {MyBooleanField} from "shared/components/MyBooleanField/MyBooleanField";
import {format} from "date-fns";
import {PATTERN_DATE_TIME_FULL} from "shared/constants/dates";

export default function SafeDealShow() {
  const redirect = useRedirect();
  const ctx = useShowContext();
  const {isLoading} = useShowController({
    queryOptions: {
      onError: (error) => {
        redirect("/");
      },
    },
  });
  if (ctx.isLoading) return <Loader />;

  return (
    <Show title={"Детали безопасной сделки"}>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <SimpleShowLayout>
            <Stack direction={"row"} spacing={3}>
              <Labeled label="№ запроса">
                <TextField source="hid" />
              </Labeled>
              <Labeled label="Статус">
                <TextField source="status" />
              </Labeled>
              <Labeled label="Тип запроса">
                <TextField source="theme" />
              </Labeled>
            </Stack>
            <Stack direction={"row"} spacing={3}>
              <Labeled label="Причина">
                <TextField source="resolution" />
              </Labeled>
              <Labeled label="Взята в работу">
                <MyBooleanField source="takenToWork" />
              </Labeled>
              <Labeled label="Время взятия в работу">
                <FunctionField
                  source="takenToWorkAt"
                  render={(record) =>
                    record?.takenToWorkAt
                      ? format(new Date(record.takenToWorkAt), PATTERN_DATE_TIME_FULL)
                      : null
                  }
                />
              </Labeled>

              <Labeled label="Кто взял в работу">
                <TextField source="takenToWorkBy.fio" />
              </Labeled>

              <Stack direction={"row"} spacing={3}>
                <Labeled label="Дата создания">
                  <FunctionField
                    source="createdAt"
                    render={(record) =>
                      record?.createdAt
                        ? format(new Date(record.createdAt), PATTERN_DATE_TIME_FULL)
                        : null
                    }
                  />
                </Labeled>

                <Labeled label="Кто создал">
                  <MyUrlField
                    source={"createdBy.fio"}
                    label={"Создатель запроса"}
                    to={(record) =>
                      `/${ROUTES.USERS.path}?userId=${record?.createdBy?.id}`
                    }
                  />
                </Labeled>
              </Stack>
            </Stack>
            <TextField source="title" label="Сделка" />
            <Labeled label="Требуется подтверждение">
              <DealBoolean path={"acceptanceActNeeded"} />
            </Labeled>

            <Stack direction={"row"} spacing={3}>
              <Labeled label="Дата сделки">
                <DealDate path={"dealDate"} />
              </Labeled>
              <Labeled label="Дата начала сделки">
                <DealDate path={"dealDateStart"} />
              </Labeled>
              <Labeled label="Сделка обновлена">
                <DealDate path={"updatedAt"} />
              </Labeled>
            </Stack>

            <Stack direction={"row"} spacing={3}>
              <Labeled label="Срок поставки">
                <DealDate path={"termDeliveryDate"} />
              </Labeled>
              <Labeled label="Срок приёмки">
                <DealDate path={"termAcceptanceDate"} />
              </Labeled>
            </Stack>

            <Labeled label="Количество">
              <DealText path={"contractAmount"} />
            </Labeled>
            <DealMessage />
            <DealOperator />

            <Stack direction={"row"} spacing={10}>
              <Customer />
              <Supplier />
            </Stack>
            <FileList />
            <ExecsFileList />
            <hr />
            <DealOperatorFooter />
            <TicketsEditButton />
          </SimpleShowLayout>
        </div>
      )}
    </Show>
  );
}

function Customer() {
  return (
    <Stack>
      <h3>Покупатель</h3>
      <Labeled label="Расчетный счет">
        <DealText path={"customerAccount"} />
      </Labeled>
      <Labeled label="Корреспондентский счет">
        <DealText path={"customerBankCorAccount"} />
      </Labeled>
      <Labeled label="Банк">
        <DealText path={"customerBankName"} />
      </Labeled>
      <Labeled label="БИК">
        <DealText path={"customerBic"} />
      </Labeled>
      <Labeled label="Зачислены средства на номинальный счет?">
        <DealBoolean path={"customerHasMoney"} />
      </Labeled>
      <Labeled label="ИНН">
        <DealText path={"customerInn"} />
      </Labeled>
      <Labeled label="КПП">
        <DealText path={"customerKpp"} />
      </Labeled>
      <Labeled label="Покупатель">
        <DealText path={"customerName"} />
      </Labeled>
      <Labeled label="Дата публикации">
        <DealDate path={"customerSafeDealPublishedAt"} />
      </Labeled>
      <Labeled label="Дата подтверждения">
        <DealDate path={"customerSafeDealSignedAt"} />
      </Labeled>
    </Stack>
  );
}

function Supplier() {
  return (
    <Stack>
      <h3>Продавец</h3>
      <Labeled label="Расчетный счет">
        <DealText path={"supplierAccount"} />
      </Labeled>
      <Labeled label="Корреспондентский счет">
        <DealText path={"supplierBankCorAccount"} />
      </Labeled>
      <Labeled label="Банк">
        <DealText path={"supplierBankName"} />
      </Labeled>
      <Labeled label="БИК">
        <DealText path={"supplierBic"} />
      </Labeled>
      <Labeled label="Зачислены средства на номинальный счет?">
        <DealBoolean path={"supplierHasMoney"} />
      </Labeled>
      <Labeled label="ИНН">
        <DealText path={"supplierInn"} />
      </Labeled>
      <Labeled label="КПП">
        <DealText path={"supplierKpp"} />
      </Labeled>
      <Labeled label="Покупатель">
        <DealText path={"supplierName"} />
      </Labeled>
      <Labeled label="Дата публикации">
        <DealDate path={"supplierSafeDealPublishedAt"} />
      </Labeled>
      <Labeled label="Дата подтверждения">
        <DealDate path={"supplierSafeDealSignedAt"} />
      </Labeled>
    </Stack>
  );
}
