import {AutocompleteArrayInput, AutocompleteArrayInputProps} from "react-admin";

export const MySelectArrayInput = (props: AutocompleteArrayInputProps) => {
  return (
    <AutocompleteArrayInput
      {...props}
      alwaysOn={true}
      sx={{minWidth: "180px", maxWidth: "420px"}}
      fullWidth
    />
  );
};
