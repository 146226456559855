import {Box, Typography} from "@mui/material";
import {
  ImageField,
  Labeled,
  SimpleShowLayout,
  TextField,
  useShowContext,
} from "react-admin";
import {useState} from "react";
import Loader from "shared/components/Loader/Loader";
import {fileNames} from "shared/constants/filesNames";
import {ShowAndDownloadBlock} from "../ShowAndDownloadFile/ShowAndDownloadFile";

const ExecsFileList = () => {
  const dealFiles = useShowContext().record?.state?.allExecs;
  const files = dealFiles || [];

  const loadingState = useState(false);

  return (
    files &&
    files.filter((f) => f.lastExec?.executionFile).length > 0 && (
      <>
        <Typography sx={{fontWeight: "bold", marginBottom: 2, marginTop: 4}}>
          Файлы "Исполнения сделки"
        </Typography>

        {files
          .filter((file) => file.lastExec?.executionFile)
          .map((el, i) => (
            <Box key={i}>
              <hr />

              <ShowAndDownloadBlock
                onShowFile={() => {
                  const url =
                    el?.lastExec?.executionFile?.url ||
                    el?.lastExec?.executionFile?.thumbUrl;

                  fetch(url)
                    .then((response) => response.blob())
                    .then((blob) => {
                      const blobUrl = URL.createObjectURL(blob);
                      window.open(blobUrl, "_blank");
                    });
                }}
                onDownloadFile={() =>
                  window.open(el?.lastExec?.executionFile?.url, "_blank")
                }
                fileName={
                  el?.title ||
                  fileNames[el?.lastExec?.executionFile?.filename] ||
                  `Файл ${i + 1}`
                }
              />

              <SimpleShowLayout record={el}>
                <Box display={"flex"} gap={3}>
                  <Labeled>
                    <TextField
                      record={{
                        size: ~~(el?.lastExec?.executionFile?.size / 1024) + " КБ",
                      }}
                      source={"size"}
                      label={"Размер"}
                    />
                  </Labeled>
                  <Labeled>
                    <TextField
                      source={"extension"}
                      record={{extension: el?.lastExec?.executionFile?.extension}}
                      label={"Тип"}
                    />
                  </Labeled>
                  {loadingState[0] ? (
                    <Loader />
                  ) : el?.lastExec?.executionFile?.mime?.split("/")[0] === "image" ? (
                    <ImageField
                      sx={{cursor: "pointer"}}
                      // @ts-ignore
                      onClick={() =>
                        window.open(el?.lastExec?.executionFile?.thumbUrl, "_blank")
                      }
                      record={{img: el?.lastExec?.executionFile?.thumbUrl}}
                      source={"img"}
                    />
                  ) : (
                    <>
                      <TextField
                        display={"flex"}
                        alignItems={"flex-end"}
                        record={{file: el?.lastExec?.executionFile?.originFilename}}
                        source={"file"}
                      />
                    </>
                  )}
                </Box>
              </SimpleShowLayout>
            </Box>
          ))}
      </>
    )
  );
};

export default ExecsFileList;
