import {
  SelectChangeEvent,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  InputAdornment,
  IconButton,
  MenuItem,
} from "@mui/material";
import {useState} from "react";
import {useGetList} from "react-admin";
import {ROUTES} from "shared/constants/ROUTES";
import {TariffGroup} from "typesFromApi/types/finApi";
import {useFormContext} from "react-hook-form";
import ClearIcon from "@mui/icons-material/Clear";

export const SelectOfGroupTariffs = () => {
  const {data, isError, isLoading} = useGetList<TariffGroup>(ROUTES.GROUP_TARIFFS.path);
  const {setValue, getValues} = useFormContext();
  const [selectedGroup, setSelectedGroup] = useState<TariffGroup["id"]>(
    getValues("tgroupId") || "",
  );

  const handleChange = (event: SelectChangeEvent<typeof selectedGroup>) => {
    const {
      target: {value},
    } = event;

    setSelectedGroup(value as TariffGroup["id"]);
    setValue("tgroupId", value, {shouldDirty: true});
  };

  const handleClear = () => {
    setSelectedGroup("");
    setValue("tgroupId", "", {shouldDirty: true});
  };

  return (
    <FormControl sx={{mb: 5}}>
      <InputLabel id="availableGroups-label">Тарифные группы</InputLabel>
      <Select
        labelId="availableGroups-label"
        id="availableGroups"
        value={selectedGroup}
        onChange={handleChange}
        input={<OutlinedInput label="Тарифные группы" />}
        disabled={isLoading || isError || !data?.length}
        endAdornment={
          selectedGroup && (
            <InputAdornment sx={{marginRight: "15px"}} position="end">
              <IconButton onClick={handleClear}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          )
        }
      >
        {data?.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
