import {FileUploadOutlined} from "@mui/icons-material";
import {Button, Link, Stack, Tooltip, Typography} from "@mui/material";
import {format} from "date-fns";
import {useState, useEffect} from "react";
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  TopToolbar,
  BooleanInput,
} from "react-admin";
import {PATTERN_DATE_TIME_FULL} from "shared/constants/dates";
import {getDataGridStyles} from "shared/styles/dataGridStyles";
import {PAYMENTS_STATUS_COLOR, PAYMENTS_STATUS_I18} from "./utils/constants";
import axios from "axios";
import {API_URL} from "shared/constants/apiURL";
import {useToast} from "shared/hooks/toastContext/useToast";
import {separateValueForMoney} from "shared/utils/separateValueForMoney";
import {textAlignLeft} from "shared/styles/textFieldAlignLeft";
import {ROUTES} from "shared/constants/ROUTES";
import {MyBooleanField} from "shared/components/MyBooleanField/MyBooleanField";
import {UI_Modal} from "shared/components/UI_Modal/UI_Modal";
import {useDisclosure} from "shared/hooks/useDisclosure";
import {MyUrlField} from "shared/components/MyUrlField/MyUrlField";
import {useSearchParams} from "react-router-dom";

const CustomMyToolbar = () => {
  const [isDisabled, setIsDisabled] = useState(false);
  const {isOpen, onOpen} = useDisclosure();
  const {addErrorToast} = useToast();
  const handleNoClose = () => null;

  const [countdown, setCountdown] = useState(5);

  const handleUpdatePayments = () => {
    setIsDisabled(true);

    axios
      .post(`${API_URL}/safe-deal/temp/dot-check-new-payments`)
      .then(onOpen)
      .catch((_) => addErrorToast("Произошла ошибка обновления платежей"))
      .finally(() => setIsDisabled(false));
  };

  useEffect(() => {
    let timer;
    if (isOpen) {
      timer = setInterval(() => {
        setCountdown((prevCount) => prevCount - 1);
        if (countdown === 1) {
          clearInterval(timer);
          window.location.reload();
        }
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isOpen, countdown]);

  return (
    <TopToolbar>
      <Button variant="dashed" onClick={handleUpdatePayments} disabled={isDisabled}>
        Обновить платежи
      </Button>
      <UI_Modal
        putCloseButton={false}
        sx={({palette}) => ({
          backgroundColor: palette.background.paper,
          maxWidth: "500px",
          width: "100%",
        })}
        modalProps={{open: isOpen, onClose: handleNoClose}}
        titleProps={{
          title: "Команда на обновление платежей поставлена в очередь",
          variant: "15px",
          sx: {textAlign: "center"},
        }}
      >
        <Stack sx={{alignItems: "center", gap: 2}}>
          <Typography> Страница обновится через</Typography>
          <Typography
            sx={({palette}) => ({
              color: palette.primary.main,
              fontSize: "24px !important",
            })}
          >
            {countdown}
          </Typography>
        </Stack>
      </UI_Modal>
    </TopToolbar>
  );
};

const filters = [
  <BooleanInput source="only_paid" alwaysOn label="Только оплаченные" sx={{ml: 4}} />,
  <BooleanInput source="linked_to_deals" alwaysOn label="Только связанные с БС" />,
];

export const PaymentsList = () => {
  const [searchParams] = useSearchParams();
  const paymentsSD_ID = searchParams.get("paymentsSD_ID") || "";

  return (
    <List
      actions={<CustomMyToolbar />}
      filters={filters}
      sort={{field: "createdAt", order: "DESC"}}
      hasCreate={false}
      resource={
        paymentsSD_ID
          ? ROUTES.SAFE_DEAL_PAYMENTS.path + `?paymentsSD_ID=${paymentsSD_ID}`
          : ROUTES.SAFE_DEAL_PAYMENTS.path
      }
      filterDefaultValues={{paymentsSD_ID: paymentsSD_ID}}
    >
      <Datagrid
        bulkActionButtons={false}
        sx={({palette}) => ({
          ...getDataGridStyles(palette),
        })}
      >
        <MyUrlField
          source="safeDealHid"
          label="БС ID"
          sortable={false}
          to={(record) =>
            `/${ROUTES.SAFE_DEAL_ALL_DEALS.path}?sdID=${record.safeDealHid}`
          }
        />

        <FunctionField
          label={
            <>
              Дата <br /> платежа
            </>
          }
          source="createdAt"
          render={(record) =>
            record?.createdAt ? format(record.createdAt, PATTERN_DATE_TIME_FULL) : null
          }
          textAlign="center"
        />

        <TextField source="payerName" label="Заказчик" sortable={false} />

        <TextField
          source="payerInn"
          label={
            <>
              ИНН <br /> заказчика
            </>
          }
          sortable={false}
          textAlign="center"
        />

        <TextField source="recipientName" label="Поставщик" sortable={false} />

        <TextField
          source="recipientInn"
          label={
            <>
              ИНН <br /> поставщика
            </>
          }
          sortable={false}
          textAlign="center"
        />

        <FunctionField
          source="amount"
          label="Сумма"
          sortable={false}
          render={(record) => (
            <span style={{whiteSpace: "nowrap"}}>
              {separateValueForMoney(record?.amount || "")}
            </span>
          )}
        />

        <FunctionField
          source="incoming"
          label={
            <>
              Направление <br /> платежа
            </>
          }
          sortable={false}
          render={(record) => (record?.incoming ? "Входящий" : "Исходящий")}
          textAlign="center"
        />

        <TextField source="reportComment" label="Комментарий" sortable={false} />

        <TextField
          source="purpose"
          label={
            <>
              Назначение <br /> платежа
            </>
          }
          sortable={false}
        />

        <FunctionField
          source="status"
          label="Статус"
          sortable={false}
          render={(record) => {
            if (!PAYMENTS_STATUS_I18[record.status]) return `${record.status}`;

            const [primary, secondary] = PAYMENTS_STATUS_COLOR[record.status].split(".");
            return (
              <Typography
                variant="h2-regular-14"
                sx={({palette}) => ({color: `${palette[primary][secondary]} !important`})}
              >
                {PAYMENTS_STATUS_I18[record.status]}
              </Typography>
            );
          }}
          textAlign="center"
        />

        <FunctionField
          label={
            <Tooltip title="Платежной поручение">
              <span>п/п</span>
            </Tooltip>
          }
          source="paymentOrderFile.url"
          render={(record) =>
            record?.paymentOrderFile?.url ? (
              <Link
                onClick={() => window.open(record?.paymentOrderFile?.url, "_blank")}
                sx={{cursor: "pointer"}}
              >
                <FileUploadOutlined />
              </Link>
            ) : null
          }
          sortable={false}
          textAlign="center"
        />
      </Datagrid>
    </List>
  );
};
