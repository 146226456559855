import {Stack, useTheme} from "@mui/material";
import {DoneOutlined, DoneAllOutlined} from "@mui/icons-material";
import {TChat} from "shared/ReduxSlice/chatSlice/types";
import {useAppSelector} from "shared/hooks/useAppSelector";

type TProps = Pick<TChat, "lastMessage" | "unreadMessagesCnt">;

export const ChatUnreadMessages = ({unreadMessagesCnt, lastMessage}: TProps) => {
  const {palette} = useTheme();
  const {userId} = useAppSelector((state) => state.chatSlice);

  if (unreadMessagesCnt) {
    return (
      <Stack
        sx={{
          borderRadius: "50%",
          bgcolor: palette.primary.main,
          color: palette.white.main,
          width: 24,
          height: 24,
          justifyContent: "center",
          alignItems: "center",
          fontSize: 12,
        }}
      >
        {unreadMessagesCnt > 99 ? "99+" : unreadMessagesCnt}
      </Stack>
    );
  }

  if (userId !== lastMessage.userId) return <></>;

  if (!lastMessage.isDelivered) {
    return <DoneOutlined sx={{width: 20, color: palette.black500.main}} />;
  }

  return (
    <DoneAllOutlined
      sx={{
        width: 20,
        color: lastMessage.isRead ? palette.primary.main : palette.black500.main,
      }}
    />
  );
};
