import {Avatar, Stack, Typography, useTheme} from "@mui/material";
import {DoneOutlined, DoneAllOutlined} from "@mui/icons-material";
import {format} from "date-fns";

import {useEffect, useMemo, useRef} from "react";
import {TChatMessage} from "shared/ReduxSlice/chatSlice/types";
import {chatUtils} from "shared/utils/chatUtils/chatUtils";
import {ChatEvents} from "shared/utils/chatUtils/ChatEvents";
import ProtocolButton from "./ProtocolButton";
import useInView from "shared/hooks/useInView";
import {useAdminAuth} from "shared/hooks/adminAuthContext/useAdminAuth";
import ChatFileItem from "pages/chat/body/components/ChatWithUser/components/ChatFileItem";

const MessageItem = ({message}: {message: TChatMessage}) => {
  const {user} = useAdminAuth();
  const {text, isRead, isDelivered, createdAt, id, chatId, media} = message;
  const {palette} = useTheme();
  const messagesRef = useRef<null | HTMLDivElement>(null);
  const isIntersecting = useInView(messagesRef, true);

  const currUser = useMemo(() => {
    const userByMsg = chatUtils.getChatUserById({userID: message.userId});

    if (message.userId === user?.id) {
      return {...userByMsg, fio: "Оператор"};
    }

    return userByMsg;
  }, [message, user]);

  const isRight = useMemo(() => user?.id === message.userId, [message.userId, user?.id]);

  const messageDate = useMemo(() => {
    try {
      return format(new Date(createdAt), "HH:mm");
    } catch (e) {
      console.warn("не удалось обработать время в сообщении. ошибка:");
      console.warn(e);
      return format(new Date(), "HH:mm");
    }
  }, [createdAt]);

  useEffect(() => {
    if (!isRight && !isRead && isIntersecting) {
      chatUtils.bsSend({chatId, messageId: id}, ChatEvents.ChatService.IsRead);
    }
  }, [isIntersecting]);

  return (
    <Stack
      alignItems="flex-end"
      gap={2}
      sx={{flexDirection: isRight ? "row-reverse" : "row"}}
      id={"chat_msg" + id}
      ref={messagesRef}
    >
      <Avatar
        sx={{
          width: 36,
          height: 36,
          bgcolor: palette.primary700.main,
          fontSize: "28px",
          lineHeight: "28px",
          color: palette.white.main,
        }}
        alt={currUser?.fio}
        src={currUser?.avatar?.thumb_url || ""}
      >
        {currUser?.fio[0]}
      </Avatar>
      <Stack
        sx={{
          padding: 2,
          position: "relative",
          borderRadius: 3,
          maxWidth: "70%",
          gap: 1,
          borderBottomLeftRadius: isRight ? "12px" : 0,
          borderBottomRightRadius: !isRight ? "12px" : 0,
          bgcolor: palette.mode === "light" ? palette.white.main : palette.black400.main,
          zIndex: 1,
        }}
      >
        <Typography variant="h2-medium-14" sx={{color: palette.primary.main}}>
          {currUser?.fio}
        </Typography>

        {media?.map((file) => (
          <ChatFileItem file={file} key={file.id} />
        ))}
        <Typography
          variant="h2-regular-14"
          sx={{whiteSpace: "pre-line", wordBreak: "break-word"}}
          dangerouslySetInnerHTML={{__html: text}}
        />
        <Stack direction="row" alignItems="center" justifyContent="space-between" gap={1}>
          <Stack direction="row" alignItems="center" gap={1}>
            {isRight && <MessageStatus isRead={isRead} isDelivered={isDelivered} />}
            <Typography>{messageDate}</Typography>
          </Stack>
          <ProtocolButton message={message} isRight={isRight} />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default MessageItem;

// отображение статуса сообщения (доставлено / непропитанное / прочитанное)
function MessageStatus({isRead, isDelivered}: {isRead: boolean; isDelivered: boolean}) {
  const {palette} = useTheme();

  if (!isDelivered) {
    return <DoneOutlined sx={{width: 20, color: palette.black500.main}} />;
  }
  return (
    <DoneAllOutlined
      sx={{width: 20, color: isRead ? palette.primary.main : palette.black500.main}}
    />
  );
}
