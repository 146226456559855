import {Stack, Typography, useTheme} from "@mui/material";
import React from "react";
import {useAppSelector} from "shared/hooks/useAppSelector";
import {chatUtils} from "shared/utils/chatUtils/chatUtils";
import {ChatAvatar} from "../../../../../components";

export const UserListInChat = () => {
  const {palette} = useTheme();
  const {currentChat} = useAppSelector((state) => state.chatSlice);

  const usersArray = React.useMemo(
    () =>
      (currentChat?.users || []).map((user) => chatUtils.getChatUserById({userID: user})),
    [currentChat],
  );

  return (
    <Stack sx={{borderTop: `1px solid ${palette.black300.main}`}}>
      {usersArray.map((user) => (
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            borderBottom: `1px solid ${palette.black300.main}`,
            padding: 4,
            gap: "10px",
            transition: "all 0.3s ease",

            backgroundColor: palette.background.default,
            ":hover": {
              bgcolor:
                palette.mode === "light" ? palette.black200.main : palette.black400.main,
            },
          }}
          key={user?.id}
        >
          <ChatAvatar withStatus={true} online={user?.online?.isOnline} />
          <Stack sx={{width: "100%"}} gap={1}>
            <Typography variant="h2-medium-14" sx={{fontWeight: 600}}>
              {user?.fio}
            </Typography>
            <Typography variant="h2-regular-14">{user?.company?.name}</Typography>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};
