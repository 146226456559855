import {DataProvider} from "react-admin";
import {API_URL} from "shared/constants/apiURL";
import axios from "axios";
import {
  handleConvertBlocksInCompanyReqBody,
  handleConvertBlocksReqBody,
  handleConvertOneBlockReqBody,
} from "../utils/helpers";
import {defaultProvider} from "./defaultProvider";
import {ROUTES} from "shared/constants/ROUTES";

const AVAILABLE_WARN_LENGTH = 3;

export const blocksProvider = {
  ...defaultProvider(),

  getList: async (resource, params) => {
    let fetchedData;

    if (resource.includes(ROUTES.BLOCKS_OF_COMPANY.path)) {
      fetchedData = (
        await axios.post(
          `${API_URL}/company/v2/${ROUTES.BLOCKS_OF_COMPANY.path}`,
          handleConvertBlocksInCompanyReqBody(params),
        )
      )?.data;
    } else {
      fetchedData = (
        await axios.post(`${API_URL}/${resource}`, handleConvertBlocksReqBody(params))
      )?.data;
    }

    const replacedData = (fetchedData?.items || [])?.map((item) => {
      if (item?.user?.isActive) {
        const bool = !item.user.isActive;
        item.user.isActive = bool;
      }
      if (item?.company?.isActive) {
        const bool = !item.company.isActive;
        item.company.isActive = bool;
      }
      return item;
    });

    return {data: replacedData, total: fetchedData?.totalCount};
  },

  getOne: async (resource, params) => {
    const data = (
      await axios.post(`${API_URL}/${resource}`, handleConvertOneBlockReqBody(params))
    )?.data;

    return {
      data: {
        id: params.id,
        canActionWarn: data.count < AVAILABLE_WARN_LENGTH,
      },
    };
  },
} as DataProvider;
