export const ChatEvents = {
  ChatServiceEvent: "ChatService.",
  ChatService: {
    ConnectionAccepted: "ConnectionAccepted",
    GetChat: "GetChat",
    GetArchivedChats: "GetArchivedChats",
    GetBlackListChats: "GetBlackListChats",
    GetChats: "GetChats",
    GetMessages: "GetMessages",
    GetMedia: "GetMedia",
    GetUnreadMessagesCnt: "GetUnreadMessagesCnt",
    Typing: "Typing",
    ChatOnUsers: "ChatOnUsers",
    IsRead: "IsRead",
    IsDelivered: "IsDelivered",
    MessageSend: "MessageSend",
    MessageReceived: "MessageReceived",
    SendSuccess: "SendSuccess",
    UserIsOnline: "UserIsOnline",
    UserIsOffline: "UserIsOffline",
    MeetingClose: "MeetingClose",
    MeetingClosed: "MeetingClosed",
    InvitationRequest: "InvitationRequest",
    InviteAccepted: "InviteAccepted",
    InviteRejected: "InviteRejected",
    GroupInviteAccept: "GroupInviteAccept",
    GroupInviteReject: "GroupInviteReject",
    GroupCreate: "GroupCreate",
    GroupSearch: "GroupSearch",
    GroupLeave: "GroupLeave",
    GroupAddUsers: "GroupAddUsers",
    ChatGroupAddUsersSuccess: "ChatGroupAddUsersSuccess",
    GroupDelete: "GroupDelete",
    DirectInviteAccept: "DirectInviteAccept",
    DirectInviteReject: "DirectInviteReject",
    DirectCreate: "DirectCreate",
    DirectSearch: "DirectSearch",
    MeetingInviteAccept: "MeetingInviteAccept",
    MeetingInviteReject: "MeetingInviteReject",
    MeetingCreate: "MeetingCreate",
    MeetingSearch: "MeetingSearch",
    ChatOpened: "ChatOpened",
    ChatClosed: "ChatClosed",
    ChatDeleted: "ChatDeleted",
    ChatCreated: "ChatCreated",
    ChatLeaved: "ChatLeaved",
    InProtocol: "InProtocol",
    ProtocolCreated: "ProtocolCreated",
    InProtocolSuccess: "InProtocolSuccess",
    Archive: "Archive",
    UserBlacklist: "UserBlacklist",
    UploadFiles: "UploadFiles",
  },
} as const;
