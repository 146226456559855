import {Box, Typography} from "@mui/material";
import {
  ImageField,
  Labeled,
  SimpleShowLayout,
  TextField,
  useShowContext,
} from "react-admin";
import {useState} from "react";
import Loader from "shared/components/Loader/Loader";
import {fileNames} from "shared/constants/filesNames";
import {ShowAndDownloadBlock} from "../ShowAndDownloadFile/ShowAndDownloadFile";

const FileList = ({data = null}: {data?: any[] | null}) => {
  const dealFiles = useShowContext().record?.files;

  const files = dealFiles || data || [];
  const loadingState = useState(false);

  return (
    files &&
    files?.length > 0 && (
      <>
        <Typography sx={{fontWeight: "bold", marginBottom: 2, marginTop: 4}}>
          Файлы
        </Typography>
        {files.map((el, i) => (
          <Box key={i}>
            <hr />

            <ShowAndDownloadBlock
              onShowFile={() => {
                const url = el?.url;

                fetch(url)
                  .then((response) => response.blob())
                  .then((blob) => {
                    const blobUrl = URL.createObjectURL(blob);
                    window.open(blobUrl, "_blank");
                  });
              }}
              onDownloadFile={() => window.open(el?.url, "_blank")}
              fileName={fileNames[el?.filename] || `Файл ${i + 1}`}
            />

            <SimpleShowLayout record={el} sx={{display: "flex", gap: 3}}>
              <Box display={"flex"} gap={3}>
                <Labeled>
                  <TextField
                    record={{size: ~~(el?.size / 1024) + " КБ"}}
                    source={"size"}
                    label={"Размер"}
                  />
                </Labeled>
                <Labeled>
                  <TextField source={"extension"} label={"Тип"} />
                </Labeled>
                <>
                  {loadingState[0] ? (
                    <Loader />
                  ) : el?.mime?.split("/")[0] === "image" ? (
                    <ImageField
                      sx={{cursor: "pointer"}}
                      // @ts-ignore
                      onClick={() => {
                        // TODO: временное решение, пока не поднимут продовское хранилище thumbs
                        const url = el?.url || el?.thumbUrl || el?.thumb_url;
                        window.open(url, "_blank");
                      }}
                      // TODO: временное решение, пока не поднимут продовское хранилище thumbs
                      record={{img: el?.url || el?.thumbUrl || el?.thumb_url}}
                      source={"img"}
                    />
                  ) : (
                    <>
                      <TextField
                        display={"flex"}
                        alignItems={"flex-end"}
                        record={{file: el?.originFilename || el?.origin_filename}}
                        source={"file"}
                      />
                    </>
                  )}
                </>
              </Box>
            </SimpleShowLayout>
          </Box>
        ))}
      </>
    )
  );
};

export default FileList;

/*
onShowFile

() => {
      const url = el?.thumbUrl || el?.thumb_url || el?.url;

      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const blobUrl = URL.createObjectURL(blob);
          window.open(blobUrl, "_blank");
        });
    } */

/* 
    
    onDownloadFile

    () => window.open(el?.url, "_blank")
    */

/* fileName
    
    
    fileNames[el?.filename] || `Файл ${i + 1}`
    */
