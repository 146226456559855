import {Stack, Typography, Box} from "@mui/material";
import {NumberInput} from "react-admin";
import {useFormContext} from "react-hook-form";
import {minSchema} from "../helpers/validation";

export const DaysMonthYearsComponent = () => {
  const {getValues} = useFormContext();
  const days = getValues("days");
  const months = getValues("months");
  const years = getValues("years");

  return (
    <Stack gap={3}>
      <Typography>
        Продолжительность тарифа в днях/месяцах/годах. Может быть активно только одно
        поле.
      </Typography>
      <Box sx={{display: "flex", width: "100%", justifyContent: "space-between"}}>
        <NumberInput
          source="days"
          label="День"
          disabled={months || years}
          min={1}
          validate={minSchema}
        />
        <NumberInput
          source="months"
          label="Месяц"
          disabled={days || years}
          min={1}
          validate={minSchema}
        />
        <NumberInput
          source="years"
          label="Год"
          disabled={days || months}
          min={1}
          validate={minSchema}
        />
      </Box>
    </Stack>
  );
};
