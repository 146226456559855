import {Box, Button, Stack, Typography} from "@mui/material";
import {useAdminAuth} from "shared/hooks/adminAuthContext/useAdminAuth";
import {Auth} from "shared/services/auth";

/**
 * Компонент для отображения сообщения о том, что пользователь не является Администратором.
 *
 * Предоставляет возможность выхода из учетной записи.
 */
export const NoPermissionLogout = () => {
  const {setUser} = useAdminAuth();
  return (
    <Stack sx={{margin: "0 auto", pt: 10, maxWidth: "440px", gap: "10px"}}>
      <Typography>
        Вы вошли на площадку под пользователем который не является администратором. У вас
        недостаточно прав продолжить работу.
      </Typography>

      <Box sx={{display: "flex", justifyContent: "center"}}>
        <Button
          sx={{}}
          onClick={async () => {
            await Auth.logout();
            setUser(null);
          }}
          variant="reject"
        >
          Выйти
        </Button>
      </Box>
    </Stack>
  );
};
