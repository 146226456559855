import {ChatGetMediaDto} from "typesFromApi/types/chatApi";
import {useEffect, useMemo, useState} from "react";
import {TChatFiles} from "shared/ReduxSlice/chatSlice/types";
import {WS_SERVERS} from "shared/constants/wsServers";
import {useAppSelector} from "shared/hooks/useAppSelector";
import {useDebounce} from "shared/hooks/useDebounce";
import {ChatEvents} from "shared/utils/chatUtils/ChatEvents";
import {chatUtils} from "shared/utils/chatUtils/chatUtils";

/**
 * Используется для загрузки списка файлов из чата
 */
export const usePreloadFileList = () => {
  const {currentChat, loaders, findChatsFileText, GetMedia} = useAppSelector(
    (state) => state.chatSlice,
  );
  const [mediaArray, setMediaArray] = useState<TChatFiles[]>([]);
  const debouncedValue = useDebounce(findChatsFileText);

  useEffect(() => {
    if (loaders[ChatEvents.ChatService.GetMedia]) return;
    if (!currentChat?.id) return;
    const reqBody: ChatGetMediaDto = {chatId: currentChat.id};
    chatUtils.bsSend(reqBody, ChatEvents.ChatService.GetMedia);
  }, [currentChat]);

  const initialMediaArray = useMemo(
    () =>
      GetMedia?.length
        ? GetMedia.reduce(
            (accumulator, currentValue) => [...accumulator, ...currentValue.media],
            [] as TChatFiles[],
          )
        : [],
    [GetMedia],
  );

  useEffect(() => {
    if (loaders[ChatEvents.ChatService.GetMedia]) return;
    if (!findChatsFileText) {
      setMediaArray(initialMediaArray);
    } else {
      setMediaArray(
        initialMediaArray.filter(({origin_filename}) =>
          origin_filename?.toLowerCase().includes(findChatsFileText),
        ),
      );
    }
  }, [debouncedValue, initialMediaArray]);

  return {
    isLoading: loaders[ChatEvents.ChatService.GetMedia],
    mediaArray,
  };
};
