import {PERMISSIONS_FOR_ACTIONS, PERMISSIONS_FOR_ROUTES} from "shared/constants/ROUTES";
import {UserProfile} from "shared/types/UserTypes";

export const usePermission = () => {
  /**
   * Есть ли доступ у пользователя по текущим permissions на выполнение действия
   * @param resource  проверяемое действие
   * @returns
   */
  const getActionPermission = (
    resource: keyof typeof PERMISSIONS_FOR_ACTIONS,
    user: UserProfile | null,
  ): boolean => !!user?.permissions?.includes(PERMISSIONS_FOR_ACTIONS[resource]);

  /**
   * Есть ли доступ у пользователя по текущим permissions на переход по ссылке
   * @param resource  проверяемый роут
   * @returns
   */
  const getRoutePermission = (
    resource: keyof typeof PERMISSIONS_FOR_ROUTES,
    user: UserProfile | null,
  ) => !!user?.permissions?.includes(PERMISSIONS_FOR_ROUTES[resource]);

  return {getActionPermission, getRoutePermission};
};
