import {Components, Theme} from "@mui/material/styles";
import {renderScrollStyle} from "shared/styles/scrollStyle";

export const muiSelect: Components<Theme>["MuiSelect"] = {
  defaultProps: {
    // fullWidth: true,
    MenuProps: {
      sx: ({palette}) => ({
        ".MuiMenu-paper": {
          ...renderScrollStyle(palette),
        },
      }),
    },
  },
  styleOverrides: {
    select: ({theme}) => ({
      minWidth: "100px",
      height: "10px",

      // height: "34px",
      // paddingTop: 4,
      // paddingBottom: 4,
      // display: "flex",
      // alignItems: "center",
    }),
  },
};
