import {mergeAttributes, Node} from "@tiptap/core";
import {ReactNodeViewRenderer} from "@tiptap/react";
import {TypoWithLeftBorder} from "./TypoWithLeftBorders";

export default Node.create({
  name: "WithLeftBorder",

  group: "block",

  content: "inline*",

  parseHTML() {
    return [
      {
        tag: "WithLeftBorder",
      },
    ];
  },

  renderHTML({HTMLAttributes}) {
    return ["WithLeftBorder", mergeAttributes(HTMLAttributes), 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(TypoWithLeftBorder);
  },
});
