import Loader from "shared/components/Loader/Loader";
import {
  Labeled,
  Show,
  SimpleShowLayout,
  TextField,
  useRedirect,
  useShowContext,
  useShowController,
} from "react-admin";
import {replaceBooleanData} from "shared/utils/replaceBooleanData";
import FileList from "shared/components/FileList/FileList";
import TicketsEditButton from "../components/TicketsEditButton/TicketsEditButton";
import {Stack} from "@mui/material";
import {Link, useLocation} from "react-router-dom";
import {ROUTES} from "shared/constants/ROUTES";
import {MyUrlField} from "shared/components/MyUrlField/MyUrlField";
import {getColorForStatus} from "shared/utils/getColorForStatus";

export const TicketsEditComplaint = () => {
  const redirect = useRedirect();
  useShowController({
    queryOptions: {
      onError: (error) => {
        redirect("/");
      },
    },
  });

  return (
    <Show>
      <Fields />
    </Show>
  );
};

function Fields() {
  const {record, isLoading} = useShowContext();
  const {pathname} = useLocation();
  if (isLoading) return <Loader />;

  replaceBooleanData(record);
  record["files"] = record?.complaint_files;
  record["service"] = pathname.split("/tickets/")[0].slice(1);

  const [primary, secondary] = getColorForStatus(record?.status);

  return (
    <SimpleShowLayout>
      <Stack direction={"row"} spacing={3}>
        <Labeled>
          <TextField
            source="status"
            label="Статус"
            sx={({palette}) => ({
              color:
                primary && secondary ? palette[primary][secondary] : palette.text.primary,
            })}
          />
        </Labeled>

        <Labeled>
          <TextField source="taken_to_work" label="Взята в работу" />
        </Labeled>

        {/* TODO: не существует? */}
        <Labeled>
          <TextField source="takenToWorkAt" label="Время взятия в работу" />
        </Labeled>
        {/* TODO: не существует? */}
        <Labeled>
          <TextField source="takenToWorkBy" label="Кем взята в работу" />
        </Labeled>
      </Stack>

      <Stack direction={"row"} spacing={3}>
        <Labeled>
          <TextField source="hid" label="Номер жалобы" />
        </Labeled>

        <Labeled>
          <TextField source="created_at" label="Дата создания" />
        </Labeled>

        <Labeled label={"Кем создана"}>
          <MyUrlField
            source={"created_by.fio"}
            label={"Кем создана"}
            to={(record) => `/${ROUTES.USERS.path}?userId=${record?.created_by?.id}`}
          />
        </Labeled>
        <Labeled>
          <TextField source="updated_at" label="Обновлена" />
        </Labeled>

        <Labeled label={"Кем обновлена"}>
          <MyUrlField
            source={"updated_by.fio"}
            label={"Кем обновлена"}
            to={(record) => `/${ROUTES.USERS.path}?userId=${record?.updated_by?.id}`}
          />
        </Labeled>
      </Stack>

      <Labeled>
        <TextField
          source="procedure_number"
          label="Номер процедуры"
          component={Link}
          to={`/${ROUTES.PROCEDURE_TICKETS.path}/tickets/${record.procedure_id}/show`}
          sx={({palette}) => ({
            textDecoration: "none",
            color: palette.primary.main,
            ":hover": {textDecoration: "underline"},
          })}
        />
      </Labeled>

      <Labeled>
        <TextField source="title" label="Заголовок" />
      </Labeled>

      <Labeled label={"Компания"}>
        <MyUrlField
          source={"company.name"}
          label="Компания"
          to={(record) => `/${ROUTES.COMPANY_LIST.path}/${record?.company?.id}/show`}
        />
      </Labeled>

      <Labeled>
        <TextField source="complaint_text" label="Текст жалобы" />
      </Labeled>

      {record?.files?.length > 0 && <FileList />}

      <TicketsEditButton />
    </SimpleShowLayout>
  );
}
