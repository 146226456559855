import axios from "axios";
import {useNotify} from "react-admin";
import {API_URL} from "shared/constants/apiURL";

export const useDownloadInvoice = () => {
  const notify = useNotify();

  const handleDownloadInvoice = async ({
    invoiceId,
    fileName,
  }: {
    invoiceId: string;
    fileName: string;
  }) => {
    if (!invoiceId) return;

    try {
      const data = (
        await axios.post(
          `${API_URL}/fin/v2/get-pdf-invoice?invoice_id=${invoiceId}`,
          {},
          {
            withCredentials: true,
            headers: {"Content-Type": "application/pdf"},
            responseType: "blob",
          },
        )
      )?.data;

      const blob = URL.createObjectURL(data);
      const link = document.createElement("a");
      link.setAttribute("download", fileName);
      link.href = blob;
      link.click();
      notify("Счет загружен", {type: "success"});
      return data;
    } catch (e) {
      notify("Произошла ошибка загрузки файла", {type: "error"});
      return;
    }
  };

  return {handleDownloadInvoice};
};
