import {SafeDealsList} from "./allDeals/SafeDealsList";
import {PaymentsList} from "./payments/PaymentsList";
import ListTickets from "../tickets/ListTickets/ListTickets";
import {Show, TabbedShowLayout} from "react-admin";
import {safeDealTheme} from "shared/constants/ticketsTheme";

export const SafeDealList = () => {
  return (
    <Show resource={undefined} actions={false} id={undefined}>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="Тикеты" path={""}>
          <ListTickets theme={safeDealTheme} />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Реестр" path={"list-deals/all"}>
          <SafeDealsList />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Платежи" path={"payments/list/all"}>
          <PaymentsList />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  );
};
