import {Avatar, AvatarProps, Badge, Box, useTheme} from "@mui/material";

type ChatAvatarProps = {
  online?: boolean;
  withStatus?: boolean;
  sizePx?: number;
} & AvatarProps;

export const ChatAvatar = ({
  withStatus = false,
  online = false,
  sizePx = 40,
  ...avatarProps
}: ChatAvatarProps) => {
  const {palette} = useTheme();
  const {alt, sx, ...otherAvatarProps} = avatarProps;

  return (
    <Badge
      overlap="circular"
      anchorOrigin={{vertical: "bottom", horizontal: "right"}}
      badgeContent={
        withStatus && (
          <Box
            sx={{
              width: 12,
              height: 12,
              borderRadius: "50%",
              position: "relative",
              bgcolor: palette.white.main,
              "&::after": {
                content: '""',
                position: "absolute",
                width: 10,
                height: 10,
                bgcolor: online ? palette.green.main : palette.red.main,
                borderRadius: "50%",
                top: 1,
                left: 1,
              },
            }}
          />
        )
      }
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: sizePx,
        height: sizePx,
        flexShrink: 0,
      }}
    >
      <Avatar
        sx={{
          width: 36,
          height: 36,
          bgcolor:
            palette.mode === "light" ? palette.primary700.main : palette.primary.main,
          fontSize: "28px",
          lineHeight: "28px",
          color: palette.white.main,
          ...sx,
        }}
        alt={alt}
        {...otherAvatarProps}
      >
        {alt ? alt[0] : ""}
      </Avatar>
    </Badge>
  );
};
