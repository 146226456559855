import {
  BooleanInput,
  Create,
  DateInput,
  ImageField,
  ImageInput,
  SimpleForm,
  TextInput,
  required,
  useNotify,
  useRecordContext,
} from "react-admin";
import {RichTextInput} from "ra-input-rich-text";
import {useTheme} from "@mui/material";
import {TiptapWrapper} from "shared/components/Tiptap";
import {useFormContext} from "react-hook-form";

export const NewsCreate = () => {
  return (
    <Create title="Создание новости">
      <SimpleForm sx={{maxWidth: "1200px"}}>
        <BooleanInput source="status" label={"Опубликовать"} name={"status"} />
        <TextInput
          fullWidth={true}
          source="title"
          label={"Заголовок"}
          name={"title"}
          validate={required()}
        />
        {/* <RichTextInput
          fullWidth={true}
          source="html"
          label={"Описание"}
          name={"html"}
          validate={required()}
        /> */}

        <WrapperHtmlInput />

        <WrapperImageInput />
        <BooleanInput
          name={"remove_photo"}
          source={"remove_photo"}
          label={"Скрыть изображение"}
        />
        <DateInput
          required={true}
          source="time"
          defaultValue={new Date()}
          label={"Дата"}
          name={"time"}
        />
      </SimpleForm>
    </Create>
  );
};

function WrapperImageInput() {
  const {palette} = useTheme();
  const notify = useNotify();

  const onDropRejected = () => {
    notify("Принимаются только файлы формата .jpg, .png, .jpeg", {type: "error"});
  };
  return (
    <ImageInput
      source={"preview_file"}
      accept={".jpg, .png, .jpeg"}
      label={"Новое изображение"}
      name={"preview_file"}
      validate={required()}
      sx={{
        ".RaFileInput-dropZone": {
          minHeight: 140,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: `0.3px dashed ${palette.border.main}`,
        },
      }}
      options={{onDropRejected}}
    >
      <ImageField source="src" />
    </ImageInput>
  );
}

function WrapperHtmlInput() {
  const record = useRecordContext();
  const formMethods = useFormContext();

  return <TiptapWrapper html={record?.html || ""} formMethods={formMethods} />;
}
