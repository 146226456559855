import {Box, Button, TextField, Tooltip, List as MUIList} from "@mui/material";
import {
  RichTextInputProps,
  useTiptapEditor,
  RichTextInputToolbar,
  RichTextInput,
  DefaultEditorOptions,
} from "ra-input-rich-text";
import {useState} from "react";
import {useNotify, Confirm} from "react-admin";
import {useDisclosure} from "shared/hooks/useDisclosure";
import Link from "@tiptap/extension-link";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import {LinksInToolbar} from "./LinksInToolbar";

const MyRichTextInputToolbar = (props: RichTextInputProps) => {
  const editor = useTiptapEditor();

  if (!editor) {
    return null;
  }

  return (
    <Box sx={{width: "100%", my: 2}}>
      <RichTextInputToolbar {...props} size={"large"}>
        <LinksInToolbar editor={editor} />

        <Tooltip
          title={
            <MUIList subheader={"Подсказки к пользованию:"}>
              <li>
                1) Для удаления стилей после вставки текста с другого источника
                используете Backspace
              </li>
              <li>
                2) Ctrl + Enter или Shift + Enter - это переход на новую строку внутри
                элемента
              </li>
              <li>
                3) Для создания ссылки: Выделите нужный текст и нажмите кнопку "Задать
                URL", убедитесь что выбран правильный текст и напишите URL{" "}
              </li>
              <li>
                4) Для перехода внутри сайта начинайте URL c "/" например "/srm" для
                перехода на страницу SRM, для перехода на сторонние сайты скопируете
                полностью нужную ссылку
              </li>
            </MUIList>
          }
        >
          <LiveHelpIcon
            color="primary"
            sx={{cursor: "help", position: "absolute", top: 0, right: 0}}
          />
        </Tooltip>
      </RichTextInputToolbar>
    </Box>
  );
};

export const MyEditorOptions = {
  ...DefaultEditorOptions,
  extensions: [
    ...DefaultEditorOptions.extensions,
    Link.configure({
      openOnClick: false,
      autolink: true,
    }),
  ],
};

export const MyRichTextInput = (props: RichTextInputProps) => (
  <RichTextInput
    editorOptions={MyEditorOptions}
    toolbar={<MyRichTextInputToolbar {...props} />}
    {...props}
  />
);
