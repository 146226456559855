import {DataProvider} from "react-admin";
import {defaultProvider} from "./defaultProvider";
import {ROUTES, TICKET_ROUTES} from "shared/constants/ROUTES";
import {API_URL} from "shared/constants/apiURL";
import {getParams, getTotal} from "../utils/helpers";
import axios from "axios";

export const ticketsListProvider = {
  ...defaultProvider(),
  getList: async (resource = "", params) => {
    params.filter = {
      ...params.filter,
      theme: params.filter?.theme?.length ? params.filter.theme : TICKET_ROUTES[resource],
    };

    const res = await axios.get(`${API_URL}/tickets/admin/all?${getParams(params)}`);
    const total = getTotal(res.headers);

    return {total: total, data: res.data};
  },
  getOne: async (resource = "", params) => {
    /* return null bcs in safe deal list we are using Show and TabbedShowLayout for displaying three different lists*/
    if (resource === ROUTES.SAFE_DEAL_TICKETS.path) return {data: {id: null}};

    return defaultProvider().getOne(resource, params);
  },
} as DataProvider;
