import {Box, Button, Typography} from "@mui/material";
import {NodeViewContent, NodeViewWrapper} from "@tiptap/react";
import {Link} from "react-router-dom";
import {DefaultBlockWithBackgroundAttrs} from "./extension";
import {NodeView} from "@tiptap/core";

export const BlockWithBackground = ({
  node,
}: NodeView<DefaultBlockWithBackgroundAttrs>) => {
  return (
    <NodeViewWrapper>
      <Box
        sx={({palette}) => ({
          backgroundColor: palette.backgroundBlock.secondary,
          borderRadius: 2,
          p: "16px 36px 16px 24px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 8,
        })}
      >
        <Typography sx={{fontSize: "15px !important"}} component={NodeViewContent} />

        <Button
          variant="main"
          sx={{fontSize: "15px !important"}}
          component={Link}
          to={node.attrs.url}
        >
          Подробнее
        </Button>
      </Box>
    </NodeViewWrapper>
  );
};
