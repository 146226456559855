import {Box, Collapse, Stack, Typography} from "@mui/material";
import {InvoiceList} from "pages/invoice";
import React, {useState} from "react";
import {useRecordContext} from "react-admin";
import {ExpandLessOutlined, ExpandMoreOutlined} from "@mui/icons-material";

export const ShowCompanyInvoices = () => {
  const record = useRecordContext();

  const [showCompanyInvoices, setShowCompanyInvoices] = useState(false);

  return (
    <Stack>
      <Box
        onClick={() => setShowCompanyInvoices((prev) => !prev)}
        sx={({palette}) => ({
          cursor: "pointer",
          color: palette.primary.main,
          display: "flex",
          alignItems: "center",
        })}
      >
        <Typography variant="16px">Выставленные счета компании</Typography>
        {showCompanyInvoices ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
      </Box>

      <Collapse in={showCompanyInvoices}>
        <InvoiceList companyId={record.id as string} isCompanyList />
      </Collapse>
    </Stack>
  );
};
