import {
  SelectChangeEvent,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
} from "@mui/material";
import React from "react";
import {useFatherCtx} from "shared/hooks/fatherContext/useFatherCtx";
import {useFormContext} from "react-hook-form";

export const SelectRoles = ({
  operatorRoles = [],
  isOperatorBlocked = false,
}: {
  operatorRoles?: string[];
  isOperatorBlocked?: boolean;
}) => {
  const {availableRoles} = useFatherCtx();
  const {setValue} = useFormContext();

  const [chosenRoles, setChosenRoles] = React.useState<string[]>(operatorRoles || []);

  const handleChange = (event: SelectChangeEvent<typeof chosenRoles>) => {
    const {
      target: {value},
    } = event;
    const valueAsArray = typeof value === "string" ? value.split(",") : value;
    setChosenRoles(valueAsArray);
    setValue("roles", valueAsArray, {shouldDirty: true});
  };

  return (
    <FormControl sx={{mb: 5}}>
      <InputLabel id="availableRoles-label">Роли</InputLabel>
      <Select
        labelId="availableRoles-label"
        id="availableRoles"
        multiple
        value={chosenRoles}
        onChange={handleChange}
        input={<OutlinedInput label="Роли" />}
      >
        {availableRoles?.map((item) => (
          <MenuItem
            key={item.id}
            value={item.id}
            disabled={isOperatorBlocked && !operatorRoles.includes(item.id)}
          >
            {item.i10n}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
