import {
  CreateButton,
  Datagrid,
  DateField,
  FilterButton,
  ImageField,
  List,
  TextField,
  TextInput,
  TopToolbar,
  useDeleteMany,
  useListContext,
  useNotify,
  useStore,
  useUnselectAll,
  useUpdateMany,
} from "react-admin";
import {Button} from "@mui/material";
import Loader from "shared/components/Loader/Loader";
import NewIcon from "shared/components/NewIcon/NewIcon";
import React from "react";
import {getDataGridStyles} from "shared/styles/dataGridStyles";
import {MyBooleanField} from "shared/components/MyBooleanField/MyBooleanField";

const filters = [
  <TextInput source="title" label={"Заголовок"} name={"title"} />,
  <TextInput name="html" source="html" label={"Описание"} />,
];

export const NewsList = () => {
  const showImageState = useStore("showImageNewsList", false);

  return (
    <List
      filters={filters}
      actions={<TopButtons showImageState={showImageState} />}
      title="Список новостей"
    >
      <Fields showImageState={showImageState} />
    </List>
  );
};

function Fields({showImageState}) {
  const {isLoading = false} = useListContext();

  if (isLoading) return <Loader />;
  return (
    <Datagrid
      rowClick="edit"
      bulkActionButtons={<BulkActionButtons />}
      sx={({palette}) => ({
        ...getDataGridStyles(palette),
      })}
    >
      <TextField source="id" label={"ID"} textAlign="center" />
      <MyBooleanField source={"status"} label={"Опубликовать"} />
      <TextField source="title" label={"Заголовок"} />
      {showImageState[0] && (
        <ImageField
          sx={{
            "& img": {width: "90px !important", height: "auto !important"},
            padding: 0,
            lineHeight: 0,
          }}
          source={"preview_url"}
          label={"Изображение"}
        />
      )}
      <DateField source="time" label={"Дата"} textAlign="center" />
    </Datagrid>
  );
}

function BulkActionButtons(props) {
  const {data} = useListContext();

  const selectedData = React.useMemo(
    () => data.filter((item) => props.selectedIds.includes(item.id)),
    [props.selectedIds, data],
  );

  const unselectAll = useUnselectAll(props.resource);
  const notify = useNotify();

  const [delMany] = useDeleteMany(
    props.resource,
    {ids: props.selectedIds},
    {
      onSuccess: () => {
        notify("Новости удалены", {type: "success"});
        unselectAll();
      },
      onError: () => notify("Ошибка сервера", {type: "error"}),
    },
  );

  const [published] = useUpdateMany(
    props.resource,
    {ids: props.selectedIds, data: {status: true, items: selectedData}},
    {
      onSuccess: () => {
        notify("Новости опубликованы", {type: "success"});
        unselectAll();
      },
      onError: () => {
        notify("Ошибка сервера", {type: "error"});
      },
    },
  );

  const [unPublished] = useUpdateMany(
    props.resource,
    {ids: props.selectedIds, data: {status: false, items: selectedData}},
    {
      onSuccess: () => {
        notify("Новости сняты с публикации", {type: "success"});
        unselectAll();
      },
      onError: () => {
        notify("Ошибка сервера", {type: "error"});
      },
    },
  );

  return (
    <>
      <Button variant="main" onClick={() => published()}>
        Опубликовать
      </Button>
      <Button variant="main" onClick={() => unPublished()}>
        Снять с публикации
      </Button>
      <Button variant="block" onClick={() => delMany()}>
        Удалить
      </Button>
    </>
  );
}

function TopButtons({showImageState}) {
  return (
    <TopToolbar
      sx={{width: "100%", justifyContent: "flex-end", alignItems: "center", gap: 4}}
    >
      <CreateButton variant="main" sx={{px: 2}} />
      <Button
        onClick={(e) => showImageState[1]((p) => !p)}
        sx={{
          fontSize: 12,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {showImageState[0] ? "Скрыть" : "Показать"} изображения
        <NewIcon />
      </Button>
      <FilterButton />
    </TopToolbar>
  );
}
