import {Stack, Box, Typography} from "@mui/material";
import React, {useState} from "react";
import {BooleanInput, NumberInput, TextInput, useRecordContext} from "react-admin";
import {minSchema, maxSchema} from "../helpers/validation";

export const IncludeSaleInputs = () => {
  const record = useRecordContext();
  const [showAdditional, setShowAdditional] = useState(
    record?.specialPriceIsEnabled || false,
  );

  return (
    <Stack>
      <Box>
        <Typography>Акция:</Typography>
        <BooleanInput
          source="specialPriceIsEnabled"
          label="Включен ли тариф в акцию"
          onChange={(e) => setShowAdditional(e?.target.checked)}
        />
      </Box>

      {showAdditional && (
        <>
          <NumberInput
            source="specialPriceWithNds"
            label="Цена акции с учетом НДС"
            min={0}
            validate={minSchema}
          />

          <NumberInput
            source="specialPriceNoNds"
            label="Цена акции без учета НДС"
            min={0}
            validate={minSchema}
          />

          <NumberInput
            source="specialPriceNdsPercent"
            label="Ставка НДС акции, %"
            min={0}
            max={100}
            validate={[maxSchema, minSchema]}
          />

          <NumberInput
            source="specialPriceNdsAmount"
            label="Сумма НДС акции"
            min={0}
            validate={[minSchema]}
          />

          <TextInput
            source="specialPriceReason"
            label="Причина акционной цены"
            fullWidth
          />
        </>
      )}
    </Stack>
  );
};
