import {Menu, MenuItemLink, MenuItemLinkProps} from "react-admin";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import Diversity3OutlinedIcon from "@mui/icons-material/Diversity3Outlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import {
  FeedOutlined,
  BusinessOutlined,
  BusinessCenterOutlined,
  SecurityOutlined,
  HelpOutline,
  Report,
  HowToRegOutlined,
  AssignmentTurnedIn,
  SettingsOutlined,
} from "@mui/icons-material";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import {FATHER_ROUTES, ROUTES} from "shared/constants/ROUTES";
import {useLocation} from "react-router-dom";
import SpeakerGroupIcon from "@mui/icons-material/SpeakerGroup";
import SpeakerIcon from "@mui/icons-material/Speaker";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import LoyaltyOutlinedIcon from "@mui/icons-material/LoyaltyOutlined";
import CardGiftcardOutlinedIcon from "@mui/icons-material/CardGiftcardOutlined";
import {MessagesUnread} from "./components/MessagesUnread";
import {Box} from "@mui/material";
import {useChatInit} from "pages/chat/hooks/useChatInit";

const CustomMenuItemLink = ({to, primaryText, leftIcon, ...rest}: MenuItemLinkProps) => {
  const {pathname} = useLocation();

  const isNeedHighlight = () => {
    if (pathname.startsWith("/company/v2/admin/list-companies") && to === "company/v2") {
      return false;
    }

    if (
      (pathname.startsWith("/procedures/complaints") ||
        pathname.startsWith("/procedures/companyplaints")) &&
      to === "procedures"
    ) {
      return false;
    }

    if (
      pathname.startsWith("/procedures/companyplaints") &&
      to === "procedures/complaints"
    ) {
      return true;
    }

    return pathname.startsWith(`/${to}`);
  };

  return (
    // @ts-expect-error https://github.com/marmelab/react-admin/issues/9737
    <MenuItemLink
      {...rest}
      to={to}
      primaryText={primaryText}
      leftIcon={leftIcon}
      sx={({palette}) => ({
        gap: 2,
        alignItems: "center",
        color: isNeedHighlight() ? palette.primary.main : palette.text.secondary,
        "&.RaMenuItemLink-active": {
          color: isNeedHighlight() ? palette.primary.main : palette.text.secondary,
        },
        svg: {
          color: isNeedHighlight() ? palette.primary.main : palette.text.secondary,
        },
      })}
    />
  );
};

const MySideBar = (props) => {
  const {isOperator, isContentManager, ...rest} = props;
  useChatInit();
  return (
    <Menu {...rest} sx={{mt: 6, height: "90vh"}}>
      {/* контент менеджер */}

      {isContentManager && [
        <CustomMenuItemLink
          to={ROUTES.NEWS.path}
          key={ROUTES.NEWS.path}
          primaryText={ROUTES.NEWS.name}
          leftIcon={<FeedOutlined />}
        />,

        <CustomMenuItemLink
          to={ROUTES.BLOG.path}
          key={ROUTES.BLOG.path}
          primaryText={ROUTES.BLOG.name}
          leftIcon={<ArticleOutlinedIcon />}
        />,
        <CustomMenuItemLink
          to={ROUTES.FAQ.path}
          key={ROUTES.FAQ.path}
          primaryText={ROUTES.FAQ.name}
          leftIcon={<HelpOutline />}
        />,
      ]}
      {/* оператор */}
      {isOperator && [
        <CustomMenuItemLink
          to={ROUTES.CHAT.path}
          key={ROUTES.CHAT.path}
          primaryText={ROUTES.CHAT.name}
          leftIcon={
            <Box sx={{position: "relative"}}>
              <ChatOutlinedIcon />
              <MessagesUnread />
            </Box>
          }
        />,
        <CustomMenuItemLink
          to={ROUTES.COMPANY_TICKETS.path}
          key={ROUTES.COMPANY_TICKETS.path}
          primaryText={ROUTES.COMPANY_TICKETS.name}
          leftIcon={<BusinessOutlined />}
        />,
        <CustomMenuItemLink
          to={ROUTES.COMPANY_LIST.path}
          key={ROUTES.COMPANY_LIST.path}
          primaryText={ROUTES.COMPANY_LIST.name}
          leftIcon={<BusinessCenterOutlined />}
        />,
        <CustomMenuItemLink
          to={ROUTES.COMPANY_TICKETS_STATUSES.path}
          key={ROUTES.COMPANY_TICKETS_STATUSES.path}
          primaryText={ROUTES.COMPANY_TICKETS_STATUSES.name}
          leftIcon={<HowToRegOutlined />}
        />,
        <CustomMenuItemLink
          to={ROUTES.USERS.path}
          key={ROUTES.USERS.path}
          primaryText={ROUTES.USERS.name}
          leftIcon={<GroupOutlinedIcon />}
        />,
        <CustomMenuItemLink
          to={ROUTES.PROCEDURE_TICKETS.path}
          key={ROUTES.PROCEDURE_TICKETS.path}
          primaryText={ROUTES.PROCEDURE_TICKETS.name}
          leftIcon={<AssignmentTurnedIn />}
        />,
        <CustomMenuItemLink
          to={ROUTES.SAFE_DEAL_TICKETS.path}
          key={ROUTES.SAFE_DEAL_TICKETS.path}
          primaryText={ROUTES.SAFE_DEAL_TICKETS.name}
          leftIcon={<SecurityOutlined />}
        />,

        <CustomMenuItemLink
          to={ROUTES.SOPROCEDURES_TICKETS.path}
          key={ROUTES.SOPROCEDURES_TICKETS.path}
          primaryText={ROUTES.SOPROCEDURES_TICKETS.name}
          leftIcon={<Diversity3OutlinedIcon />}
        />,

        <CustomMenuItemLink
          to={ROUTES.COMPLAINTS_TICKETS.path}
          key={ROUTES.COMPLAINTS_TICKETS.path}
          primaryText={ROUTES.COMPLAINTS_TICKETS.name}
          leftIcon={<Report />}
        />,
        <CustomMenuItemLink
          to={ROUTES.USERS_AND_COMPANY_BLOCKS_LIST.path}
          key={ROUTES.USERS_AND_COMPANY_BLOCKS_LIST.path}
          primaryText={ROUTES.USERS_AND_COMPANY_BLOCKS_LIST.name}
          leftIcon={<BlockOutlinedIcon />}
        />,
        <CustomMenuItemLink
          to={ROUTES.CONTROL.path}
          key={ROUTES.CONTROL.path}
          primaryText={ROUTES.CONTROL.name}
          leftIcon={<SettingsOutlined />}
        />,

        <CustomMenuItemLink
          to={ROUTES.GROUP_TARIFFS.path}
          key={ROUTES.GROUP_TARIFFS.path}
          primaryText={ROUTES.GROUP_TARIFFS.name}
          leftIcon={<SpeakerGroupIcon />}
        />,

        <CustomMenuItemLink
          to={ROUTES.TARIFFS.path}
          key={ROUTES.TARIFFS.path}
          primaryText={ROUTES.TARIFFS.name}
          leftIcon={<SpeakerIcon />}
        />,

        <CustomMenuItemLink
          to={ROUTES.PROMO.path}
          key={ROUTES.PROMO.path}
          primaryText={ROUTES.PROMO.name}
          leftIcon={<CardGiftcardOutlinedIcon />}
        />,

        <CustomMenuItemLink
          to={ROUTES.INVOICE.path}
          key={ROUTES.INVOICE.path}
          primaryText={ROUTES.INVOICE.name}
          leftIcon={<ReceiptOutlinedIcon />}
        />,

        <CustomMenuItemLink
          to={ROUTES.ASSETS.path}
          key={ROUTES.ASSETS.path}
          primaryText={ROUTES.ASSETS.name}
          leftIcon={<LoyaltyOutlinedIcon />}
        />,
      ]}
    </Menu>
  );
};

export default MySideBar;

export const FatherSideBar = (props) => {
  return (
    <Menu {...props} sx={{mt: 6, height: "90vh"}}>
      <CustomMenuItemLink
        to={FATHER_ROUTES.LIST_ADMIN.path}
        key={FATHER_ROUTES.LIST_ADMIN.path}
        primaryText={FATHER_ROUTES.LIST_ADMIN.name}
        leftIcon={<SettingsOutlined />}
      />
    </Menu>
  );
};
