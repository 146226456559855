import {useRecordContext, useGetOne} from "react-admin";
import Loader from "shared/components/Loader/Loader";
import {ROUTES} from "shared/constants/ROUTES";
import FileList from "shared/components/FileList/FileList";

export const CompanyFiles = () => {
  const record = useRecordContext();

  const {data, isLoading} = useGetOne(ROUTES.COMPANY_FILES.path, {id: record.id});

  if (isLoading) return <Loader />;

  return data?.files?.length ? (
    <FileList data={data.files} />
  ) : (
    <>У компании нет загруженных файлов</>
  );
};
