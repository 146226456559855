import {
  EmailField,
  ImageField,
  Labeled,
  RichTextField,
  Show,
  TabbedShowLayout,
  TextField,
  DateField,
} from "react-admin";
import {Stack} from "@mui/material";
import {MyUrlField} from "shared/components/MyUrlField/MyUrlField";
import {ROUTES} from "shared/constants/ROUTES";
import {MyBooleanField} from "shared/components/MyBooleanField/MyBooleanField";
import {CompanyFiles} from "./components/CompanyFiles";
import {Employees} from "./components/Employees";
import {CompanyBlocks} from "./components/CompanyBlocks";
import {BlocksInsideCompany} from "./components/BlocksInsideCompany";
import {ButtonsRecordContextWrapper} from "./components/ButtonsToggleStatus";
import {AddPromoForCompany} from "./components/AddPromoForCompany";
import {CompanyAssets} from "./components/CompanyAssets";
import {ShowCompanyInvoices} from "./components/ShowCompanyInvoices";

export const CompanyShow = () => {
  return (
    <Show>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="Информация о компании">
          <Labeled label="Id">
            <TextField source="id" />
          </Labeled>

          <Labeled label="Название">
            <TextField source="name" />
          </Labeled>

          <EmailField source="email" />

          <Stack direction="row" spacing={3}>
            <Labeled label="ИНН">
              <TextField source="inn" />
            </Labeled>

            <Labeled label="КПП">
              <TextField source="kpp" />
            </Labeled>

            <Labeled label="ОГРН">
              <TextField source="ogrn" />
            </Labeled>

            <Labeled label="ОПФ">
              <TextField source="opf" />
            </Labeled>
          </Stack>

          <Stack direction="row" spacing={3}>
            <Labeled label="Верифицирован">
              <MyBooleanField
                source="isVerified"
                sx={{display: "flex", justifyContent: "center"}}
              />
            </Labeled>

            <Labeled label="Производитель">
              <MyBooleanField source="producer" />
            </Labeled>

            <Labeled label="Заказчик">
              <MyBooleanField source="customer" />
            </Labeled>

            <Labeled label="Поставщик">
              <MyBooleanField source="supplier" />
            </Labeled>

            <Labeled label="Доступ в SRM">
              <MyBooleanField source="srmAccess" />
            </Labeled>
          </Stack>

          <Stack direction="row" spacing={3}>
            <Labeled label="Создана">
              <DateField source="createdAt" />
            </Labeled>
            <Labeled label="Обновлена">
              <DateField source="updatedAt" />
            </Labeled>
          </Stack>

          <RichTextField source="publicDescription" />
          <RichTextField source="address" />
          <TextField source="phoneNumber" />
          <TextField source="topManagerName" />
          <TextField source="topManagerPosition" />
          <MyUrlField source="website" target="_blank" label="Сайт" isAlignLeft={true} />
          <ImageField source="logo.thumbUrl" />

          <ButtonsRecordContextWrapper />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Сотрудники">
          <Employees />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label={ROUTES.COMPANY_FILES.name}>
          <CompanyFiles />
        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab label={"Блокировки ЭТП"}>
          <CompanyBlocks />
        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab label={ROUTES.BLOCKS_OF_COMPANY.name}>
          <BlocksInsideCompany />
        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab label={"Подписки"}>
          <Stack>
            <CompanyAssets />

            <AddPromoForCompany />
            <ShowCompanyInvoices />
          </Stack>
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  );
};
