import {
  Button,
  Modal,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

import {Characteristics} from "./Characteristics";
import {useDisclosure} from "shared/hooks/useDisclosure";
import {UI_Modal} from "shared/components/UI_Modal/UI_Modal";
import {separateValueForMoney} from "shared/utils/separateValueForMoney";

const Product = ({product, index, lotIndex}) => {
  const name = product.catalog_prod?.name || product.prod_name;
  const {isOpen, onClose, onOpen} = useDisclosure();
  const disabled = !product?.description && !product?.catalog_chars?.length;
  const unit =
    typeof product?.unit === "string" ? product?.unit : product?.unit?.name || "";
  return (
    <>
      <UI_Modal
        modalProps={{
          open: isOpen,
          onClose,
        }}
        titleProps={{
          title:
            "Дополнительные требования: " +
            (product?.catalog_prod?.name ||
              // @ts-ignore
              product?.prod_name),
        }}
        sx={{
          maxWidth: "900px",
          maxHeight: "80vh",
          overflow: "auto",
        }}
      >
        <Characteristics product={product} />
      </UI_Modal>
      <TableRow>
        <TableCell>{index + 1}</TableCell>
        <TableCell>
          <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
            <Tooltip title={name}>
              <Typography sx={{textAlign: "left", width: "unset"}}>{name}</Typography>
            </Tooltip>
            <Button disabled={disabled} size="small" variant="outlined" onClick={onOpen}>
              Описание
            </Button>
          </Stack>
        </TableCell>
        <TableCell>
          <Tooltip title={unit}>
            <Typography sx={{}}>{unit}</Typography>
          </Tooltip>
        </TableCell>
        <TableCell>{product.count}</TableCell>
        <TableCell>
          <Typography sx={{textAlign: "right"}}>
            {separateValueForMoney(product.price_all_nds)}
          </Typography>
        </TableCell>
        <TableCell>{product.alternative_product ? "Да" : "Нет"}</TableCell>
      </TableRow>
    </>
  );
};

export {Product};
