import {
  Datagrid,
  DateField,
  List,
  TextInput,
  SimpleShowLayout,
  TextField,
  useListContext,
  useRecordContext,
  WrapperField,
  RowClickFunction,
  FunctionField,
  ListActions,
} from "react-admin";
import {useLocation} from "react-router-dom";
import Loader from "shared/components/Loader/Loader";
import React from "react";
import {ROUTES} from "shared/constants/ROUTES";
import {getDataGridStyles} from "shared/styles/dataGridStyles";
import {MySelectArrayInput} from "shared/components/MySelectArrayInput/MySelectArrayInput";
import {MyUrlField} from "shared/components/MyUrlField/MyUrlField";
import {MyBooleanField} from "shared/components/MyBooleanField/MyBooleanField";
import {PATTERN_DATE_TIME_FULL} from "shared/constants/dates";
import {format} from "date-fns";
import {textAlignLeft} from "shared/styles/textFieldAlignLeft";
import {
  complaintStatuses,
  ticketStatuses,
  ticketsColors,
} from "shared/constants/ticketsStatus";
import {getColorForStatus} from "shared/utils/getColorForStatus";

const complaintsStatus = complaintStatuses.map((item) => ({id: item, name: item}));

const status = ticketStatuses.map((item) => ({id: item, name: item}));

const getFilters = (status: {id: string; name: string}[]) => [
  <TextInput
    parse={(e = "") => {
      const int = +e.match(/\d/g)?.join("");
      return int ? int : "";
    }}
    source={"hid"}
    label="№ запроса (полный)"
    alwaysOn
  />,
  /* // <BooleanInput source={'takenToWork'} label={'В работе'} defaultChecked={true}/>, */
  <MySelectArrayInput choices={status} source={"status"} label={"Статус"} alwaysOn />,
];

const renderSelect = (theme: object[]) => (
  <MySelectArrayInput choices={theme} source={"theme"} label={"Тип запроса"} alwaysOn />
);

const ListTickets = ({theme}: {theme?: string[]}) => {
  const {pathname} = useLocation();

  const filters = getFilters(
    pathname === `/${ROUTES.COMPLAINTS_TICKETS.path}` ? complaintsStatus : status,
  );

  const themeForSelect = React.useMemo(() => {
    return theme?.map((item) => ({id: item, name: item}));
  }, [theme]);

  const updatedFilters = React.useMemo(
    () => (themeForSelect ? [...filters, renderSelect(themeForSelect)] : filters),
    [themeForSelect, filters],
  );

  const hasThemeColumn = !!themeForSelect?.length;

  return updatedFilters?.length ? (
    <List
      filters={updatedFilters}
      sort={{field: "createdAt", order: "DESC"}}
      actions={<ListActions exporter={false} />}
    >
      <Fields hasThemeColumn={hasThemeColumn} />
    </List>
  ) : null;
};

/**
 * Функция для обработки клика на строку в таблице.
 * @returns {string} Возвращает URL для перехода на определенный роут
 */
const click: RowClickFunction = (id, resource, record): string => {
  const serviceUrl =
    record.service === "company" ? `${record.service}/v2` : record.service;

  return `/${serviceUrl}/tickets/${id}/show`;
};

function Fields({hasThemeColumn}: {hasThemeColumn: boolean}) {
  const {isLoading = false} = useListContext();

  if (isLoading) return <Loader />;
  return (
    <Datagrid
      bulkActionButtons={false}
      rowClick={click}
      expand={<RowDetail />}
      expandSingle
      sx={({palette}) => ({
        ...getDataGridStyles(palette),
      })}
    >
      <TextField source={"hid"} label={"№ запроса"} textAlign="center" />

      <MyBooleanField source={"takenToWork"} label={"В работе"} textAlign="center" />

      <WrapperField label="Статус" textAlign="center">
        <ColorFields />
      </WrapperField>

      {hasThemeColumn && (
        <FunctionField
          label="Тип запроса"
          source="theme"
          render={(record) => record?.theme?.replace("Безопасная сделка:", "").trim()}
        />
      )}

      <TextField
        source={"company.name"}
        label={"Компания"}
        sortable={false}
        textAlign="left"
      />

      <FunctionField
        label="Создана"
        source="createdAt"
        textAlign="center"
        render={(record) =>
          record?.createdAt ? format(record.createdAt, PATTERN_DATE_TIME_FULL) : null
        }
      />
    </Datagrid>
  );
}

function ColorFields() {
  const record = useRecordContext();

  const [primary, secondary] = getColorForStatus(record?.status);

  return (
    <TextField
      sx={({palette}) => ({
        color: primary && secondary ? palette[primary][secondary] : palette.text.primary,
      })}
      source={"status"}
      label={"Статус"}
      sortable={false}
      textAlign="center"
    />
  );
}

function RowDetail() {
  return (
    <SimpleShowLayout
      sx={{
        pl: 8,
        pt: 4,
        div: {
          alignItems: "flex-start",
          span: {display: "flex", alignItems: "flex-start"},
        },
      }}
    >
      <TextField source={"hid"} label={"Номер запроса"} textAlign="center" />
      <TextField source={"theme"} label={"Тип запроса"} />
      <DateField source={"createdAt"} label={"Дата создания"} />
      <MyBooleanField source={"takenToWork"} label={"В работе"} />

      <MyUrlField
        source="company.name"
        to={(record) => {
          const companyId = record?.company?.id || record?.companyId;

          if (!companyId) return "";
          if (companyId === "00000000-0000-0000-0000-000000000000") return "";

          return `/${ROUTES.COMPANY_LIST.path}/${companyId}/show`;
        }}
        label="Компания"
      />

      <TextField source={"company.opf"} label={"Тип"} />
      <MyUrlField
        source={"createdBy.fio"}
        label={"Кем создана заявка"}
        to={(record) => `/${ROUTES.USERS.path}?userId=${record?.createdBy?.id}`}
      />

      <DateField source={"createdAt"} label={"Когда создали заявку"} />

      <TextField source={"takenToWorkBy.fio"} label={"Кто взял в работу"} />
      <FunctionField
        source={"takenToWorkAt"}
        label={"Когда взяли в работу"}
        render={(record) =>
          record?.takenToWorkAt
            ? format(record.takenToWorkAt, PATTERN_DATE_TIME_FULL)
            : null
        }
      />
    </SimpleShowLayout>
  );
}

export default ListTickets;
