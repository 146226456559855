import {Typography} from "@mui/material";
import {NodeViewContent, NodeViewWrapper} from "@tiptap/react";

export const BigText = (props) => {
  return (
    <NodeViewWrapper className="react-component-with-content">
      <Typography
        variant="h1-medium-20"
        sx={{my: "-4px"}}
        component={NodeViewContent}
      ></Typography>
    </NodeViewWrapper>
  );
};
