import {configureStore} from "@reduxjs/toolkit";
import {initMessageListener} from "redux-state-sync";
import {reducersForStore} from "./storeReducer";
import {middlewares} from "./middlewareStore";
import {listenerMiddleware} from "./middlewares/listenerMiddleware";

export const store = configureStore({
  reducer: reducersForStore,

  // @ts-expect-error
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({serializableCheck: false})
      .prepend(listenerMiddleware.middleware)
      .concat(middlewares),
});

initMessageListener(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
