import {Stack, TextField, Tooltip, useTheme} from "@mui/material";
import React, {useEffect, useState} from "react";
import {AttachFileOutlined} from "@mui/icons-material";
import {ChatSendButton} from "./components/ChatSendButton";
import {useAppSelector} from "shared/hooks/useAppSelector";
import {chatUtils} from "shared/utils/chatUtils/chatUtils";
import {CHAT_TYPES, INVITATION_STATE} from "shared/constants/chat";
import {ChatEvents} from "shared/utils/chatUtils/ChatEvents";
import {TypingApiArg} from "typesFromApi/types/chatApi";
import {useDisclosure} from "shared/hooks/useDisclosure";
import {UI_Modal} from "shared/components/UI_Modal/UI_Modal";
import {useSendNewMessage} from "./components/ChatSendFileMessage/hooks/useSendNewMessage";
import ChatSendFileMessage from "./components/ChatSendFileMessage/ChatSendFileMessage";

export const MessageInput = () => {
  const {currentChat, userId} = useAppSelector((state) => state.chatSlice);
  const [value, setValue] = useState("");
  const [lastTypingTimeValue, setLastTypingTimeValue] = useState(Date.now());
  const [inputState, setInputState] = useState({
    disable: false,
    placeholder: "Напишите сообщение...",
  });
  const {isOpen, onOpen, onClose} = useDisclosure();
  const {palette} = useTheme();
  const {handleSendNewMessage} = useSendNewMessage();

  useEffect(() => {
    if (currentChat) {
      const {invitationState, type, ownerId} = currentChat;
      switch (true) {
        case currentChat.userInBlackList:
          return setInputState({disable: true, placeholder: "Чат в моём чёрном списке"});
        case currentChat.meInBlackList:
          return setInputState({disable: true, placeholder: "Вы в черном списке"});
        case currentChat.inArchive:
          return setInputState({disable: true, placeholder: "Чат в архиве"});
        case currentChat.isClosed:
          return setInputState({disable: true, placeholder: "Переговоры завершены"});
        case ownerId === userId &&
          invitationState === INVITATION_STATE.REQUEST &&
          type === CHAT_TYPES.DIRECT:
          return setInputState({
            disable: true,
            placeholder: "Приглашение ещё не принято",
          });
        default:
          return setInputState({disable: false, placeholder: "Напишите сообщение..."});
      }
    }
  }, [currentChat, userId]);

  const handleChange = ({target}: React.ChangeEvent<HTMLInputElement>) => {
    if (lastTypingTimeValue < Date.now() - 2500) {
      const reqBody: TypingApiArg["httpChatTypingDto"]["message"] = {
        chatId: currentChat?.id || -1,
      };
      chatUtils.bsSend(reqBody, ChatEvents.ChatService.Typing);
      setLastTypingTimeValue(Date.now());
    }
    return setValue(target.value.trimStart());
  };

  const onSubmit = () => {
    if (!value || inputState.disable) return;
    handleSendNewMessage({text: value});
    setValue("");
  };

  const handleAttachFiles = () => {
    if (inputState.disable) return;
    onOpen();
  };

  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "flex-end",
        gap: 4,
        width: "100%",
        padding: 4,
        borderTop: ({palette}) => `1px solid ${palette.black500.main}`,
        background: palette.mode === "light" ? palette.white.main : "#21262b",
      }}
    >
      <Stack sx={{height: 47, cursor: "pointer"}} justifyContent="center">
        <Tooltip title={"Загрузить файлы"}>
          <AttachFileOutlined
            sx={({palette}) => ({
              width: 25,
              height: 25,
              opacity: inputState.disable ? 0.5 : 1,

              ":hover": {
                color: palette.primary.main,
              },
            })}
            onClick={handleAttachFiles}
          />
        </Tooltip>
      </Stack>
      <TextField
        multiline
        sx={{
          width: "100%",
          padding: "0 8px",
          fontSize: 16,
          borderRadius: 1,
          minHeight: 47,
          "& .MuiFormHelperText-root": {display: "none"},
          "& .MuiInputBase-input": {alignSelf: "center"},
        }}
        /* TODO: */
        onPaste={() => console.log(" i am pasted")}
        placeholder={inputState.placeholder}
        onKeyDown={(e) => e.key === "Enter" && e.shiftKey === false && onSubmit()}
        InputProps={{
          sx: {
            padding: 1,
            minHeight: 47,
            alignItems: "flex-end",
            fontSize: 16,
          },
          maxRows: 5,
        }}
        value={value}
        onChange={handleChange}
        disabled={inputState.disable}
      />
      <ChatSendButton
        value={value}
        handleSubmit={onSubmit}
        // isLoading={!!loaders[ChatEvents.ChatService.GetMessages]}
      />
      <UI_Modal
        modalProps={{open: isOpen, onClose}}
        sx={{maxWidth: "480px"}}
        titleProps={{title: "Отправить файлы"}}
      >
        <ChatSendFileMessage
          onClose={onClose}
          text={value}
          onSubmit={() => setValue("")}
        />
      </UI_Modal>
    </Stack>
  );
};
