import {useRef, useState, useEffect} from "react";
import {Stack, useTheme} from "@mui/material";
import {TChat, TChatUser} from "shared/ReduxSlice/chatSlice/types";
import {useAppSelector} from "shared/hooks/useAppSelector";
import {renderScrollStyle} from "shared/styles/scrollStyle";
import {chatUtils} from "shared/utils/chatUtils/chatUtils";
import {ChatGetChatsDto} from "typesFromApi/types/chatApi";
import {ChatListItem} from "./components/ChatListItem/ChatListItem";
import {EVENT_BY_CURRENT_MAIN_MENU} from "shared/constants/chat";

export const ChatList = () => {
  const {GetChats, GetBlackListChats, GetArchivedChats, currentMainMenu} = useAppSelector(
    (state) => state.chatSlice,
  );
  const {palette} = useTheme();
  const elemRef = useRef<HTMLDivElement>(null);

  const [displayedChats, setDisplayedChats] = useState<{
    chats: TChat[];
    users: TChatUser[];
    totalCount: number;
  } | null>(null);

  useEffect(() => {
    switch (currentMainMenu) {
      case "ARCHIVE":
        setDisplayedChats(GetArchivedChats);
        break;

      case "BLACKLIST":
        setDisplayedChats(GetBlackListChats);
        break;

      default:
        setDisplayedChats(GetChats);
        break;
    }
  }, [GetChats, GetBlackListChats, GetArchivedChats, currentMainMenu]);

  const callback = (entries, obser) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        obser.unobserve(entry.target);
        if (
          displayedChats?.totalCount &&
          displayedChats?.totalCount > displayedChats.chats.length
        ) {
          const data: ChatGetChatsDto = {
            limit: 20,
            lastUnreadMessageDt:
              displayedChats.chats[displayedChats.chats.length - 1].lastMessage.createdAt,
          };

          chatUtils.bsSend(data, EVENT_BY_CURRENT_MAIN_MENU[currentMainMenu]);
        }
      }
    });
  };

  const options = {
    root: elemRef.current as HTMLDivElement,
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callback, options);
    if (elemRef.current && elemRef.current.lastElementChild)
      observer.observe(elemRef.current.lastElementChild);
    return () => {
      observer?.disconnect();
    };
  }, [displayedChats, elemRef.current, currentMainMenu]);

  return (
    <Stack
      ref={elemRef}
      sx={({breakpoints}) => ({
        height: "100%",
        overflowY: "auto",
        width: "100%",
        maxWidth: "420px",
        ...renderScrollStyle(palette),
      })}
    >
      {displayedChats?.chats.map((chat, index) => (
        <ChatListItem key={chat.id} chat={chat} type={currentMainMenu} />
      ))}
    </Stack>
  );
};
