import {useShowContext} from "react-admin";

export const DealOperatorFooter = () => {
  const dealStatus =
    useShowContext().record?.theme === "Безопасная сделка: Уведомление для оператора";

  return (
    <div>
      {dealStatus && (
        <p>
          <i>
            Данный тикет является информационным сообщением. <br />
            Действия оператора с тикетом логируются, но не запускают какие-либо
            последующие программные обработки. <br />
            Одобрение тикета этого типа означает, что оператор с ним ознакомился.
          </i>
        </p>
      )}
    </div>
  );
};
