import {Typography, Stack, TextField} from "@mui/material";
import {useState} from "react";
import {useRefresh, SimpleForm} from "react-admin";
import {CommentsType} from "widgets/BlocksForm/BlocksForm";
import {BlockOperatorButtons} from "./components/BlockOperatorButtons";

const OPERATOR_ACTIONS = ["BLOCK", "UNBLOCK"] as const;

export const BlockOperators = () => {
  const [comments, setComments] = useState<CommentsType>({reason: "", internalNotes: ""});
  const refresh = useRefresh();

  const handleResetComments = () => {
    setComments({reason: "", internalNotes: ""});
    refresh();
  };

  return (
    <SimpleForm sx={{px: 4, ">div": {gap: 3}, mt: 4, maxWidth: "1200px"}} toolbar={false}>
      <Typography variant="h2-medium-14" sx={{fontWeight: 600}}>
        Заблокировать оператора:
      </Typography>
      <TextField
        helperText={false}
        fullWidth
        name={"reason"}
        required={true}
        label={"Комментарий администратора"}
        multiline={true}
        value={comments.reason}
        onChange={(e) => setComments((prev) => ({...prev, reason: e.target.value}))}
      />
      <TextField
        name={"internalNotes"}
        helperText={false}
        fullWidth
        label={"Внутренний комментарий для других администраторов"}
        value={comments.internalNotes}
        onChange={(e) =>
          setComments((prev) => ({...prev, internalNotes: e.target.value}))
        }
        multiline={true}
      />
      <Stack direction="row" spacing={3}>
        {OPERATOR_ACTIONS.map((action) => (
          <BlockOperatorButtons
            key={action}
            action={action}
            {...comments}
            onSuccess={handleResetComments}
          />
        ))}
      </Stack>
    </SimpleForm>
  );
};
