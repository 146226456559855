import {Typography, Stack} from "@mui/material";
import {memo} from "react";
import {TChatWithUserType} from "shared/ReduxSlice/chatSlice/types";
import {useAppSelector} from "shared/hooks/useAppSelector";
import {ChatBackground} from "./components/ChatBackground/ChatBackground";
import {FileListInChat} from "./components/FileListInChat/FileListInChat";
import {MessagesList} from "./components/MessagesList/MessagesList";
import {UserListInChat} from "./components/UserListInChat/UserListInChat";

const MENU_CHAT: Record<TChatWithUserType, JSX.Element> = {
  MESSAGES_LIST: (
    <ChatBackground>
      <MessagesList />
    </ChatBackground>
  ),
  USERS_LIST: <UserListInChat />,
  FILE_LIST: <FileListInChat />,
};

export const ChatWithUser = memo(() => {
  const {currentChatMenu, currentChat} = useAppSelector((state) => state.chatSlice);

  if (!currentChat) {
    return (
      <ChatBackground
        sx={{display: "flex", alignItems: "center", justifyContent: "center"}}
      >
        <Typography
          sx={{
            padding: 4,
            borderRadius: 2,
            backgroundColor: ({palette}) => palette.white.main,
            color: ({palette}) => palette.black.main,
          }}
        >
          Выберите чат
        </Typography>
      </ChatBackground>
    );
  }

  return <Stack sx={{width: "100%"}}>{MENU_CHAT[currentChatMenu]}</Stack>;
});
