import {useAppSelector} from "shared/hooks/useAppSelector";
import {useAppDispatch} from "shared/hooks/useAppDispatch";
import {TChatMessage} from "shared/ReduxSlice/chatSlice/types";
import {setNewMessageInChat} from "shared/ReduxSlice/chatSlice/chatSlice";
import {ChatEvents} from "shared/utils/chatUtils/ChatEvents";
import {MessageSendApiArg} from "typesFromApi/types/chatApi";
import {chatUtils} from "shared/utils/chatUtils/chatUtils";
import {useAdminAuth} from "shared/hooks/adminAuthContext/useAdminAuth";

/**
 * Используется для отправки нового сообщения
 */
export const useSendNewMessage = () => {
  const {user} = useAdminAuth();
  const {currentChat, GetMessages} = useAppSelector((state) => state.chatSlice);
  const dispatch = useAppDispatch();

  const handleSendNewMessage = ({
    text,
    media = [],
  }: {
    text: TChatMessage["text"];
    media?: TChatMessage["media"];
  }) => {
    const resultObj: TChatMessage = {
      chatId: currentChat?.id || -1,
      id:
        GetMessages[currentChat?.id || ""][GetMessages[currentChat?.id || ""].length - 1]
          .id + 1 || 1,
      isDelivered: false,
      isRead: false,
      text: text.replace(/\n\s*\n/g, "\n").trim(),
      userId: user?.id || "",
      createdAt: new Date().toISOString(),
      media,
      isNotify: false,
      inProtocol: false,
      // sendSuccess: false,
    };
    dispatch(setNewMessageInChat(resultObj));
    const reqBody: MessageSendApiArg["httpMessageSendDto"]["message"] = {
      chatId: currentChat?.id || -1,
      // media: media.length ? media : [],
      //@ts-ignore - временное решение (https://b24-oshain.bitrix24.ru/company/personal/user/46/tasks/task/view/792/)
      media: media.length ? media : null,
      text,
    };
    chatUtils.bsSend(reqBody, ChatEvents.ChatService.MessageSend);
  };

  return {handleSendNewMessage};
};
