import {differenceInCalendarDays, differenceInHours} from "date-fns";

/**
 * функция возвращающая кол-во оставшихся дней либо часов
 */
export const timeLeft = (date: string): string => {
  try {
    const endDate = new Date(date);
    const now = Date.now();
    const daysLeft = differenceInCalendarDays(endDate, now);
    if (daysLeft >= 1) {
      return `${daysLeft} д`;
    } else if (daysLeft < 0) {
      return "0 ч";
    } else {
      const hoursLeft = differenceInHours(endDate, now);
      return `${hoursLeft} ч`;
    }
  } catch {
    return "-";
  }
};
