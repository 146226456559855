import {Stack, CircularProgress, Typography} from "@mui/material";
import {format} from "date-fns";

import {bytesToShort} from "shared/utils/bytesShort/bytesShort";

import {TChatFiles} from "shared/ReduxSlice/chatSlice/types";
import {useDisclosure} from "shared/hooks/useDisclosure";
import {UI_Modal} from "shared/components/UI_Modal/UI_Modal";
import {useDownloadFileWithToken} from "shared/hooks/useDownloadFileWithToken";
import {PATTERN_DATE_TIME_FULL} from "shared/constants/dates";

const ChatFileItem = ({file}: {file: TChatFiles}) => {
  const {
    filename,
    icon_url,
    origin_filename,
    size,
    url,
    created_at,
    thumb_url,
    mime,
    id,
  } = file;
  const {download, isLoading} = useDownloadFileWithToken();
  const {isOpen, onOpen, onClose} = useDisclosure();

  // обработчик клика по файлу
  const handleOnClickByMediaItem = () => {
    if (mime.startsWith("image")) {
      return onOpen();
    }
    download(url, origin_filename, id);
  };

  return (
    <Stack
      sx={({palette}) => ({
        display: "flex",
        flexDirection: "row",
        gap: 2,
        alignItems: "center",
      })}
    >
      {isLoading ? (
        <CircularProgress sx={{width: 40, height: 40}} />
      ) : (
        <img
          src={thumb_url || icon_url}
          alt={origin_filename || filename}
          style={{width: 40, height: 40, cursor: "pointer"}}
          onClick={handleOnClickByMediaItem}
        />
      )}
      <Stack gap={1}>
        <Typography>{origin_filename || filename}</Typography>
        <Typography>
          {bytesToShort(size)}
          {" · "}
          {format(new Date(created_at || ""), PATTERN_DATE_TIME_FULL)}
        </Typography>
      </Stack>
      <UI_Modal modalProps={{open: isOpen, onClose}} sx={{maxWidth: "480px", padding: 1}}>
        <img src={thumb_url || icon_url} alt={origin_filename || filename} />
      </UI_Modal>
    </Stack>
  );
};

export default ChatFileItem;
