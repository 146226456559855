import React from "react";
import {Typography} from "@mui/material";

export const SubtitleSpan = ({children}: {children: React.ReactNode}) => {
  return (
    <Typography
      sx={({palette}) => ({
        color: palette.text.secondary,
        fontSize: "12px",
        mb: "0.2em",
      })}
    >
      {children}:
    </Typography>
  );
};
