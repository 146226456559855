import {Theme} from "@mui/material";
import {Components} from "@mui/material/styles";

export const muiTableHead: Components<Theme>["MuiTableHead"] = {
  styleOverrides: {
    root: ({theme}) => ({
      backgroundColor: theme.palette.primary.main,
      "& .MuiTableRow-root": {
        height: "42px",
      },
      "& .MuiTableCell-root": {
        padding: 0,

        color: theme.palette.white.main,
        fontSize: "13px",
        fontStyle: "normal",
        fontWeight: 500,
        textAlign: "center",
        lineHeight: "110%",

        borderRight: `1px solid ${theme.palette.white.main}`,
        ":last-of-type": {
          borderRight: `1px solid ${theme.palette.primary.main}`,
        },
        ":first-of-type": {
          borderLeft: `1px solid ${theme.palette.primary.main}`,
        },
      },
    }),
  },
};
