import axios from "axios";
import {DataProvider, HttpError} from "react-admin";
import {API_URL} from "shared/constants/apiURL";
import {defaultProvider} from "./defaultProvider";
import {handleTransformToFormDataV2} from "shared/utils/handleTransformObjToFD";
import {ROUTES} from "shared/constants/ROUTES";
import queryString from "query-string";

export const tariffProviders = {
  ...defaultProvider(),

  getList: async (resource = "", params) => {
    const query =
      resource === ROUTES.GROUP_TARIFFS.path
        ? ""
        : queryString.stringify({...params.filter, archived: false});

    const data = (await axios.post(`${API_URL}/${resource}?${query}`, {}))?.data;

    const total = data?.totalCount;
    const withAdditional = (data?.items || []).map((item) => ({
      ...item,
      availableSubscriptionTypes: data?.availableSubscriptionTypes,
    }));

    return {
      data: withAdditional,
      total,
    };
  },

  getOne: async (resource, params) => {
    try {
      const queryKey = resource === ROUTES.GROUP_TARIFFS.path ? "tgroup_id" : "tariff_id";

      const data = (
        await axios.get(
          `${API_URL}/${resource.replace("list", "get")}?${queryKey}=${params?.id}`,
        )
      )?.data;

      return {data};
    } catch (e) {
      return Promise.reject(e);
    }
  },

  create: async (resource, params) => {
    try {
      params.data.description = params?.data?.description?.replaceAll(
        /<li><p>(.*?)<\/p><(\/?)(ol|li|ul)>/gi,
        "<li>$1<$2$3>",
      );

      params.data.conditions = params?.data?.conditions?.length
        ? params?.data?.conditions
        : null;

      const data = (
        await axios(`${API_URL}/${resource.replace("list", "create")}`, {
          method: "POST",
          headers: {"Content-Type": "multipart/form-data"},
          data: handleTransformToFormDataV2(params?.data),
        })
      )?.data;

      return {data: {...data, ...params.data}};
    } catch (err) {
      const errBody = err.response.data.detail;
      throw new HttpError("Произошла ошибка", err.response.status, {errors: errBody});
    }
  },
  update: async (resource, params) => {
    try {
      params.data.description = params?.data?.description?.replaceAll(
        /<li><p>(.*?)<\/p><(\/?)(ol|li|ul)>/gi,
        "<li>$1<$2$3>",
      );

      params.data.conditions = params?.data?.conditions?.length
        ? params?.data?.conditions
        : null;

      const queryKey = resource === ROUTES.GROUP_TARIFFS.path ? "tgroup_id" : "tariff_id";

      await axios(
        `${API_URL}/${resource.replace("list", "update")}?${queryKey}=${params?.id}`,
        {
          method: "PATCH",
          headers: {"Content-Type": "multipart/form-data"},
          data: handleTransformToFormDataV2(params?.data),
        },
      );

      return {data: {...params.data}};
    } catch (err) {
      const errBody = err.response.data.detail;
      throw new HttpError("Произошла ошибка", err.response.status, {errors: errBody});
    }
  },

  delete: async (resource = "", params) => {
    return await axios(
      `${API_URL}/${resource.replace("list", "delete")}?tgroup_id=${params?.id}`,
      {
        method: "DELETE",
        headers: {"Content-Type": "multipart/form-data"},
      },
    );
  },
} as DataProvider;
