import {useRecordContext, Confirm, useNotify} from "react-admin";
import {useDisclosure} from "shared/hooks/useDisclosure";
import {blockingRequest, validateError} from "../utils";
import {buttonActions, buttonEntities} from "../constants";
import {Button} from "@mui/material";
import React from "react";

export type ButtonsProps = {
  action: "BLOCK" | "UNBLOCK" | "WARN";
  entity: "USER" | "COMPANY";
  reason: string;
  internalNotes?: string;
  onSuccess: () => void;
  canActionWarn: boolean;
};

export const Buttons = (props: ButtonsProps) => {
  const {action, entity, onSuccess, reason, canActionWarn} = props;
  const record = useRecordContext();
  const {isOpen, onOpen, onClose} = useDisclosure();

  const type = buttonActions[action];
  const entries = buttonEntities[entity];

  const notify = useNotify();

  const disabled = React.useMemo(() => {
    if (!reason) {
      return true;
    }

    switch (action) {
      case "WARN":
        return (
          !canActionWarn || (record.isActive && (action === "WARN" || action === "BLOCK"))
        );
      case "UNBLOCK":
        return !record.isActive;
      case "BLOCK":
        return record.isActive;
      default:
        return false;
    }
  }, [reason, canActionWarn, action, record]);

  return (
    <>
      <Button variant={type.variant} onClick={onOpen} disabled={disabled}>
        {type.description}
      </Button>
      <Confirm
        isOpen={isOpen}
        title={type.description}
        content={`Вы уверены что вы хотите ${type.description.toLowerCase()}
            ${record[entries.name]}?`}
        onConfirm={async () => {
          try {
            const data = await blockingRequest({
              [`${entity.toLowerCase()}Id`]: record.id as string,
              ...props,
            });

            notify(data?.result || "Успешно", {type: "success"});
          } catch (e) {
            const err = e.response.data.detail;
            const message = validateError(err);

            notify(`Произошла ошибка : ${message}`, {type: "error"});
          } finally {
            onSuccess();
            onClose();
          }
        }}
        onClose={onClose}
      />
    </>
  );
};
