import {
  useRecordContext,
  List,
  Datagrid,
  TextField,
  NullableBooleanInput,
  FunctionField,
  TopToolbar,
} from "react-admin";
import {MyUrlField} from "shared/components/MyUrlField/MyUrlField";
import {ROUTES} from "shared/constants/ROUTES";
import {getDataGridStyles} from "shared/styles/dataGridStyles";
import {MyFunctionalActionField} from "./MyFunctionalActionField";
import {format} from "date-fns";
import {PATTERN_DATE_TIME_FULL} from "shared/constants/dates";
import {useSearchParams} from "react-router-dom";
import {Button} from "@mui/material";

const filters = [
  <NullableBooleanInput
    label="Снятые блокировки"
    source="cancelled"
    alwaysOn
    nullLabel="Показать все записи"
    trueLabel="Показать"
    falseLabel="Скрыть"
    sx={{minWidth: "180px", my: 1}}
  />,
];

export const BlocksInsideCompany = () => {
  const record = useRecordContext();
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("userId") || "";

  const resource =
    `${ROUTES.USERS_AND_COMPANY_BLOCKS_LIST.path}/${ROUTES.BLOCKS_OF_COMPANY.path}/?company_id=${record.id}` +
    (userId ? `&user_id=${userId}` : "");

  return (
    <List
      resource={resource}
      hasCreate={false}
      filters={filters}
      filterDefaultValues={{companyId: record.id, userId: userId}}
      sort={{field: "createdAt", order: "DESC"}}
      actions={<CustomListActions />}
    >
      <Datagrid
        bulkActionButtons={false}
        sx={({palette}) => ({
          ...getDataGridStyles(palette),
        })}
      >
        <MyUrlField
          source="user.fio"
          to={(record) => `/${ROUTES.USERS.path}?userId=${record?.user?.id}`}
          label="Нарушитель"
          sortable={false}
          isAlignLeft={true}
        />

        <MyFunctionalActionField
          label="Блокировка"
          actions={{BLOCK: {text: "Блокирован", color: "red"}}}
        />

        <FunctionField
          label="Дата блокировки"
          source="createdAt"
          render={(record) =>
            record?.createdAt ? format(record.createdAt, PATTERN_DATE_TIME_FULL) : null
          }
        />

        <MyUrlField
          source="createdBy.fio"
          to={(record) => `/${ROUTES.USERS.path}?userId=${record?.createdBy?.id}`}
          label="Кто заблокировал"
          sortable={false}
          isAlignLeft={true}
        />

        <TextField
          source="reason"
          label="Причина блокировки"
          sortable={false}
          textAlign="left"
        />

        <FunctionField
          label="Разблокировка"
          source="updatedAt"
          render={(record) => (record?.updatedBy?.id ? "Разблокирован" : null)}
          sx={({palette}) => ({color: palette.green.main})}
          sortable={false}
        />

        <FunctionField
          label="Дата разблокировки"
          source="updatedAt"
          render={(record) =>
            record?.updatedBy?.id
              ? format(record.updatedAt, PATTERN_DATE_TIME_FULL)
              : null
          }
          sortable={false}
        />

        <MyUrlField
          source="updatedBy.fio"
          to={(record) => `/${ROUTES.USERS.path}?userId=${record?.updatedBy?.id}`}
          label="Кто разблокировал"
          sortable={false}
          isAlignLeft={true}
        />
      </Datagrid>
    </List>
  );
};

function CustomListActions() {
  const [searchParams, setSearchParams] = useSearchParams();
  const userId = searchParams.get("userId") || "";
  return (
    <TopToolbar>
      <Button
        variant="dashed"
        disabled={!userId}
        onClick={() => {
          searchParams.delete("userId");
          setSearchParams(searchParams);
        }}
        sx={{maxWidth: "220px"}}
      >
        Убрать фильтр по сотруднику
      </Button>
    </TopToolbar>
  );
}
