import {useRecordContext, useNotify, Confirm} from "react-admin";
import {BSACK} from "shared/constants/operatorKeys";
import {useDisclosure} from "shared/hooks/useDisclosure";
import {buttonActions} from "widgets/BlocksForm/constants";
import {validateError} from "widgets/BlocksForm/utils";
import {blockingOperatorRequest} from "../utils/blockingOperatorRequest";
import {Button} from "@mui/material";

type BlockOperatorButtonsProps = {
  action: "BLOCK" | "UNBLOCK";
  onSuccess: () => void;
  reason: string;
  internalNotes: string;
};

export const BlockOperatorButtons = ({
  action,
  onSuccess,
  reason,
  internalNotes,
}: BlockOperatorButtonsProps) => {
  const record = useRecordContext();
  const {isOpen, onOpen, onClose} = useDisclosure();
  const notify = useNotify();

  const type = buttonActions[action];

  return (
    <>
      <Button
        variant={type.variant}
        onClick={onOpen}
        disabled={!reason || (action === "UNBLOCK" ? !record.isActive : record.isActive)}
      >
        {type.description}
      </Button>
      <Confirm
        isOpen={isOpen}
        title={type.description}
        content={`Вы уверены что вы хотите ${type.description.toLowerCase()}
              ${record.fio}?`}
        onConfirm={async () => {
          try {
            const data = await blockingOperatorRequest({
              action,
              reason,
              internalNotes,
              userId: record.id as string,
              value: BSACK,
            });

            notify(data?.result || "Успешно", {type: "success"});
          } catch (e) {
            const err = e.response.data.detail;
            const message = validateError(err);
            notify(`Произошла ошибка : ${message}`, {type: "error"});
          } finally {
            onSuccess();
            onClose();
          }
        }}
        onClose={onClose}
      />
    </>
  );
};
