import {format} from "date-fns";
import {CustomBooleanField} from "pages/companies/components/CustomBooleanField";
import {MyFunctionalActionField} from "pages/companies/components/MyFunctionalActionField";
import {
  Datagrid,
  FunctionField,
  List,
  SelectInput,
  TextField,
  WrapperField,
} from "react-admin";
import {MyBooleanField} from "shared/components/MyBooleanField/MyBooleanField";
import {MyUrlField} from "shared/components/MyUrlField/MyUrlField";
import {ROUTES} from "shared/constants/ROUTES";
import {PATTERN_DATE_TIME_FULL} from "shared/constants/dates";
import {getDataGridStyles} from "shared/styles/dataGridStyles";

const filters = [
  <SelectInput
    choices={[
      {id: "WARN", name: "Предупреждения"},
      {id: "BLOCK", name: "Блокировка"},
      {id: "UNBLOCK", name: "Разблокировка"},
    ]}
    source={"action"}
    label="Действие"
    alwaysOn
  />,

  <SelectInput
    alwaysOn
    choices={[
      {id: "USER", name: "Пользователь"},
      {id: "COMPANY", name: "Компания"},
    ]}
    label="Субъект"
    source="entity"
  />,
];

export const AllBlocksList = () => {
  return (
    <List
      hasCreate={false}
      sort={{field: "createdAt", order: "DESC"}}
      actions={false}
      filters={filters}
      title="Список всех блокировок"
    >
      <Datagrid
        bulkActionButtons={false}
        sx={({palette}) => ({
          ...getDataGridStyles(palette),
        })}
      >
        <FunctionField
          source="entity"
          label="Субъект"
          render={(record) => (record.entity === "USER" ? "Пользователь" : "Компания")}
        />

        <FunctionField
          label="Дата"
          source="createdAt"
          render={(record) =>
            record?.createdAt ? format(record.createdAt, PATTERN_DATE_TIME_FULL) : null
          }
        />

        <MyUrlField
          source="user.fio"
          to={(record) => `/${ROUTES.USERS.path}?userId=${record?.user?.id}`}
          label="Нарушитель"
          sortable={false}
          isAlignLeft={true}
        />

        <MyUrlField
          source="createdBy.fioShort"
          to={(record) => `/${ROUTES.USERS.path}?userId=${record?.createdBy?.id}`}
          label="Арбитр"
          sortable={false}
          isAlignLeft={true}
        />

        <CustomBooleanField />

        <MyUrlField
          source="company.name"
          label="Компания"
          to={(record) => `/${ROUTES.COMPANY_LIST.path}/${record?.company?.id}/show`}
        />

        <MyBooleanField
          source="user.blockedInCompany"
          label={
            <>
              Блок <br /> компанией
            </>
          }
          sortable={false}
        />

        <WrapperField textAlign="center" label="Действие">
          <MyFunctionalActionField />
        </WrapperField>
        <TextField source="reason" label="Причина" sortable={false} textAlign="left" />
        <TextField
          source="internalNotes"
          label={
            <>
              Внутренний <br /> комментарий
            </>
          }
          sortable={false}
          textAlign="left"
        />
      </Datagrid>
    </List>
  );
};
