import {Create, SimpleForm, TextInput, PasswordInput} from "react-admin";
import {Stack} from "@mui/material";
import {SelectRoles} from "./components/SelectRoles";
import {SaveToolbar} from "shared/components/SaveToolbar/SaveToolbar";
import {AdditionalErrors} from "./components/AdditionalErrors";
import {PhoneInputWrapper} from "./components/PhoneInputWrapper";
import {ALREADY_DISPLAYED_KEYS} from "./constants";

export const OperatorCreate = () => {
  return (
    <Create redirect="list" title="Создание нового оператора">
      <SimpleForm toolbar={<SaveToolbar />} sx={{maxWidth: "1200px"}}>
        <Stack sx={{maxWidth: "1200px", width: "100%", gap: 1}}>
          <TextInput source="fio" label="ФИО" fullWidth autoComplete="new-password" />
          <TextInput source="email" label="Email" autoComplete="new-password" />

          <PasswordInput
            source="password"
            label="Пароль оператора"
            autoComplete="new-password"
          />

          <PhoneInputWrapper />

          <TextInput source="position" label="Должность" />
          <TextInput source="department" label="Отдел" />

          <SelectRoles />

          <PasswordInput
            source="password0"
            label="Введите свой пароль"
            autoComplete="new-password"
          />

          <AdditionalErrors alreadyDisplayedKeys={ALREADY_DISPLAYED_KEYS} />
        </Stack>
      </SimpleForm>
    </Create>
  );
};
