import axios from "axios";
import {DataProvider, HttpError} from "react-admin";
import {API_URL} from "shared/constants/apiURL";
import {defaultProvider} from "./defaultProvider";
import {
  handleTransformToFormData,
  handleTransformToFormDataV2,
} from "shared/utils/handleTransformObjToFD";
import {ROUTES} from "shared/constants/ROUTES";
import {getParams, handleConvertPromoGetListReqBody} from "../utils/helpers";

export const promoProviders = {
  ...defaultProvider(),

  getList: async (resource = "", params) => {
    const data = (
      await axios(`${API_URL}/${resource}`, {
        method: "POST",
        headers: {"Content-Type": "multipart/form-data"},
        data: handleConvertPromoGetListReqBody(params),
      })
    )?.data;
    const total = data?.totalCount;
    return {total, data: data?.items || []};
  },
  create: async (resource, params) => {
    try {
      const data = (
        await axios(`${API_URL}/${ROUTES.PROMO_CREATE.path}`, {
          method: "POST",
          headers: {"Content-Type": "multipart/form-data"},
          data: params?.data,
        })
      )?.data;

      return {data: {...data, ...params.data}};
    } catch (err) {
      const errBody = err.response.data.detail;
      throw new HttpError("Произошла ошибка", err.response.status, {errors: errBody});
    }
  },
} as DataProvider;
