import {BoxProps, Box, useTheme} from "@mui/material";
import {memo} from "react";
import ChatBackgroundImage from "shared/static/img/chat/background.png";
import ChatBackgroundImageDark from "shared/static/img/chat/background_dark.png";

export const ChatBackground = memo(({children, ...props}: BoxProps) => {
  const {palette} = useTheme();
  const {sx, ...other} = props;
  return (
    <Box
      sx={{
        backgroundImage:
          palette.mode === "light"
            ? `url(${ChatBackgroundImage}), ${"linear-gradient(180deg, #B0E1E6 14.06%, #DCE6E8 100%)"}`
            : `url(${ChatBackgroundImageDark})`,
        width: "100%",
        borderLeft: `1px solid ${palette.black500.main}`,
        position: "relative",
        height: "100%",
        flex: 1,
        ...sx,
      }}
      {...other}
    >
      {children}
    </Box>
  );
});
