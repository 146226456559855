import {Slice} from "@reduxjs/toolkit";

interface Options {
  blackList?: string[];
  whiteList?: string[];
}

export const generateActionsForSync = <S extends Slice>(
  slice: S,
  options: Options = {blackList: []},
): string[] => {
  return Object.keys(slice.actions)
    .map((x) => !options.blackList?.includes(x) && `${slice.name}/${x}`)
    .filter(Boolean) as string[];
};
