import {SubtitleSpan} from "../../components/SubtitleSpan/SubtitleSpan";
import {TypoAsValue} from "../../components/TypoAsValue/TypoAsValue";
import {TicketType} from "../TicketsEditProcedures";
import {CustomStack} from "./PaymentAndDelivery";

const Contact = ({ticket}: {ticket: TicketType}) => {
  const {contact_fio, contact_mail, contact_phone} = ticket;

  return (
    <>
      <CustomStack>
        <SubtitleSpan>ФИО</SubtitleSpan>
        <TypoAsValue>{contact_fio}</TypoAsValue>
      </CustomStack>
      <CustomStack>
        <SubtitleSpan>Телефон</SubtitleSpan>
        <TypoAsValue>{contact_phone}</TypoAsValue>
      </CustomStack>

      <CustomStack>
        <SubtitleSpan>Email</SubtitleSpan>
        <TypoAsValue>{contact_mail}</TypoAsValue>
      </CustomStack>
    </>
  );
};

export default Contact;
