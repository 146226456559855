import {Box, Stack, Typography, useTheme} from "@mui/material";
import {useAppSelector} from "shared/hooks/useAppSelector";
import ChatStatus from "./ChatStatus";
import ChatMenu from "./ChatMenu";
import {ChatItemAvatar} from "../../components";

export const ChatCurrentUserHeader = () => {
  const {palette} = useTheme();
  const {currentChat} = useAppSelector((state) => state.chatSlice);

  return (
    <Stack
      sx={{
        width: "100%",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between",
        borderLeft: `1px solid ${palette.black500.main}`,
        padding: "5px 20px",
        // position: "absolute",
      }}
    >
      <Box sx={{display: "flex", flexDirection: "row", alignItems: "center", gap: 2}}>
        {currentChat && (
          // компонент с инфой о пользователе / группе
          <Stack direction="row" alignItems="center" gap={2}>
            <ChatItemAvatar
              chatType={currentChat.type}
              lastUserId={currentChat.users[0]}
            />
            <Stack gap={1}>
              <Typography>{currentChat.chatName}</Typography>
              <ChatStatus />
            </Stack>
          </Stack>
        )}
      </Box>
      {/* меню чата */}
      <ChatMenu />
    </Stack>
  );
};
