export type TicketStatusType =
  | "Черновик"
  | "Открыта"
  | "Рассмотрение"
  | "Доработка"
  | "Одобрена"
  | "Отклонена"
  | "Отозвана";

export const ticketStatuses: TicketStatusType[] = [
  "Черновик",
  "Открыта",
  "Рассмотрение",
  "Доработка",
  "Одобрена",
  "Отклонена",
  "Отозвана",
];

/**
 * @key название статуса
 * @value строка цвета для получения из MUI palette
 *  */
export const ticketsColors: Record<TicketStatusType, string> = {
  Одобрена: "green.main",
  Отклонена: "error.main",
  Отозвана: "warning.main",
  Рассмотрение: "primary.main",
  Доработка: "warning.main",
  Открыта: "primary700.main",
  Черновик: "text.secondary",
};

export const complaintStatuses: TicketStatusType[] = [
  "Открыта",
  "Рассмотрение",
  "Одобрена",
  "Отклонена",
];
