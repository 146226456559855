import {mergeAttributes, Node} from "@tiptap/core";
import {ReactNodeViewRenderer} from "@tiptap/react";
import {List} from "./List";

export default Node.create({
  name: "List",

  group: "block",

  content: "inline*",

  parseHTML() {
    return [
      {
        tag: "List",
      },
    ];
  },

  renderHTML({HTMLAttributes}) {
    return ["List", mergeAttributes(HTMLAttributes), 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(List);
  },
});
