import React from "react";
import {useAdminAuth} from "shared/hooks/adminAuthContext/useAdminAuth";
import {FATHER_ID} from "shared/constants/father";
import {FatherProvider} from "app/providers/fatherProvider/fatherProvider";

export const FatherWrapper = ({
  children,
}: {
  children: (isFather: boolean) => React.ReactNode;
}) => {
  const {user} = useAdminAuth();

  const isFather = user?.id === FATHER_ID;

  return isFather ? (
    <FatherProvider>{children(isFather)}</FatherProvider>
  ) : (
    <>{children(isFather)}</>
  );
};
