import {useAppSelector} from "shared/hooks/useAppSelector";
import {Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {INVITATION_STATE} from "shared/constants/chat";
import {TChat} from "shared/ReduxSlice/chatSlice/types";
import {sxEllipsis} from "shared/styles/sxEllipsis";

export const ChatLastMessage = ({
  lastMessage,
  typingUsers,
  invitationState,
  ownerId,
}: Pick<TChat, "lastMessage" | "typingUsers" | "invitationState" | "ownerId">) => {
  const [message, setMessage] = useState("");
  const {userId} = useAppSelector((state) => state.chatSlice);
  const [isUserTyping, setUserTyping] = useState(false);

  useEffect(() => {
    if (!typingUsers || typingUsers?.startTime + 3000 < Date.now()) return;
    setUserTyping(!!typingUsers?.startTime);
    setTimeout(() => {
      setUserTyping(false);
    }, 3000);
  }, [typingUsers, typingUsers?.startTime]);

  useEffect(() => {
    if (isUserTyping) {
      setMessage("Печатает...");
    } else if (lastMessage.text === "<p></p>" || !lastMessage.text) {
      setMessage("Сообщение с файлом");
    } else if (userId !== ownerId && invitationState === INVITATION_STATE.REQUEST) {
      setMessage("Вам пришло приглашение");
    } else {
      setMessage(lastMessage.text);
    }
  }, [invitationState, isUserTyping, lastMessage.text, ownerId, userId]);

  return (
    <Typography
      variant="h2-regular-14"
      sx={{...sxEllipsis, maxWidth: 310, "& p": {...sxEllipsis}}}
      dangerouslySetInnerHTML={{__html: message.toString()}}
    />
  );
};
