import {UI_PhoneTextField} from "shared/components/UI_PhoneTextField/UI_PhoneTextField";
import {useFormContext, Controller} from "react-hook-form";

export const PhoneInputWrapper = () => {
  const {control} = useFormContext();

  return (
    <Controller
      name="phoneNumber"
      control={control}
      render={({field, fieldState: {invalid, error}}) => (
        <UI_PhoneTextField
          {...field}
          label="Телефон*"
          helperText={error?.message || " "}
          error={invalid}
          fullWidth
          placeholder={"Телефон*"}
        />
      )}
    />
  );
};
