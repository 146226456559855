import {Box, Button, TextField} from "@mui/material";
import React, {useState} from "react";
import {Confirm, useNotify} from "react-admin";
import {Editor} from "@tiptap/react";
import {useDisclosure} from "shared/hooks/useDisclosure";
import {buttonSx} from "shared/components/Tiptap/MenuBar/MenuBar";

export const LinksInToolbar = ({editor}: {editor: Editor}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [urlValue, setUrlValue] = useState("");
  const [urlString, setUrlString] = useState("");
  const notify = useNotify();

  const handleClose = () => {
    setUrlValue("");
    setUrlString("");
    onClose();
  };

  const handleClickAddUrl = () => {
    const selection = editor.state.selection;
    const {from, to} = selection;
    const text = editor.state.doc.textBetween(from, to, "");
    setUrlString(text);

    if (!text) {
      notify("Не выбран текст для ссылки", {type: "error"});
      return;
    }

    onOpen();
  };

  const handleConfirmAddUrl = () => {
    if (!urlValue) return;

    editor
      .chain()
      .focus()
      .extendMarkRange("link")
      .setLink({href: urlValue, target: "_blank"})
      .run();

    handleClose();
  };

  return (
    <>
      <Button
        variant="main"
        onClick={handleClickAddUrl}
        className={editor.isActive("link") ? "is-active" : ""}
        sx={buttonSx}
      >
        Сделать ссылку
      </Button>
      <Button
        variant="main"
        sx={buttonSx}
        onClick={() => editor.chain().focus().unsetLink().run()}
        disabled={!editor.isActive("link")}
      >
        Убрать ссылку
      </Button>

      <Confirm
        title={`Введите URL для - ${urlString}`}
        onClose={handleClose}
        isOpen={isOpen}
        content={
          <Box sx={{maxWidth: "600px", width: "100%", minWidth: "450px"}}>
            <TextField
              value={urlValue}
              onChange={(e) => setUrlValue(e.target.value)}
              label={"URL"}
              sx={{my: 4}}
              fullWidth
            />
          </Box>
        }
        onConfirm={handleConfirmAddUrl}
      />
    </>
  );
};
