import {MouseEvent, useState} from "react";

/**
 * используется для контроля попапов MUI и других элементов
 *  */
export const useAnchor = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return {handleClose, handleClick, open, anchorEl};
};
