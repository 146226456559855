import {Palette, Theme} from "@mui/material";
import {SystemStyleObject} from "@mui/system";

/**
 * Генерация стилей для thead в Datagrid
 */
export const getDataGridStyles = (palette: Palette): SystemStyleObject<Theme> => {
  return {
    thead: {
      backgroundColor: palette.primary.light,

      "tr > th": {
        backgroundColor: `${palette.primary.light} !important`,
        p: "0 5px !important",
        color: "white !important",
        span: {color: "white !important"},
        svg: {color: "white !important"},
      },
    },
  };
};
