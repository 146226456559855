import {
  AutocompleteInput,
  CreateButton,
  Datagrid,
  FunctionField,
  List,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
} from "react-admin";
import Loader from "shared/components/Loader/Loader";
import {MyBooleanField} from "shared/components/MyBooleanField/MyBooleanField";
import {getDataGridStyles} from "shared/styles/dataGridStyles";
import {textAlignLeft} from "shared/styles/textFieldAlignLeft";

const ListActions = () => (
  <TopToolbar>
    <CreateButton variant="main" sx={{px: 2}} />
  </TopToolbar>
);

export const FAQList = () => {
  return (
    <List
      filters={[
        <TextInput source="question" label={"Вопрос"} name={"question"} alwaysOn />,
        <TextInput name="answer" source="answer" label={"Ответ"} alwaysOn />,
        <AutocompleteInput
          source="slug"
          label="Раздел"
          choices={[
            {id: "questions", name: "FAQ"},
            {id: "manuals", name: "Руководство"},
          ]}
          alwaysOn
          sx={{minWidth: 180}}
        />,
      ]}
      actions={<ListActions />}
    >
      <Fields />
    </List>
  );
};

function Fields() {
  const {isLoading = false} = useListContext();

  if (isLoading) return <Loader />;
  return (
    <Datagrid
      rowClick="edit"
      sx={({palette}) => ({
        ...getDataGridStyles(palette),
      })}
    >
      <TextField source="id" textAlign="center" />
      <MyBooleanField source={"status"} label={"Опублик."} textAlign="center" />
      <TextField source="slug" label={"Раздел"} name={"slug"} textAlign="center" />
      <TextField source="question" label={"Вопрос"} textAlign="left" />
      <TextField source="position" label={"Позиция"} textAlign="center" />
      <FunctionField
        render={(record) => (
          <div dangerouslySetInnerHTML={{__html: record?.answer || ""}} />
        )}
        source="answer"
        label={"Ответ"}
        textAlign="left"
      />
    </Datagrid>
  );
}

export default FAQList;
