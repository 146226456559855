import {AlertColor} from "@mui/material";
import {createContext} from "react";

type ToastContextType = {
  addToast: (content: string, severity?: AlertColor, persisted?: boolean) => void;
  addErrorToast: (content: string) => void;
  removeToast: (id: number) => void;
};

export const ToastContext = createContext<ToastContextType | null>(null);
