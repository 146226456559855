import {Stack, FormControlLabel, Checkbox, Button} from "@mui/material";
import {useRecordContext, useNotify, useRefresh, Confirm} from "react-admin";
import {useDisclosure} from "shared/hooks/useDisclosure";
import {markInvoiceAsPaidRequest} from "../services/markInvoiceAsPaidRequest";

export const MarkAsPaidButton = () => {
  const record = useRecordContext();
  const {isOpen, onOpen, onClose} = useDisclosure();
  const notify = useNotify();
  const refresh = useRefresh();

  return (
    <>
      <Button variant={"green"} onClick={onOpen} disabled={record?.isPaid}>
        Пометить оплаченным
      </Button>
      <Confirm
        isOpen={isOpen}
        title={"Пометить платеж оплаченным?"}
        content={
          <Stack gap={2}>
            <span style={{textWrap: "nowrap"}}>
              Вы уверены что вы хотите платеж{" "}
              {`${record?.numPrefix ? record.numPrefix : ""}${
                record?.num ? record.num : ""
              }${record?.numSuffix ? record.numSuffix : ""}`}{" "}
              пометить как оплаченный ?
            </span>
            <span>Все подписки активируются самостоятельно.</span>
            <FormControlLabel
              disabled
              control={<Checkbox defaultChecked />}
              label="Активировать подписки"
              defaultChecked
              value={true}
              sx={{ml: 0}}
            />
          </Stack>
        }
        onConfirm={async () => {
          try {
            await markInvoiceAsPaidRequest({
              invoice_id: record.id + "",
              activate_subscriptions: true,
            });
            refresh();
            notify("Успешно", {type: "success"});
          } catch (e) {
            const err = e.response.data.detail;
            const message = validateError(err);
            notify(`Произошла ошибка : ${message}`, {type: "error"});
          } finally {
            onClose();
          }
        }}
        onClose={onClose}
      />
    </>
  );
};

function validateError(detail: Record<string, string>): string {
  if ("form" in detail) return detail.form;
  if ("invoiceId" in detail) return detail.invoiceId;
  return "";
}
