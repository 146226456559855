import axios from "axios";
import {API_URL} from "shared/constants/apiURL";

type NotifyFunction = (message: string, options: {type: string}) => void;
type RefreshFunction = () => void;
type RecordType = {id: string; service: string};

/**
 * Класс Tickets предоставляет статические методы для управления запросами.
 */
export class Tickets {
  /**
   * Метод для одобрения заявки.
   * @param {RecordType} record - Объект записи, содержащий информацию о заявке.
   * @param {string} data - Комментарий администратора.
   * @param {NotifyFunction} notify - Функция уведомления об успешном выполнении действия (по умолчанию пустая функция).
   * @param {RefreshFunction} refresh - Функция обновления данных (по умолчанию пустая функция).
   */
  static approve = async (
    record: RecordType,
    data: string,
    notify: NotifyFunction = () => null,
    refresh: RefreshFunction = () => null,
  ) => {
    try {
      await axios.post(
        `${API_URL}/${record.service}/admin/tickets/${record.id}/approve`,
        {resolution: data},
        {headers: {"Content-Type": "application/json"}},
      );
      notify("Одобрено", {type: "success"});
      refresh();
    } catch (e) {
      notify("Ошибка запроса", {type: "error"});
    }
  };

  /**
   * Метод для отклонения заявки.
   * @param {RecordType} record - Объект записи, содержащий информацию о заявке.
   * @param {string} data - Комментарий администратора.
   * @param {NotifyFunction} notify - Функция уведомления (по умолчанию пустая функция).
   * @param {RefreshFunction} refresh - Функция обновления данных (по умолчанию пустая функция).
   */
  static reject = async (
    record: RecordType,
    data: string,
    notify: NotifyFunction = () => null,
    refresh: RefreshFunction = () => null,
  ) => {
    try {
      await axios.post(
        `${API_URL}/${record.service}/admin/tickets/${record.id}/reject`,
        {resolution: data},
        {headers: {"Content-Type": "application/json"}},
      );
      notify("Отклонено", {type: "success"});
      refresh();
    } catch (e) {
      notify("Ошибка запроса", {type: "error"});
    }
  };

  /**
   * Метод для передачи заявки на выполнение.
   * @param {RecordType} record - Объект записи, содержащий информацию о заявке.
   * @param {string} data - Комментарий администратора.
   * @param {NotifyFunction} notify - Функция уведомления (по умолчанию пустая функция).
   * @param {RefreshFunction} refresh - Функция обновления данных (по умолчанию пустая функция).
   */
  static takeToWork = async (
    record: RecordType,
    data: string,
    notify: NotifyFunction = () => null,
    refresh: RefreshFunction = () => null,
  ) => {
    try {
      await axios.post(
        `${API_URL}/${record.service}/admin/tickets/${record.id}/take-to-work`,
        {resolution: data},
        {headers: {"Content-Type": "application/json"}},
      );
      notify("В работе", {type: "success"});
      refresh();
    } catch (e) {
      notify("Ошибка запроса", {type: "error"});
    }
  };

  /**
   * Метод для отправки заявки на доработку.
   * @param {RecordType} record - Объект записи, содержащий информацию о заявке.
   * @param {string} data - Комментарий администратора.
   * @param {NotifyFunction} notify - Функция уведомления (по умолчанию пустая функция).
   * @param {RefreshFunction} refresh - Функция обновления данных (по умолчанию пустая функция).
   */
  static sendForRevision = async (
    record: RecordType,
    data: string,
    notify: NotifyFunction = () => null,
    refresh: RefreshFunction = () => null,
  ) => {
    try {
      await axios.post(
        `${API_URL}/${record.service}/admin/tickets/${record.id}/send-for-revision`,
        {resolution: data},
        {headers: {"Content-Type": "application/json"}},
      );
      notify("На доработке", {type: "success"});
      refresh();
    } catch (e) {
      notify("Ошибка запроса", {type: "error"});
    }
  };
}
