import {useEffect} from "react";
import {BcService} from "shared/services/BroadcastChannelService";
import {useAdminAuth} from "../adminAuthContext/useAdminAuth";
import {store} from "shared/store/store";
import {useToast} from "../toastContext/useToast";
import {Auth} from "shared/services/auth";
import {useAppSelector} from "../useAppSelector";
import {useAppDispatch} from "../useAppDispatch";
import {assignChatsState} from "shared/ReduxSlice/chatSlice/chatSlice";

export const useBcHelpers = () => {
  const {user, setUser} = useAdminAuth();
  const {isMainApp, tabsCount} = useAppSelector((state) => state.broadcastChannelSlice);
  const {messageReceiver, handleBcSendMessage} = BcService;
  const {addToast} = useToast();
  const chatSlice = store.getState().chatSlice;
  const dispatch = useAppDispatch();

  const messageHandler = ({data}: MessageEvent) => {
    if (data.user) {
      setUser(data.user);
    } else if (data.expired) {
      Auth.logout();
      setUser(null);
      addToast("Время сессии закончилось", "error", true);
    } else if (data.manuallyLoggedOut) {
      setUser(null);
    } else if (data.chatSlice) {
      dispatch(assignChatsState(data.chatSlice));
    }
    return;
  };

  useEffect(() => {
    messageReceiver.onmessage = (e) => messageHandler(e);

    return () => messageReceiver.removeEventListener("message", messageHandler);
  }, []);

  useEffect(() => {
    if (!isMainApp || !user) return;

    const {currentChat, GetMessages, ...rest} = chatSlice;
    handleBcSendMessage({chatSlice: rest});
  }, [tabsCount]);
};
