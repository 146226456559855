import {Tooltip, InputAdornment, Stack, Box, Typography, Collapse} from "@mui/material";
import {
  DateField,
  EmailField,
  List,
  TextField,
  useListContext,
  DatagridConfigurable,
  SelectColumnsButton,
  TopToolbar,
  TextInput,
  ExportButton,
  CheckboxGroupInput,
  SimpleShowLayout,
  ImageField,
  Labeled,
  minLength,
  FunctionField,
  useRecordContext,
  useGetOne,
  Datagrid,
  WrapperField,
} from "react-admin";
import Loader from "shared/components/Loader/Loader";
import {getDataGridStyles} from "shared/styles/dataGridStyles";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import {MyUrlField} from "shared/components/MyUrlField/MyUrlField";
import {ROUTES} from "shared/constants/ROUTES";
import {useSearchParams} from "react-router-dom";
import React from "react";
import {BlocksForm} from "widgets/BlocksForm/BlocksForm";
import {MyBooleanField} from "shared/components/MyBooleanField/MyBooleanField";
import {OPF_RECORD} from "shared/constants/OPFRecord";
import {ROLE_KEY} from "shared/constants/roles";
import {PATTERN_DATE_TIME_FULL} from "shared/constants/dates";
import {format} from "date-fns";
import {MyFunctionalActionField} from "../companies/components/MyFunctionalActionField";
import {ExpandLessOutlined, ExpandMoreOutlined} from "@mui/icons-material";
import {useDisclosure} from "shared/hooks/useDisclosure";

const filters = [
  <TextInput
    source="search"
    resettable
    label={"Поиск"}
    alwaysOn
    validate={minLength(3)}
    InputProps={{
      endAdornment: (
        <InputAdornment position={"end"}>
          <Tooltip
            title={
              <>
                Поиск осуществляется при длине больше 3ех символов.
                <br />
                По частичному совпадению: ФИО, телефон, email, название компании.
                <br />
                По полному совпадению: ID пользователя, ИНН компании, КПП компании,
                userId, companyId.
              </>
            }
          >
            <LiveHelpIcon sx={{cursor: "help"}} />
          </Tooltip>
        </InputAdornment>
      ),
    }}
  />,

  <CheckboxGroupInput
    source="hide"
    choices={[
      {id: "active", name: "Блок площадкой"},
      {
        id: "blockedInCompany",
        name: "Блок компанией",
      },
      {
        id: "dismissed",
        name: "Уволен",
      },
      {
        id: "sortBlockedLast",
        name: "Заблокированных в конец списка",
      },
    ]}
    alwaysOn
    label=""
    sx={{ml: 4, display: "flex", flexWrap: "wrap"}}
  />,
];

const PostListActions = () => (
  <TopToolbar>
    <SelectColumnsButton />
  </TopToolbar>
);

export const UserList = ({
  companyId,
  isEmployeeList = false,
}: {
  companyId?: string;
  isEmployeeList?: boolean;
}) => {
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("userId") || "";

  const params = React.useMemo(() => {
    const filters = {companyId, userId};
    const filteredFilters = Object.fromEntries(
      Object.entries(filters).filter(([key, value]) => !!value),
    );
    let resource = ROUTES.USERS.path;

    if (Object.keys(filteredFilters).length) {
      const queryParams = Object.entries(filteredFilters)
        .map(([key, value]) => `${key}=${value}`)
        .join("&");
      resource += `?${queryParams}`;
    }
    return {filters: filteredFilters, resource};
  }, [companyId, userId]);

  return (
    <List
      filters={filters}
      sort={{field: "createdAt", order: "DESC"}}
      actions={<PostListActions />}
      resource={params.resource}
      filterDefaultValues={params.filters}
    >
      <Fields isEmployeeList={isEmployeeList} />
    </List>
  );
};

function Fields({isEmployeeList}) {
  const {isLoading = false} = useListContext();

  if (isLoading) return <Loader />;

  return (
    <DatagridConfigurable
      sx={({palette}) => ({
        ...getDataGridStyles(palette),
      })}
      bulkActionButtons={false}
      expand={<RowUserDetail />}
      expandSingle
      omit={[
        "department",
        "position",
        "role",
        "company.opf",
        "company-inn",
        "company.srm_access",
        "updatedAt",
      ]}
    >
      <TextField source="hid" label="ID" textAlign="center" />
      <TextField source="fio" label="ФИО" textAlign="left" />
      <TextField source="department" label="Отдел" textAlign="left" />
      <TextField source="position" label="Должность" textAlign="left" />
      <TextField source="role" label="Роль" sortable={false} textAlign="center" />
      <EmailField source="email" sx={{textDecoration: "none"}} textAlign="left" />
      <TextField source="phoneNumber" label="Телефон" textAlign="center" />
      <DateField source="createdAt" label="Регистрация" textAlign="center" />
      <DateField source="updatedAt" label="Обновлен" textAlign="center" />

      <FunctionField
        source={"company.opf"}
        label={"ОПФ"}
        render={(record) =>
          OPF_RECORD[record?.company?.opf]
            ? OPF_RECORD[record?.company?.opf]
            : record?.company?.opf
        }
        textAlign="center"
      />

      <WrapperField label="Название компании">
        <MyUrlField
          source="company.name"
          to={(record) => `/${ROUTES.COMPANY_LIST.path}/${record?.company?.id}/show`}
          label={
            <>
              Название <br /> компании
            </>
          }
          isAlignLeft={true}
        />
      </WrapperField>

      <WrapperField label="ИНН Компании">
        <TextField
          source="company.inn"
          label={
            <>
              ИНН <br /> Компании
            </>
          }
          textAlign="center"
        />
      </WrapperField>

      <WrapperField label="Доступ в SRM">
        <MyBooleanField
          source="company.srm_access"
          label={
            <>
              Доступ <br /> в SRM
            </>
          }
          textAlign="center"
        />
      </WrapperField>

      {/* Блокирован на ур-не ЭТП - обратное */}
      <WrapperField label="Блок площадкой">
        <MyBooleanField
          source="isActive"
          label={
            <>
              Блок <br /> площадкой
            </>
          }
          sortable={false}
          textAlign="center"
        />
      </WrapperField>

      <WrapperField label="Блок компанией">
        {isEmployeeList ? (
          <MyUrlField
            source="blockedInCompany"
            label={
              <>
                Блок <br /> компанией
              </>
            }
            sortable={false}
            to={(record) =>
              `/${ROUTES.COMPANY_LIST.path}/${record.company.id}/show/4?userId=${record.id}`
            }
            isValueBoolean={true}
          />
        ) : (
          <MyBooleanField
            source="blockedInCompany"
            label={
              <>
                Блок <br /> компанией
              </>
            }
            sortable={false}
            textAlign="center"
          />
        )}
      </WrapperField>

      <MyBooleanField
        source="isDismissed"
        label="Уволен"
        sortable={false}
        textAlign="center"
      />
    </DatagridConfigurable>
  );
}

function RowUserDetail() {
  const list = useListContext();
  const resource = list.resource;
  const record = useRecordContext();

  const {data: expandedUserData} = useGetOne(
    resource + `userId=${record.id}&violations&roles`,
    {
      id: record.id,
      meta: record,
    },
  );

  const {isOpen: showBlockByETP, onToggle: toggleShowByETP} = useDisclosure();
  const {isOpen: showBlockByCompanies, onToggle: toggleShowByCompanies} = useDisclosure();

  return (
    <>
      <SimpleShowLayout
        sx={{
          pl: 8,
          py: 4,
          div: {
            alignItems: "flex-start",
            span: {display: "flex", alignItems: "flex-start"},
          },
        }}
      >
        <TextField source="hid" label="ID" />
        <TextField source="fio" label="ФИО" />

        <FunctionField
          source="role"
          label="Доступ в админку"
          render={(record) => (record?.role === "Администратор" ? "Да" : "Нет")}
        />

        <EmailField source="email" />
        <TextField source="phoneNumber" label="Номер телефона" />

        <MyUrlField
          source="company.name"
          to={(record) => `/${ROUTES.COMPANY_LIST.path}/${record?.company?.id}/show`}
          label="Название компании"
        />

        <Stack direction={"row"} spacing={3}>
          <Labeled label="Должность в компании">
            <TextField source="position" />
          </Labeled>
          <Labeled label="Отдел компании">
            <TextField source="department" />
          </Labeled>

          <Labeled label="Блок компанией">
            <MyBooleanField source="blockedInCompany" textAlign="center" />
          </Labeled>
        </Stack>
        <Stack direction={"row"} spacing={3}>
          <Labeled label="Id компании">
            <TextField source="companyId" />
          </Labeled>
          {/* <Labeled label="Короткое название компании">
          <TextField source="company.shortName"  />
        </Labeled>
        <Labeled label="readableName компании">
          <TextField source="company.readableName"  />
        </Labeled> */}
          <Labeled label="ОПФ компании">
            <TextField source="company.opf" />
          </Labeled>
          <Labeled label="ИНН компании">
            <TextField source="company.inn" />
          </Labeled>
          <Labeled label="КПП компании">
            <TextField source="company.kpp" />
          </Labeled>
        </Stack>
        <Stack direction={"row"} spacing={3}>
          <Labeled label="Заказчик">
            <MyBooleanField source="company.customer" />
          </Labeled>

          <Labeled label="Производитель">
            <MyBooleanField source="company.supplier" />
          </Labeled>

          <Labeled label="Доступ в SRM">
            <MyBooleanField source="company.srmAccess" />
          </Labeled>

          <Labeled label="Компания подтверждена">
            <MyBooleanField source="company.isVerified" />
          </Labeled>
        </Stack>

        <ImageField source="company.logo.thumbUrl" label="Логотип компании" />

        <MyBooleanField source="isActive" label="Блок площадкой" />
        <MyBooleanField source="isDismissed" label="Уволен" />

        <DateField source="createdAt" label="Дата регистрации" />
        <DateField source="updatedAt" label="Дата обновления профиля" />
        {Object.keys(expandedUserData?.roles || {})?.length ? (
          <Stack sx={{alignItems: "flex-start", gap: 2}}>
            {Object.keys(expandedUserData.roles).map((key) => {
              const values = Array.isArray(expandedUserData.roles[key])
                ? expandedUserData.roles[key]
                : [expandedUserData.roles[key]];
              const valuesAsString = values?.map((obj) => obj?.i10n)?.join(", ") || "Нет";
              return (
                <Labeled key={`${key}`} label={ROLE_KEY[key]} sx={{textAlign: "left"}}>
                  <Box>{valuesAsString}</Box>
                </Labeled>
              );
            })}
          </Stack>
        ) : (
          <Typography variant="15px">У пользователя не назначены роли</Typography>
        )}
      </SimpleShowLayout>
      <Stack sx={{px: 8, gap: 4}}>
        <Stack sx={{alignItems: "flex-start"}}>
          <Box
            onClick={toggleShowByETP}
            sx={({palette}) => ({
              cursor: "pointer",
              color: palette.primary.main,
              display: "flex",
              alignItems: "center",
            })}
          >
            <Typography variant="16px">Нарушения на площадке</Typography>
            {showBlockByETP ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
          </Box>

          <Collapse in={showBlockByETP}>
            <UserBlocksByETP data={expandedUserData?.etpBlocks} />
          </Collapse>
        </Stack>

        <Stack sx={{alignItems: "flex-start", pb: 4}}>
          <Box
            onClick={toggleShowByCompanies}
            sx={({palette}) => ({
              cursor: "pointer",
              color: palette.primary.main,
              display: "flex",
              alignItems: "center",
            })}
          >
            <Typography variant="16px">Блокировки компаниями</Typography>
            {showBlockByCompanies ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
          </Box>
          <Collapse in={showBlockByCompanies}>
            <UserBlocksByCompanies data={expandedUserData?.companiesBlock} />
          </Collapse>
        </Stack>
      </Stack>
      <BlocksFormWrapper />
    </>
  );
}

function BlocksFormWrapper() {
  const record = useRecordContext();

  return record?.role !== "Администратор" ? (
    <Box sx={{pl: 8}}>
      <BlocksForm entity="USER" actions={["WARN", "BLOCK", "UNBLOCK"]} />
    </Box>
  ) : null;
}

function UserBlocksByETP({data}) {
  return (
    <Datagrid
      bulkActionButtons={false}
      sx={({palette}) => ({
        ...getDataGridStyles(palette),
      })}
      data={data?.items || []}
      total={data?.totalCount || 0}
      empty={<Box>Нет блокировок</Box>}
    >
      <FunctionField
        source="createdAt"
        label="Дата"
        render={(record) =>
          record?.createdAt ? format(record.createdAt, PATTERN_DATE_TIME_FULL) : null
        }
        sortable={false}
      />
      <MyFunctionalActionField label="Действие" />
      <MyUrlField
        source="createdBy.fioShort"
        to={(record) => `/${ROUTES.USERS.path}?userId=${record?.createdBy?.id}`}
        label="Арбитр"
        sortable={false}
        isAlignLeft={true}
      />
      <TextField source="reason" label="Причина" sortable={false} textAlign="left" />
      <TextField
        source="internalNotes"
        label="Внутренний комментарий"
        sortable={false}
        textAlign="left"
      />
    </Datagrid>
  );
}

function UserBlocksByCompanies({data}) {
  return (
    <Datagrid
      bulkActionButtons={false}
      sx={({palette}) => ({
        ...getDataGridStyles(palette),
      })}
      data={data?.items || []}
      total={data?.totalCount || 0}
      empty={<Box>Нет блокировок</Box>}
    >
      <MyFunctionalActionField
        label="Блокировка"
        actions={{BLOCK: {text: "Блокирован", color: "red"}}}
      />

      <FunctionField
        label="Дата блокировки"
        source="createdAt"
        render={(record) =>
          record?.createdAt ? format(record.createdAt, PATTERN_DATE_TIME_FULL) : null
        }
        sortable={false}
      />

      <MyUrlField
        source="createdBy.fio"
        to={(record) => `/${ROUTES.USERS.path}?userId=${record?.createdBy?.id}`}
        label="Кто заблокировал"
        sortable={false}
        isAlignLeft={true}
      />

      <MyUrlField
        source="company.name"
        label="Компания"
        to={(record) => `/${ROUTES.COMPANY_LIST.path}/${record?.company?.id}/show`}
        sortable={false}
      />

      <TextField
        source="reason"
        label="Причина блокировки"
        sortable={false}
        textAlign="left"
      />

      <FunctionField
        label="Разблокировка"
        source="updatedAt"
        render={(record) => (record?.updatedBy?.id ? "Разблокирован" : null)}
        sx={({palette}) => ({color: palette.green.main})}
        sortable={false}
      />

      <FunctionField
        label="Дата разблокировки"
        source="updatedAt"
        render={(record) =>
          record?.updatedBy?.id ? format(record.updatedAt, PATTERN_DATE_TIME_FULL) : null
        }
        sortable={false}
      />

      <MyUrlField
        source="updatedBy.fio"
        to={(record) => `/${ROUTES.USERS.path}?userId=${record?.updatedBy?.id}`}
        label="Кто разблокировал"
        sortable={false}
        isAlignLeft={true}
      />
    </Datagrid>
  );
}
