export const fileNames = {
  identityDocumentsFile: "Копии документов удостоверяющих личность",
  identity_documents_file: "Копии документов удостоверяющих личность",

  certificateOfRegistrationFile: "Свидетельство о регистрации",
  certificate_of_registration_file: "Свидетельство о регистрации",
  sertificateOfRegistrationFile: "Свидетельство о регистрации",
  sertificate_of_registration_file: "Свидетельство о регистрации",

  certificateFile: "Свидетельство о постановке на учет",
  certificate_file: "Свидетельство о постановке на учет",
  sertificateFile: "Свидетельство о постановке на учет",
  sertificate_file: "Свидетельство о постановке на учет",

  chartFile: "Устав",
  chart_file: "Устав",

  egrulFile: "Выписка из ЕГРЮЛ (за последние 3 месяца)",
  egrul_file: "Выписка из ЕГРЮЛ (за последние 3 месяца)",

  orderAppointDirectorFile: "Приказ о назначении директора",
  order_appoint_director_file: "Приказ о назначении директора",

  registrationRefFile: "Справка о постановке на учет по КНД 1122035",
  registration_ref_file: "Справка о постановке на учет по КНД 1122035",

  passportFile: "Копия паспорта",
  passport_file: "Копия паспорта",

  egripFile: "Выписка из ЕГРИП",
  egrip_file: "Выписка из ЕГРИП",

  innFile: "Свидетельство ИНН",
  inn_file: "Свидетельство ИНН",

  producerFile: "Подтверждение производителя",
  producer_file: "Подтверждение производителя",

  proxyFile: "Доверенность",
  proxy_file: "Доверенность",

  ecpFile: "Файл ЭЦП",
  ecp_file: "Файл ЭЦП",

  statisticsRefFile: "Коды Статистики",
  statistics_ref_file: "Коды Статистики",

  contractScanFile: "Контракт",
};
