import {useForm} from "react-hook-form";
import {TextField, Button, Box, FormHelperText} from "@mui/material";
import {Auth} from "shared/services/auth";
import {useAdminAuth} from "shared/hooks/adminAuthContext/useAdminAuth";
import {useState} from "react";
import {LoginFormData} from "shared/types/UserTypes";
import {BcService} from "shared/services/BroadcastChannelService";

export const LoginForm = () => {
  const {setUser} = useAdminAuth();
  const {register, handleSubmit} = useForm<LoginFormData>();
  const [errorMsg, setErrorMsg] = useState("");

  const onSubmit = async (data: LoginFormData) => {
    const error = await Auth.login(data);

    if (error) {
      setErrorMsg(error);
      return;
    }

    const userInfo = await Auth.getUserInfo();
    setUser(userInfo);
    BcService.handleBcSendMessage({user: userInfo});
    setErrorMsg("");
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "300px",
          width: "100%",
        }}
      >
        <TextField
          {...register("username")}
          label="Username"
          type="username"
          required
          sx={{mb: 3}}
        />

        <TextField
          {...register("password")}
          label="Password"
          type="password"
          required
          sx={{mb: 1}}
        />
        <FormHelperText error sx={{mb: 1}}>
          {errorMsg || " "}
        </FormHelperText>
        <Button type="submit" variant="main">
          Login
        </Button>
      </form>
    </Box>
  );
};
