import {PayloadAction, createSlice} from "@reduxjs/toolkit";
import {initialState} from "./broadcast.types";
import {generateActionsForSync} from "shared/utils/generateActionsForSync";
import {resetState} from "shared/store/resetState";

export const broadcastChannelSlice = createSlice({
  name: "broadcastChannelSlice",
  initialState,
  reducers: {
    ...resetState,
    setIsMainTabApp: (state, {payload}: PayloadAction<boolean>) => {
      state.isMainApp = payload;
    },
    setTabNumber: (state, {payload}: PayloadAction<number>) => {
      state.tabNumber = payload;
    },
    setTabsCount: (state, {payload}: PayloadAction<number>) => {
      state.tabsCount = payload;
    },
    onLogout: () => {
      console.log("broadcastChannelSlice:&&&&&& onLogout &&&&&&");
    },
    on401: () => {
      console.log("broadcastChannelSlice:&&&&&& on 401  &&&&&&");
    },
    on406: () => {
      console.log("broadcastChannelSlice:&&&&&& on 406 logout &&&&&&");
    },
  },
});

export const {setIsMainTabApp, setTabNumber, onLogout, setTabsCount, on401, on406} =
  broadcastChannelSlice.actions;

export const bcSliceActions = generateActionsForSync(broadcastChannelSlice, {
  blackList: ["setIsMainTabApp", "setTabNumber", "setTabsCount"],
  whiteList: ["onLogout", "on401"],
});

export default broadcastChannelSlice.reducer;
