export const buttonActions = {
  BLOCK: {
    variant: "block",
    description: "Заблокировать",
  },
  UNBLOCK: {
    variant: "green",
    description: "Разблокировать",
  },
  WARN: {
    variant: "reject",
    description: "Выписать предупреждение",
  },
} as const;

export const buttonEntities = {
  USER: {name: "fio"},
  COMPANY: {name: "name"},
} as const;
