import {CircularProgress, Box} from "@mui/material";

const Loader = ({size = 50}: {size?: number}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "calc(1vw + 10px)",
      }}
    >
      <CircularProgress size={size} />
    </Box>
  );
};

export default Loader;
