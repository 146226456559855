import {
  TypedAddListener,
  TypedStartListening,
  addListener,
  createListenerMiddleware,
} from "@reduxjs/toolkit";
import {AppDispatch} from "shared/hooks/useAppDispatch";
import {RootState} from "../store";

export type AppStartListening = TypedStartListening<RootState, AppDispatch>;

export const listenerMiddleware = createListenerMiddleware();
export const startAppListening = listenerMiddleware.startListening as AppStartListening;

export const addAppListener = addListener as TypedAddListener<RootState, AppDispatch>;
