import {Palette, Theme} from "@mui/material";
import {SystemStyleObject} from "@mui/system";

export const renderScrollStyle = (palette: Palette): SystemStyleObject<Theme> => ({
  scrollbarColor: "auto",
  "::-webkit-scrollbar": {
    width: "2px",
    borderRadius: "8px",
    backgroundColor: palette.black200.main,
  },

  "::-webkit-scrollbar-track": {
    backgroundColor: palette.black200.main,
  },
  "::-webkit-scrollbar-thumb": {
    borderRadius: "8px",
    backgroundColor: palette.black500.main,
    opacity: 0.4,
    cursor: "grab",
  },
});
