/**
 * Функция преобразования объекта в FormData
 * C использованием `${value}`
 *  */
export const handleTransformToFormData = (object: Record<string, unknown>) => {
  return Object.entries(object).reduce((formData, [key, value]) => {
    formData.append(key, `${value}`);
    return formData;
  }, new FormData());
};

/**
 * Функция преобразования объекта в FormData
 * C использованием JSON.stringify
 *  */
export const handleTransformToFormDataV2 = (object: Record<string, unknown>) => {
  return Object.entries(object).reduce((formData, [key, value]) => {
    typeof value === "object"
      ? formData.append(key, JSON.stringify(value))
      : formData.append(key, `${value}`);

    return formData;
  }, new FormData());
};
