import {MutableRefObject, useEffect, useState} from "react";

const useInView = <T extends Element>(
  target: MutableRefObject<T | null>,
  triggerOnce = false,
) => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [observer, setObserver] = useState<IntersectionObserver | null>(null);

  useEffect(() => {
    const callback = (entries) => {
      setIsIntersecting(entries[0].isIntersecting);
    };

    observer?.disconnect();

    if (target.current) {
      const _observer = new IntersectionObserver(callback, {
        threshold: 1,
        rootMargin: "0px",
      });
      _observer.observe(target.current);
      setObserver(_observer);
    }

    return () => {
      observer?.disconnect();
    };
  }, [target.current]);

  useEffect(() => {
    triggerOnce &&
      isIntersecting &&
      observer &&
      target.current &&
      observer.unobserve(target.current);
  }, [isIntersecting]);

  return isIntersecting;
};

export default useInView;
