import {Box, Stack, useTheme} from "@mui/material";
import {format, getDayOfYear, parseISO} from "date-fns";
import {TChatMessage} from "shared/ReduxSlice/chatSlice/types";
import {PATTERN_DATE_SHORT} from "shared/constants/dates";

export const BorderDay = ({
  messages,
  index,
}: {
  messages: TChatMessage[];
  index: number;
}) => {
  const currentDate = getDayOfYear(parseISO(messages[index]?.createdAt));
  const nextDate = getDayOfYear(
    parseISO(messages[index - 1]?.createdAt || new Date().toISOString()),
  );
  const isDiff = currentDate - nextDate;
  const {palette} = useTheme();

  return !!isDiff || !index ? (
    <Stack
      sx={{
        // при наложении нескольких дат они сливаются
        // решение - объединить сообщения по дате
        // position: "sticky",
        top: 20,
        width: "100%",
        zIndex: 2,
        alignItems: "center",
        margin: "20px 0",
        // opacity: isIntersecting ? 0 : 1,
      }}
    >
      <Box
        sx={{
          color: palette.white.main,
          fontWeight: 600,
          bgcolor: palette.black400.main,
          padding: "3px 15px",
          borderRadius: 1,
        }}
      >
        {format(new Date(messages[index]?.createdAt), PATTERN_DATE_SHORT)}
      </Box>
    </Stack>
  ) : (
    <></>
  );
};
