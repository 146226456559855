import {Components, Theme} from "@mui/material/styles";

export const muiButtonBase: Components<Theme>["MuiButtonBase"] = {
  styleOverrides: {
    root: ({theme}) => ({
      "& legend": {
        display: "none",
      },
      "& fieldset": {
        top: 0,
        borderColor: theme.palette.black400.main,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.main,
      },
    }),
  },
};
