import React from "react";
import {SimpleForm, BooleanInput, useRecordContext, Edit} from "react-admin";
import {ArticleFields} from "./components/ArticleFields";
import {VideoFields} from "./components/VideoFields";
import {SaveWithDeleteToolbar} from "shared/components/SaveWithDeleteToolbar/SaveWithDeleteToolbar";

export const BlogEdit = () => {
  return (
    <>
      <Edit title="Создание блога" mutationMode={"pessimistic"} redirect="list">
        <SimpleForm toolbar={<SaveWithDeleteToolbar />} sx={{maxWidth: "1200px"}}>
          <BooleanInput source="status" label={"Опубликовать"} name={"status"} />
          <Content />
        </SimpleForm>
      </Edit>
    </>
  );
};

function Content() {
  const record = useRecordContext();
  return record?.type === "card" ? <ArticleFields /> : <VideoFields />;
}
