import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {Product} from "./Product";
import {separateValueForMoney} from "shared/utils/separateValueForMoney";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const colsWidth = ["80px", "auto", "100px", "150px", "150px", "150px"];
const colsNames = ["№", "Наименование", "Ед.", "Кол.", "Цена позиции", "Возможен аналог"];

export const LotCommercial = ({lot, lotIndex}) => {
  return (
    <Accordion disableGutters>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={({palette}) => ({backgroundColor: palette.background.default})}
      >
        <Stack gap={7} direction={"row"}>
          <Typography fontWeight={400}>Лот №{lotIndex + 1} </Typography>
          <Typography>НМЦ лота: {separateValueForMoney(lot.price_nds)} </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{p: 0}}>
        <Table>
          <colgroup>
            {colsWidth.map((width, i) => (
              <col key={i} width={width} />
            ))}
          </colgroup>
          <TableHead>
            <TableRow
              sx={({palette}) => ({
                backgroundColor: palette.primary.light,
                color: "white",
              })}
            >
              {colsNames.map((name, i) => (
                <TableCell key={i} sx={{color: "white"}}>
                  {name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(lot?.products || []).map((product, index) => (
              <Product
                key={index}
                product={product as any}
                index={index}
                lotIndex={lotIndex}
              />
            ))}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  );
};
