import {Box, CircularProgress, IconButton} from "@mui/material";
import {ChatBubbleOutlineOutlined, MessageOutlined} from "@mui/icons-material";

interface IChatSendButtonProps {
  value: string;
  handleSubmit: () => void;
  isLoading?: boolean;
}

export const ChatSendButton = ({
  value,
  handleSubmit,
  isLoading = false,
}: IChatSendButtonProps) => {
  if (isLoading) {
    return (
      <Box sx={{width: 40, height: 40}}>
        <CircularProgress sx={{fontSize: "small"}} />
      </Box>
    );
  }

  return (
    <IconButton
      sx={{
        height: 47,
        width: 40,
        justifyContent: "center",
        alignItems: "center",
        transition: "all 0.3s ease",
      }}
      disabled={!value}
      onClick={handleSubmit}
    >
      {!value ? (
        <ChatBubbleOutlineOutlined />
      ) : (
        <MessageOutlined sx={{color: ({palette}) => palette.primary.main}} />
      )}
    </IconButton>
  );
};
