import {MouseEvent, memo, useMemo, useState} from "react";
import {Stack, Typography, useTheme} from "@mui/material";

import {useAppSelector} from "shared/hooks/useAppSelector";
import {useAppDispatch} from "shared/hooks/useAppDispatch";
import {getLastDate} from "shared/utils/getLastDate/getLastDate";
import {
  resetChatMessages,
  setCurrentChat,
  setCurrentChatMenu,
} from "shared/ReduxSlice/chatSlice/chatSlice";
import {TChat, TChatMenuType, TChatType} from "shared/ReduxSlice/chatSlice/types";
import {chatUtils} from "shared/utils/chatUtils/chatUtils";
import {ChatItemAvatar} from "../../../../../components";
import {ChatLastMessage} from "./components/ChatLastMessage";
import {ChatUnreadMessages} from "./components/ChatUnreadMessages";
import {ChatItemContextMenu} from "./components/ChatItemContextMenu";
import {GetMessagesApiArg} from "typesFromApi/types/chatApi";
import {ChatEvents} from "shared/utils/chatUtils/ChatEvents";

export const ChatListItem = memo(({chat, type}: {chat: TChat; type: TChatMenuType}) => {
  const {palette} = useTheme();
  const dispatch = useAppDispatch();
  const {currentChat} = useAppSelector((state) => state.chatSlice);
  const [contextMenu, setContextMenu] = useState<{mouseX: number; mouseY: number} | null>(
    null,
  );

  const companyName = useMemo(() => {
    const currUser = chatUtils.getChatUserById({userID: chat?.users[0], type});
    if (currUser) return currUser.company.name;
    return " ";
  }, [type, chat]);

  function chatSelect() {
    dispatch(setCurrentChat(chat));
    dispatch(setCurrentChatMenu("MESSAGES_LIST"));
    dispatch(resetChatMessages(chat?.id));

    const reqBody: GetMessagesApiArg["httpChatGetMessagesDto"]["message"] = {
      chatId: chat?.id,
      limit: 20,
      startOrder: "before",
      start: null,
      middle: chat.lastMessage.id,
    };

    chatUtils.bsSend(reqBody, ChatEvents.ChatService.GetMessages);
  }

  const handleContextMenu = (event: MouseEvent) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {mouseX: event.clientX + 2, mouseY: event.clientY - 6}
        : null,
    );
  };
  return (
    <div onContextMenu={handleContextMenu} key={chat.id}>
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          backgroundColor:
            chat.id === currentChat?.id ? palette.black400.main : "transparent",
          borderBottom: `1px solid ${palette.black300.main}`,
          padding: 4,
          gap: "10px",
          transition: "all 0.3s ease",
          cursor: "pointer",
          ":hover": {
            backgroundColor:
              palette.mode === "light" ? palette.black200.main : palette.black500.main,
          },
          width: "100%",
        }}
        onClick={chatSelect}
      >
        <ChatItemAvatar chatType={chat.type as TChatType} lastUserId={chat.users[0]} />
        <Stack sx={{flex: 1, maxWidth: "340px", width: "100%"}}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap={"10px"}
          >
            <Typography
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                flex: 1,
                fontWeight: 600,
              }}
              variant="h2-medium-14"
            >
              {chat.chatName}
            </Typography>
            <Typography
              variant="h2-regular-14"
              sx={{
                flex: 1,
                maxWidth: "80px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {getLastDate(chat.lastMessage.createdAt)}
            </Typography>
          </Stack>

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap={"10px"}
          >
            <Stack gap={1}>
              <Typography
                variant="h2-regular-14"
                sx={{
                  color:
                    palette.mode === "light"
                      ? palette.text.secondary
                      : palette.black300.main,
                }}
              >
                {companyName}
              </Typography>
              <ChatLastMessage {...chat} />
            </Stack>

            <Stack justifyContent="center" alignItems="center">
              <ChatUnreadMessages
                lastMessage={chat.lastMessage}
                unreadMessagesCnt={chat.unreadMessagesCnt}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <ChatItemContextMenu
        chat={chat}
        contextMenu={contextMenu}
        handleClose={() => setContextMenu(null)}
      />
    </div>
  );
});
