interface DurationRecord {
  years?: number | null;
  months?: number | null;
  days?: number | null;
}

export const getDurationFromDMY = (record: DurationRecord) => {
  const years = record?.years;
  const days = record?.days;
  const months = record?.months;
  const string =
    (years ? `${years} Лет` : "") +
    " " +
    (months ? `${months} Месяцев ` : "") +
    " " +
    (days ? `${days} Дней` : "");

  return string;
};
