import axios from "axios";
import React, {useEffect, useState} from "react";
import {API_URL} from "shared/constants/apiURL";
import {FatherContext} from "shared/hooks/fatherContext/fatherContext";
import {AvailableFatherRolesType} from "shared/types/FatherContext";

export const FatherProvider = ({children}: {children: React.ReactNode}) => {
  const [availableRoles, setRoles] = useState<AvailableFatherRolesType[] | null>(null);

  useEffect(() => {
    axios.post(`${API_URL}/users/v2/r/or`, {}).then((res) => {
      setRoles(res?.data || []);
    });
  }, []);

  return (
    <FatherContext.Provider value={{availableRoles, setRoles}}>
      {children}
    </FatherContext.Provider>
  );
};
