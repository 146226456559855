import {format, getDayOfYear} from "date-fns";

/**
 * @param {String} date
 * @param {{
 * today?: Boolean,
 * dateWithTime?: Boolean,
 * todayWithTime? : Boolean
 * }} options
 * @return {string}
 */
export function getLastDate(
  date = new Date().toISOString(),
  options = {
    today: false,
    dateWithTime: false,
    todayWithTime: false,
  },
)
{
  if (!date || !Date.parse(date))
  {
    return "";
  }
  const _date = new Date();
  const dayOfYear = getDayOfYear(_date);
  const dayOfYearParams = getDayOfYear(new Date(date));
  const dayDiff = dayOfYear - dayOfYearParams;
  let resultDate = "";

  const HHmm = format(new Date(date), "HH:mm");
  const ddMMyyyy = format(
    new Date(date),
    `${options.dateWithTime
       ? "HH:mm "
       : ""}dd.MM.yyyy`,
  );

  switch (true)
  {
    case dayOfYear === dayOfYearParams && !options.today :
    {
      resultDate = dayOfYear === dayOfYearParams
                   ? HHmm
                   : ddMMyyyy;
      break;
    }
    case dayDiff === 0 && options.today:
    {
      if (options.todayWithTime)
      {
        resultDate = HHmm + " ";
      }
      resultDate += "Сегодня";
      break;
    }
    case dayDiff === 1 && options.today:
    {
      if (options.todayWithTime)
      {
        resultDate = HHmm + " ";
      }
      resultDate += "Вчера";
      break;
    }
    case dayDiff >= 2 && options.today:
    {
      resultDate = ddMMyyyy;
      break;
    }
    case dayDiff >= 1 && !options.today:
    {
      resultDate = ddMMyyyy;
      break;
    }

    default:
    {
      resultDate = ddMMyyyy;
    }
  }

  return resultDate;
}
