import {Components, Theme} from "@mui/material/styles";

export const muiTabs: Components<Theme>["MuiTabs"] = {
  styleOverrides: {
    root: ({theme}) => ({
      minHeight: "32px",
      marginBottom: "20px", 
      "& .MuiTabs-flexContainer": {
        gap: 16,
      },
    }),
  },
};
