import axios from "axios";
import {DataProvider} from "react-admin";
import {API_URL} from "shared/constants/apiURL";
import {
  handleConvertAllDealsListReqBody,
  handleConvertBlocksReqBody,
  handleConvertPaymentsListReqBody,
} from "../utils/helpers";
import {defaultProvider} from "./defaultProvider";
import {ROUTES} from "shared/constants/ROUTES";

export const safeDealProviders = {
  ...defaultProvider(),

  getList: async (resource = "", params) => {
    if (resource.startsWith(ROUTES.SAFE_DEAL_ALL_DEALS.path)) {
      const data = (
        await axios.get(
          `${API_URL}/${resource}?${handleConvertAllDealsListReqBody(params)}`,
        )
      )?.data;

      const total = data?.totalCount;

      return {data: data?.items || [], total};
    }

    if (resource.startsWith(ROUTES.SAFE_DEAL_PAYMENTS.path)) {
      /* TODO: no endpoint */
      // if (params.filter.paymentsSD_ID) {
      //   const data = (
      //     await axios.get(
      //       `${API_URL}/safe-deal/payments/list/safe-deal?safe_deal_id=${
      //         params.filter.paymentsSD_ID
      //       }&${handleConvertPaymentsListReqBody(params)}`,
      //     )
      //   )?.data;

      //   const total = data?.totalCount;

      //   return {data: data?.items || [], total};
      // }

      const data = (
        await axios.get(
          `${API_URL}/${resource}?${handleConvertPaymentsListReqBody(params)}`,
        )
      )?.data;

      const total = data?.totalCount;

      return {data: data?.items || [], total};
    }

    return defaultProvider().getList(resource, params);
  },

  getOne: async (resource = "", params) => {
    return defaultProvider().getOne(resource, params);
  },
} as DataProvider;
