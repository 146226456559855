import {Components} from "@mui/material/styles";

export const muiInputLabel: Components["MuiInputLabel"] = {
  variants: [
    {
      props: {
        // variant: "outlined"
      },
      style: {
        // fontSize: 15,
        // margin: "3px",
        // fontSize: "13px !important",
        // fontWeight: 500,
        // position: "static",
        // transform: "none",
        // maxWidth: "100%",
        // lineHeight: "110%",
      },
    },
  ],
  styleOverrides: {
    shrink: {
      transform: "translate(12px, -8px) scale(0.8) !important",
    },
  },
};
