import {Theme, createTheme} from "@mui/material";
import {themes} from "./ColorModeProvider";
import {muiButton} from "./ovirridesPropsThemeComponent/button";
import {muiInput} from "./ovirridesPropsThemeComponent/input";
import {typography} from "./overridesTypography/typography";
import {muiInputLabel} from "./ovirridesPropsThemeComponent/inputLabel";
import {muiCheckbox} from "./ovirridesPropsThemeComponent/checkbox";
import {muiTextField} from "./ovirridesPropsThemeComponent/textField";
import {palette, darkPalete} from "./paleteOverrides/paleteOverrides";
import {muiTab} from "./ovirridesPropsThemeComponent/tab";
import {muiTabs} from "./ovirridesPropsThemeComponent/tabs";
import {muiTable} from "./ovirridesPropsThemeComponent/table";
import {muiTableHead} from "./ovirridesPropsThemeComponent/tableHead";
import {muiTableBody} from "./ovirridesPropsThemeComponent/tableBody";
import {muiTableCell} from "./ovirridesPropsThemeComponent/tableCell";
import {muiButtonBase} from "./ovirridesPropsThemeComponent/muiButtonBase";
import {muiPagination} from "./ovirridesPropsThemeComponent/pagination";
import {muiSelect} from "./ovirridesPropsThemeComponent/select";
import {muiInputBase} from "./ovirridesPropsThemeComponent/inputBase";
import {muiAutocomplete} from "./ovirridesPropsThemeComponent/muiAutocomplete";
import {muiTypography} from "./ovirridesPropsThemeComponent/typography";
import {muiTooltip} from "./ovirridesPropsThemeComponent/tooltip";
import {muiDivider} from "./ovirridesPropsThemeComponent/divider";
import {getObjectValuesType} from "shared/helpers/typeHelpers";
import {muiToolbar} from "./ovirridesPropsThemeComponent/toolbar";

const general = createTheme({
  palette,
  spacing: 4,
  components: {
    MuiButton: muiButton,
    MuiInput: muiInput,
    MuiInputLabel: muiInputLabel,
    MuiCheckbox: muiCheckbox,
    MuiTextField: muiTextField,
    MuiTab: muiTab,
    MuiTabs: muiTabs,
    MuiTable: muiTable,
    MuiTableHead: muiTableHead,
    MuiTableBody: muiTableBody,
    MuiTableCell: muiTableCell,
    MuiButtonBase: muiButtonBase,
    MuiPagination: muiPagination,
    MuiSelect: muiSelect,
    MuiInputBase: muiInputBase,
    MuiAutocomplete: muiAutocomplete,
    MuiTypography: muiTypography,
    MuiTooltip: muiTooltip,
    MuiDivider: muiDivider,
    MuiToolbar: muiToolbar,
  },
  typography,
});

const light = createTheme(general, {
  palette: {
    mode: themes.LIGHT,
  },
});
const dark = createTheme(general, {
  palette: {
    mode: themes.DARK,
    ...darkPalete,
  },
  components: {
    MuiInput: {
      styleOverrides: {
        input: {
          border: `1px solid ${general.palette.white.main}`,
          "::placeholder": {
            color: general.palette.white.main,
          },
        },
      },
    },
  },
});

export const THEMES: Record<getObjectValuesType<typeof themes>, Theme> = {
  [themes.LIGHT]: light,
  [themes.DARK]: dark,
};
