import React, {useCallback, useState} from "react";
import {SnackbarProps, AlertColor} from "@mui/material";
import {ToastContext} from "shared/hooks/toastContext/toastContext";
import {ToastContainer} from "shared/hooks/toastContext/ui/ToastContainer";
import {ToastProps} from "shared/hooks/toastContext/ui/Toast";

export type ToastProviderProps = {
  children: React.ReactNode;
} & SnackbarProps;

let id = 1;

export const ToastProvider = ({children, ...snackbarProps}: ToastProviderProps) => {
  const [toasts, setToasts] = useState<ToastProps[]>([]);

  const addToast = useCallback(
    (content: string, severity: AlertColor = "success", persisted?: boolean) => {
      setToasts((toasts) => [
        ...toasts,
        {id: id++, content, severity, persisted: !!persisted},
      ]);
    },
    [setToasts],
  );

  const addErrorToast = useCallback(
    (content: string) => {
      addToast(content, "error");
    },
    [addToast],
  );

  const removeToast = useCallback(
    (id: number) => {
      setToasts((toasts) => toasts.filter((t) => t.id !== id));
    },
    [setToasts],
  );

  return (
    <ToastContext.Provider value={{addToast, addErrorToast, removeToast}}>
      <ToastContainer toasts={toasts} snackbarProps={snackbarProps} />
      {children}
    </ToastContext.Provider>
  );
};
