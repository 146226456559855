export const ALREADY_DISPLAYED_KEYS = [
  "codename",
  "name",
  "priceWithNds",
  "priceNoNds",
  "ndsPercent",
  "ndsAmount",
  "isArchived",
  "sorter",
  "internalComment",
  "subscriptionTypes",
];
