import {useAppSelector} from "shared/hooks/useAppSelector";
import axios from "axios";
import {useState} from "react";
import {API_URL} from "shared/constants/apiURL";
import {TChat} from "shared/ReduxSlice/chatSlice/types";
import React from "react";

export const uploadFile = async (
  chatId: TChat["id"],
  file: File,
  progressCallback: React.Dispatch<React.SetStateAction<number>>,
) => {
  try {
    const data = new FormData();
    data.append(file.name, file);

    const result = await axios.post(`${API_URL}/chat/upload/${chatId}`, data, {
      onUploadProgress: (progressEvent) => {
        if (progressEvent.total > 0) {
          const uploadProgress = Math.round(
            (100 * progressEvent.loaded) / progressEvent.total,
          );
          progressCallback(uploadProgress);
        }
      },
    });

    return result.data[0];
  } catch (error) {
    return {
      error: {
        status: error.response?.status,
        data: error.response?.data || error.message,
      },
    };
  }
};

export const useHandleUpdateFiles = () => {
  const {currentChat} = useAppSelector((state) => state.chatSlice);
  const [progressValue, setProgressValue] = useState(0);

  const handleUpdateFiles = async (filesArray) => {
    const arr = filesArray.map(async (file) => {
      try {
        if (!currentChat?.id) return;

        return await uploadFile(currentChat?.id, file, setProgressValue);
      } catch (error) {
        return {error};
      }
    });
    setProgressValue(0);
    return Promise.all(arr);
  };

  return {handleUpdateFiles, progressValue};
};
