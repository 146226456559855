import React, {RefObject, forwardRef, useState} from "react";
import {useEffect} from "react";
import {ExpandMoreOutlined} from "@mui/icons-material";
import {Button} from "@mui/material";

interface ScrollToBottomButtonProps {
  messagesEndRef: RefObject<HTMLDivElement>;
}

export const ScrollToEndButton = forwardRef<HTMLDivElement, ScrollToBottomButtonProps>(
  ({messagesEndRef}, listRef) => {
    const [showButton, setShowButton] = useState(false);

    const handleScroll = () => {
      const listElement = listRef as React.MutableRefObject<HTMLDivElement>;
      const {scrollTop, scrollHeight, clientHeight} = listElement.current;
      if (scrollTop + clientHeight < scrollHeight - 100) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({behavior: "smooth"});
    };

    useEffect(() => {
      const listElement = (listRef as React.MutableRefObject<HTMLDivElement>).current;
      listElement.addEventListener("scroll", handleScroll);
      return () => {
        listElement.removeEventListener("scroll", handleScroll);
      };
    }, [listRef]);

    return (
      showButton && (
        <Button
          onClick={scrollToBottom}
          sx={{
            position: "absolute",
            bottom: 16,
            right: 16,
            zIndex: 1000,
            minWidth: "40px",
            minHeight: "40px",
            width: "40px",
            height: "40px",
          }}
          variant="main"
        >
          <ExpandMoreOutlined sx={{color: ({palette}) => palette.white.main}} />
        </Button>
      )
    );
  },
);

ScrollToEndButton.displayName = "ScrollToEndButton";
